<template>
  <div class="tw-p-4">
    <h1 class="tw-leading-2 tw-text-xl">Nieuw gebouw aanmaken</h1>

    <div class="tw-w-96">
      <FormField label="Groep" type="multi" :options="groupOptions" v-model="form.groupId" />

      <FormField
        label="Adressen"
        type="select"
        :multiple="true"
        :options="locationOptions"
        :size="10"
        v-model="form.locationIds"
      />
      <div v-if="locationOptions.length > 0" class="tw-my-2">
        {{ form.locationIds.length }} van de {{ locationOptions.length }} adressen geselecteerd
      </div>

      <FormField label="Naam" type="text" v-model="form.name" />

      <button
        @click="createBuilding"
        class="tw-bg-blue-400 hover:tw-bg-blue-300 tw-p-1 tw-rounded tw-text-white"
      >
        Aanmaken
      </button>
    </div>
  </div>
</template>

<script setup>
import { useQuery, useMutation } from '@tanstack/vue-query'
import FormField from '@/components/shared/FormField.vue'
import { computed, reactive, watch } from 'vue'
import notifications from '@/util/Notifications'
import { useRouter } from 'vue-router/composables'

const form = reactive({
  name: '',
  groupId: null,
  locationIds: [],
})

const router = useRouter()

watch(
  () => form.groupId,
  () => {
    form.locationIds = []
    form.name = groupOptions.value.find((group) => group.value === form.groupId)?.label || ''
  }
)

const { data: groups } = useQuery({
  queryKey: ['groups'],
  queryFn: async () => {
    const response = await fetch('/groups/api/v1/group')
    return response.json()
  },
})

const { data: locations } = useQuery({
  queryKey: computed(() => ['locations', { groupId: form.groupId }]),
  queryFn: async () => {
    const response = await fetch(`/tickets/api/v1/locations?group_ids=${form.groupId}`)
    return response.json()
  },
  enabled: computed(() => !!form.groupId),
})

const locationOptions = computed(
  () =>
    locations.value?.data.map((location) => ({
      label: `${location.address.street} ${location.address.house_number}`,
      value: location.location_id,
    })) || []
)

const groupOptions = computed(() =>
  groups.value?.data.map((group) => ({
    label: group.name,
    value: group.id,
  }))
)

const { mutate: createBuilding } = useMutation({
  mutationFn: async () => {
    const payload = {
      name: form.name,
      group_id: form.groupId,
      location_ids: form.locationIds,
    }
    const response = await fetch(`/tickets/api/v1/buildings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      try {
        const error = await response.json()
        notifications.addNotification({
          message: `Fout bij aanmaken gebouw ${error.detail || error.message} `,
          type: 'danger',
        })
      } catch (e) {
        notifications.addNotification({
          message: `Fout bij aanmaken gebouw ${response.statusText} `,
          type: 'danger',
        })
      }
      throw new Error(`Failed to update case: ${response.statusText}`)
    }

    const result = await response.json()
    router.push({ name: 'building-details', params: { id: result.data.id } })
  },
})
</script>
