var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Multiselect,_vm._g(_vm._b({attrs:{"classes":{
    container:
      'tw-relative tw-mx-auto tw-w-full tw-flex tw-items-center tw-justify-end tw-box-border tw-cursor-pointer tw-border tw-border-gray-300 tw-rounded tw-bg-white tw-text-base tw-leading-snug tw-outline-none',
    containerDisabled: 'tw-cursor-default tw-bg-gray-100',
    containerOpen: 'tw-rounded-b-none',
    containerOpenTop: 'tw-rounded-t-none',
    containerActive: 'tw-ring tw-ring-blue-500 tw-ring-opacity-30',
    singleLabel:
      'tw-flex tw-items-center tw-h-full tw-max-w-full tw-absolute tw-left-0 tw-top-0 tw-pointer-events-none tw-bg-transparent tw-leading-snug tw-pl-3.5 tw-pr-16 tw-box-border tw-rtl:left-auto tw-rtl:right-0 tw-rtl:pl-0 tw-rtl:pr-3.5',
    singleLabelText:
      'tw-overflow-ellipsis tw-overflow-hidden tw-block tw-whitespace-nowrap tw-max-w-full',
    multipleLabel:
      'tw-flex tw-items-center tw-h-full tw-absolute tw-left-0 tw-top-0 tw-pointer-events-none tw-bg-transparent tw-leading-snug tw-pl-3.5 tw-rtl:left-auto tw-rtl:right-0 tw-rtl:pl-0 tw-rtl:pr-3.5',
    search:
      'tw-w-full tw-absolute tw-inset-0 tw-outline-none tw-focus:ring-0 tw-appearance-none tw-box-border tw-border-0 tw-text-base tw-font-sans tw-bg-white tw-rounded tw-pl-3.5 tw-rtl:pl-0 tw-rtl:pr-3.5',
    tags: 'tw-flex-grow tw-flex-shrink tw-flex tw-flex-wrap tw-items-center tw-mt-1 tw-pl-2 tw-rtl:pl-0 tw-rtl:pr-2',
    tag: 'tw-bg-gray-200 tw-text-gray-500 tw-text-xs tw-font-semibold tw-py-0.5 tw-pl-2 tw-rounded tw-mr-1 tw-mb-1 tw-flex tw-items-center tw-whitespace-nowrap tw-rtl:pl-0 tw-rtl:pr-2 tw-rtl:mr-0 tw-rtl:ml-1',
    tagDisabled: 'tw-pr-2 tw-opacity-50 tw-rtl:pl-2',
    tagRemove:
      'tw-flex tw-items-center tw-justify-center tw-p-1 tw-mx-0.5 tw-rounded-sm tw-hover:bg-black tw-hover:bg-opacity-10 tw-group',
    tagRemoveIcon:
      'tw-bg-multiselect-remove tw-bg-center tw-bg-no-repeat tw-opacity-30 tw-inline-block tw-w-3 tw-h-3 tw-group-hover:opacity-60',
    tagsSearchWrapper:
      'tw-inline-block tw-relative tw-mx-1 tw-mb-1 tw-flex-grow tw-flex-shrink tw-h-full',
    tagsSearch:
      'tw-absolute tw-inset-0 tw-border-0 tw-outline-none tw-focus:ring-0 tw-appearance-none tw-p-0 tw-text-base tw-font-sans tw-box-border tw-w-full',
    tagsSearchCopy: 'tw-invisible tw-whitespace-pre-wrap tw-inline-block tw-h-px',
    placeholder:
      'tw-flex tw-items-center tw-h-full tw-absolute tw-left-0 tw-top-0 tw-pointer-events-none tw-bg-transparent tw-leading-snug tw-pl-3.5 tw-text-gray-400 tw-rtl:left-auto tw-rtl:right-0 tw-rtl:pl-0 tw-rtl:pr-3.5',
    caret:
      'tw-bg-multiselect-caret tw-bg-center tw-bg-no-repeat tw-w-2.5 tw-h-4 tw-py-px tw-box-content tw-mr-3.5 tw-relative tw-z-10 tw-opacity-40 tw-flex-shrink-0 tw-flex-grow-0 tw-transition-transform tw-transform tw-pointer-events-none tw-rtl:mr-0 tw-rtl:ml-3.5',
    caretOpen: 'tw-rotate-180 tw-pointer-events-auto',
    clear:
      'tw-pr-3.5 tw-relative tw-z-10 tw-opacity-40 tw-transition tw-duration-300 tw-flex-shrink-0 tw-flex-grow-0 tw-flex tw-hover:opacity-80 tw-rtl:pr-0 tw-rtl:pl-3.5',
    clearIcon:
      'tw-bg-multiselect-remove tw-bg-center tw-bg-no-repeat tw-w-2.5 tw-h-4 tw-py-px tw-box-content tw-inline-block',
    spinner:
      'tw-bg-multiselect-spinner tw-bg-center tw-bg-no-repeat tw-w-4 tw-h-4 tw-z-10 tw-mr-3.5 tw-animate-spin tw-flex-shrink-0 tw-flex-grow-0 tw-rtl:mr-0 tw-rtl:ml-3.5',
    inifite: 'tw-flex tw-items-center tw-justify-center tw-w-full',
    inifiteSpinner:
      'tw-bg-multiselect-spinner tw-bg-center tw-bg-no-repeat tw-w-4 tw-h-4 tw-z-10 tw-animate-spin tw-flex-shrink-0 tw-flex-grow-0 tw-m-3.5',
    dropdown:
      'tw-max-h-60 tw-absolute tw--left-px tw--right-px tw-bottom-0 tw-transform tw-translate-y-full tw-border tw-border-gray-300 tw--mt-px tw-overflow-y-scroll tw-z-50 tw-bg-white tw-flex tw-flex-col tw-rounded-b',
    dropdownTop: 'tw--translate-y-full tw-top-px tw-bottom-auto tw-rounded-b-none tw-rounded-t',
    dropdownHidden: 'tw-hidden',
    options: 'tw-flex tw-flex-col tw-p-0 tw-m-0 tw-list-none',
    optionsTop: '',
    group: 'tw-p-0 tw-m-0',
    groupLabel:
      'tw-flex tw-text-sm tw-box-border tw-items-center tw-justify-start tw-text-left tw-py-1 tw-px-3 tw-font-semibold tw-bg-gray-200 tw-cursor-default tw-leading-normal',
    groupLabelPointable: 'tw-cursor-pointer',
    groupLabelPointed: 'tw-bg-gray-300 tw-text-gray-700',
    groupLabelSelected: 'tw-bg-green-600 tw-text-white',
    groupLabelDisabled: 'tw-bg-gray-100 tw-text-gray-300 tw-cursor-not-allowed',
    groupLabelSelectedPointed: 'tw-bg-green-600 tw-text-white tw-opacity-90',
    groupLabelSelectedDisabled:
      'tw-text-green-100 tw-bg-green-600 tw-bg-opacity-50 tw-cursor-not-allowed',
    groupOptions: 'tw-p-0 tw-m-0',
    option:
      'tw-flex tw-items-center tw-justify-start tw-box-border tw-text-left tw-cursor-pointer tw-text-base tw-leading-snug tw-py-2 tw-px-3',
    optionPointed: 'tw-text-gray-800 tw-bg-gray-100',
    optionSelected: 'tw-text-white tw-bg-green-500',
    optionDisabled: 'tw-text-gray-300 tw-cursor-not-allowed',
    optionSelectedPointed: 'tw-text-white tw-bg-green-500 tw-opacity-90',
    optionSelectedDisabled:
      'tw-text-green-100 tw-bg-green-500 tw-bg-opacity-50 tw-cursor-not-allowed',
    noOptions: 'tw-py-2 tw-px-3 tw-text-gray-600 tw-bg-white tw-text-left',
    noResults: 'tw-py-2 tw-px-3 tw-text-gray-600 tw-bg-white tw-text-left',
    fakeInput:
      'tw-bg-transparent tw-absolute tw-left-0 tw-right-0 tw--bottom-px tw-w-full tw-h-px tw-border-0 tw-p-0 tw-appearance-none tw-outline-none tw-text-transparent',
    spacer: 'tw-h-9 tw-py-px tw-box-content',
  }}},'Multiselect',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }