<template>
  <th>
    <div class="table-header">
      <slot></slot>
    </div>
  </th>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'TableHeader' })
export default class TableHeader extends Vue {}
</script>

<style lang="scss">
.table-header {
  display: flex;
  align-items: center;
  min-height: 34px;
  padding: 5px;
  white-space: nowrap;
}
</style>
