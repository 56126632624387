import { BaseFilter, BaseList, BaseScreenManager } from '@/logic/common'
import VueRouter from 'vue-router'
import request from '@/requests'
import type { Interaction } from '@/interfaces'
import { STATUS_OK } from '@/constants'

interface ExistingInteraction extends Interaction {
  created_by: string
  created_at: string
}

interface State {
  type: string
  value: string
  context: string
  createdBy: string
}

export default class InteractionsScreenManager extends BaseScreenManager {
  public interactions: BaseList<ExistingInteraction>
  public isLoading = true

  constructor(router: VueRouter) {
    super(router)
    this.interactions = this.createInteractions([])
    this.init()
  }

  private filterByType = (interaction: ExistingInteraction, state: State) =>
    !state.type || interaction.type === state.type

  private filterByValue = (interaction: ExistingInteraction, state: State) =>
    !state.value || interaction.value === state.value

  private filterByContext = (interaction: ExistingInteraction, state: State) =>
    !state.context || interaction.context === state.context

  private filterByCreatedBy = (interaction: ExistingInteraction, state: State) =>
    !state.createdBy || interaction.created_by === state.createdBy

  private createInteractions = (interactions: Array<ExistingInteraction>) =>
    new BaseList(interactions, {
      filter: new BaseFilter({
        state: {
          type: '',
          value: '',
          context: '',
          createdBy: '',
        },
        filters: {
          type: this.filterByType,
          value: this.filterByValue,
          context: this.filterByContext,
          createdBy: this.filterByCreatedBy,
        },
      }),
    })

  private fetchInteractions = async () => {
    const response = await request.interactions.getInteractions()
    if (response.status === STATUS_OK) {
      return response.data
    }
    return []
  }

  protected init = async () => {
    const interactions = await this.fetchInteractions()
    this.interactions.setItems(interactions.reverse())
    this.isLoading = false
  }
}
