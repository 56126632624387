import { actions } from '@/store'
import { composeSort, invertSort, plugins, notifications } from '@/util'

import {
  BaseScreenManager,
  GroupEditor,
  BaseFilter,
  BaseList,
  sortGroupNames,
  sortGroupUser,
  sortByCreatedAt,
  filterGroupUser,
  filterSearchGroupName,
} from '@/logic/common/'

import { STATUS_OK, SCREEN_GROUP_EDIT } from '@/constants'

import type VueRouter from 'vue-router'
import type { ExistingGroup, GroupFilter } from '@/interfaces'
import request from '@/requests'
import { filterGroupWoco } from '../common/filters'

export default class GroupCreationScreenManager extends BaseScreenManager {
  public groupList: BaseList<ExistingGroup>
  public editor: GroupEditor | null = null
  public groupMarkedForDelete: ExistingGroup | null = null
  public isLoading = true

  constructor(router: VueRouter) {
    super(router)
    this.groupList = this.createGroupsList([])
    this.init()
  }

  get availableUsers() {
    const users: Array<string> = []
    this.groupList.items.forEach((item) => users.push(item.created_by))
    return [...new Set(users)]
  }

  public setGroups = (groups: Array<ExistingGroup>) => {
    this.groupList.setItems(groups)
    this.groupMarkedForDelete = null
  }

  public createGroup = async (): Promise<void> => {
    if (!this.editor) return

    this.isLoading = true
    const newGroup = await this.editor.create()

    if (newGroup) {
      this.groupList.push(newGroup)
      this.router.push({
        name: SCREEN_GROUP_EDIT,
        params: { name: newGroup.name },
      })
      this.editor.generateNewGroup()
    }
    this.isLoading = false
  }

  public deleteGroup = async () => {
    if (!this.groupMarkedForDelete) return
    this.isLoading = true

    const groupName = this.groupMarkedForDelete.name

    const response = await request.groups.deleteGroup(groupName)
    if (response.status === STATUS_OK) {
      this.confirmDelete(this.groupMarkedForDelete)
    } else {
      notifications.setNotificationFromResponse(response)
      this.confirmCancel()
    }

    this.isLoading = false
  }

  public markGroupForDelete = (markedGroup: ExistingGroup) => {
    this.groupMarkedForDelete = markedGroup
  }

  public onGroupExtendSelect = (groupName: string) => {
    if (!this.editor) return

    const group = this.groupList.items.find((item) => item.name === groupName)
    if (!group) {
      console.warn(`Failed to find group [${groupName}]`)
      return
    }
    this.editor.setExtendedGroup(group)
  }

  public handleEdit = (markedGroup: ExistingGroup) => {
    this.router.push({
      name: SCREEN_GROUP_EDIT,
      params: { name: markedGroup.name },
    })
  }

  public confirmDelete = (markedGroup: ExistingGroup) => {
    if (!this.groupList) return
    this.groupList.removeItem(markedGroup)
    this.groupMarkedForDelete = null
  }

  public confirmCancel = () => {
    this.groupMarkedForDelete = null
  }

  protected init = async () => {
    const [appResponse, groupsResponse] = await this.fetchResources()
    const notifcations = []
    if (appResponse.status !== STATUS_OK) {
      notifcations.push({ message: appResponse.message, type: 'danger' })
    }
    if (groupsResponse.status !== STATUS_OK) {
      notifcations.push({ message: groupsResponse.message, type: 'danger' })
    }
    if (notifcations.length) {
      actions.common.commitNotifications(notifcations)
    }
    this.editor = new GroupEditor(this.router)
    this.isLoading = false
  }

  protected fetchResources = async () => {
    this.isLoading = true
    const [appResponse, groupsResponse] = await Promise.all([this.fetchApps(), this.fetchGroups()])
    return [appResponse, groupsResponse]
  }

  protected fetchApps = async () => {
    const response = await request.groups.getAvailableApps()
    if (response.status === STATUS_OK) {
      actions.common.commitApps(response.data)
    }
    return response
  }

  protected async fetchGroups() {
    const username = actions.auth.readUser()
    const response = await request.groups.getAllGroups()
    // const response = await request.groups.getAllGroupsVisibleForUser(username);
    if (response.status === STATUS_OK) {
      this.setGroups(response.data)
    }
    return response
  }

  protected filterGroupVisibilityType(group: ExistingGroup, state: GroupFilter) {
    const stateValue = state.visibilityType
    if (!stateValue) return true

    return (
      (stateValue === 'private' && group.is_private) ||
      (stateValue === 'public' && !group.is_private)
    )
  }

  protected createGroupsList = (groups: Array<ExistingGroup>) =>
    new BaseList(groups, {
      autoSort: true,
      filter: new BaseFilter({
        state: {
          user: '',
          search: '',
          woco: '',
          visibilityType: '',
        },
        filters: {
          user: filterGroupUser,
          woco: filterGroupWoco,
          search: filterSearchGroupName,
          visibilityType: this.filterGroupVisibilityType,
        },
      }),
      sorters: {
        // first sorter will be used by the autoSort until a new sorter has been selected
        dateDescending: composeSort(invertSort(sortByCreatedAt), sortGroupNames),
        dateAscending: composeSort(sortByCreatedAt, sortGroupNames),
        nameAscending: composeSort(sortGroupNames, sortGroupUser),
        nameDescending: composeSort(invertSort(sortGroupNames), sortGroupUser),
        userAscending: composeSort(sortGroupUser, sortGroupNames),
        userDescending: composeSort(invertSort(sortGroupUser), sortGroupNames),
      },
    })
}
