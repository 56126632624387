<template>
  <div class="pdf-viewer">
    <button class="button" @click="onClick">{{ label }}</button>
    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button
            class="delete"
            aria-label="close"
            data-id="detail-pdf-viewer"
            @click="() => (showModal = false)"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="pdf-modal-body">
            <embed :src="source" type="application/pdf" width="100%" height="100%" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'DetailsPDFViewer' })
export default class DetailsPDFViewer extends Vue {
  @Prop({ default: 'Title' }) title!: string
  @Prop({ default: 'Button' }) label!: string
  @Prop({ default: '' }) source!: string

  protected showModal = false

  private onClick() {
    this.showModal = true
  }
}
</script>

<style lang="scss" scoped>
.pdf-viewer {
  > .button {
    font-size: 0.73rem;
    margin: 0 !important;
  }
}

.pdf-modal-body {
  width: 70vw;
  height: 80vh;
}

.modal-card {
  width: 80vw !important;
}

.modal-card-body {
  display: flex;
  justify-content: center;
}
</style>
