<template>
  <div class="m-2">
    <div v-if="error" class="tw-bg-red-100 tw-rounded tw-p-2">
      {{ error }}
    </div>

    <div class="tw-my-2 tw-flex tw-flex-col">
      <div class="tw-font-bold">Filter</div>

      <div class="tw-flex tw-flex-row tw-gap-2">
        <div class="tw-w-1/4">
          <label class="tw-mr-2 tw-uppercase tw-tracking-wider tw-text-xs">Groups</label>
          <MultiSelect
            v-if="groups.length > 0"
            v-model="filter.groups"
            :options="groups.map((g) => ({ value: g.id, label: `${g.project} / ${g.name}` }))"
            searchable
            mode="tags"
          />
        </div>
        <div class="tw-w-1/4">
          <div>
            <label class="tw-mr-2 tw-uppercase tw-tracking-wider tw-text-xs">Projects</label>
          </div>
          <MultiSelect
            v-if="projects.length > 0"
            v-model="filter.projects"
            :options="projects"
            searchable
            mode="tags"
          />
        </div>
        <div class="tw-w-1/4">
          <label class="tw-mr-2 tw-uppercase tw-tracking-wider tw-text-xs">Address search</label>
          <div>
            <input
              v-model="filter.query"
              class="tw-w-full tw-border tw-border-gray-300 tw-rounded-sm tw-py-1 tw-px-2"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoading">Loading...</div>
    <div v-else class="tw-my-2">{{ total }} addresses found</div>

    <!-- <div class="tw-bg-gray-100 tw-rounded tw-p-2 tw-flex">
      <div class="tw-font-bold tw-text-sm">Add to or remove group from all addresses in filter ({{ total }})</div>
      <div class="">
        <div class="tw-gap-2 tw-flex">
          <input type="radio" id="add_group" name="action" value="add_group" v-model="action" />
          <label for="add_group">Add group</label>
        </div>
        <div class="tw-gap-2 tw-flex">
          <input type="radio" id="remove_group" name="action" value="remove_group" v-model="action" />
          <label for="remove_group">Remove group</label>
        </div>
      </div>
      <div>
        Group
      </div>
    </div> -->

    <table class="tw-w-full" v-if="addresses" :class="{ 'tw-opacity-50': isFetching }">
      <tr>
        <th class="tw-w-1/5">Address</th>
        <th class="tw-w-1/5">Project</th>
        <th class="tw-w-3/5">Groups</th>
      </tr>
      <tr v-for="address in addresses" :key="address.id">
        <td class="tw-align-middle">{{ address.address }}</td>
        <td class="tw-align-middle">{{ address.groups[0]?.project }}</td>
        <td>
          <div v-if="groupsInput[address.id]" class="tw-flex tw-items-center tw-gap-2">
            <MultiSelect
              v-model="groupsInput[address.id]"
              :options="optionsForGroups(groupsInput[address.id], address.groups)"
              searchable
              mode="tags"
            />
            <div>
              <button
                class="tw-h-full tw-text-xs tw-rounded-sm tw-bg-green-400 tw-text-white tw-p-2 disabled:tw-opacity-30"
                @click="saveAdress(address)"
                :disabled="groupsInput[address.id].length === 0"
              >
                Save
              </button>
            </div>
            <div>
              <button
                class="tw-text-xs tw-rounded-sm tw-bg-red-300 tw-text-white tw-p-1"
                @click="groupsInput[address.id] = null"
              >
                Cancel
              </button>
            </div>
          </div>

          <div v-else>
            <span
              v-for="group in address.groups"
              :key="group.id"
              class="tw-text-xs tw-rounded-sm tw-bg-blue-50 tw-p-1 tw-mr-1"
            >
              {{ group.name }}
            </span>
            <button
              class="tw-text-xs tw-rounded-sm tw-bg-green-400 tw-text-white tw-p-1 tw-mr-1"
              @click="openEdit(address)"
            >
              Edit
            </button>
          </div>
        </td>
      </tr>
    </table>

    <div class="tw-flex tw-flex-row tw-gap-2 tw-mt-3">
      <button
        :disabled="!hasPrevPage"
        @click="prevPage"
        class="tw-bg-blue-400 tw-rounded tw-p-1 disabled:tw-bg-gray-100"
      >
        Previous
      </button>
      <button
        :disabled="!hasNextPage"
        @click="nextPage"
        class="tw-bg-blue-400 tw-rounded tw-p-1 disabled:tw-bg-gray-100"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { useQuery, useMutation } from '@tanstack/vue-query'
import fetchData from '@/util/fetch'
import { computed, reactive, ref, watch, unref } from 'vue'
import MultiSelect from '@/components/shared/MultiSelect.vue'

const filter = reactive({
  projects: [],
  groups: [],
  query: '',
})

const groupsInput = ref({})
const error = ref()

const limit = 20
const offset = ref(0)

watch(filter, () => {
  offset.value = 0
})

const getAddresses = () => {
  const params = new URLSearchParams()
  params.append('limit', limit)
  params.append('offset', offset.value)
  if (filter.projects.length > 0) {
    for (const project of filter.projects) {
      params.append('projects', project)
    }
  }
  if (filter.groups.length > 0) {
    for (const group of filter.groups) {
      params.append('group_ids', group)
    }
  }
  if (filter.query) {
    params.append('query', filter.query)
  }

  return fetchData(`/groups/api/v1/address?${params}`)
}
const addressQuery = useQuery({
  queryKey: computed(() => ['addresses', filter, offset.value]),
  queryFn: getAddresses,
  keepPreviousData: true,
})

const getGroups = () => fetchData(`/groups/api/v1/group`)

const groupQuery = useQuery({
  queryKey: ['groups'],
  queryFn: getGroups,
})

const groups = computed(() => (groupQuery.data.value && groupQuery.data.value.data) || [])
const projects = computed(() => Array.from(new Set(groups.value.map((group) => group.project))))

const addresses = computed(() => {
  const allGroups = groups.value
  const list = addressQuery.data.value && addressQuery.data.value.data
  if (!list) return []

  return list.map((address) => {
    const addressGroups = allGroups.filter((group) => address.group_ids.includes(group.id))

    return {
      ...address,
      groups: addressGroups,
    }
  })
})

const total = computed(() => addressQuery.data.value && addressQuery.data.value.total)

function openEdit(address) {
  groupsInput.value = {
    ...groupsInput.value,
    [address.id]: address.groups.map((g) => g.id),
  }
}

const { mutate: saveAdress } = useMutation({
  mutationFn: (address) => {
    const payload = {
      group_ids: groupsInput.value[address.id],
    }
    return fetchData(`/groups/api/v1/address/${address.id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
  },
  onError: (e) => {
    if (e.message) {
      error.value = e.message
    } else {
      error.value = e
    }
  },
  onSuccess: (data, address) => {
    groupsInput.value = {
      ...groupsInput.value,
      [address.id]: undefined,
    }
    addressQuery.refetch()
  },
})

const isLoading = computed(() => addressQuery.isLoading.value || groupQuery.isLoading.value)
const isFetching = computed(() => addressQuery.isFetching.value)

function optionsForGroups(formGroups, addressGroups) {
  const form = unref(formGroups)

  // if selected groups are empty > show all projects for woco
  if (form.length === 0 && addressGroups[0]) {
    return groups.value
      .filter((g) => g.woco === addressGroups[0].woco)
      .map((g) => ({
        value: g.id,
        label: `${g.project} / ${g.name}`,
      }))
  } else if (form.length > 0) {
    const existingGroup = groups.value.find((g) => g.id === form[0])

    return groups.value
      .filter((g) => g.project === existingGroup.project)
      .map((g) => ({
        value: g.id,
        label: g.name,
      }))
  }
  return groups.value.map((g) => ({
    value: g.id,
    label: `${g.project} / ${g.name}`,
  }))
}

const nextPage = () => (offset.value += limit)
const hasNextPage = computed(() => total.value > offset.value + limit)

const prevPage = () => (offset.value -= limit)
const hasPrevPage = computed(() => offset.value > 0)
</script>
