<template>
  <b-modal :active="active" has-modal-card>
    <div class="modal-card" style="width: auto; min-width: 600px">
      <header class="modal-card-head">
        <p class="modal-card-title">Update installateur</p>
        <button type="button" class="delete" @click="$emit('cancel')" />
      </header>
      <section class="modal-card-body w-min-200">
        <b-field label="Installer">
          <b-select v-model="selected_partner">
            <option v-for="installer in installers" :value="installer" :key="installer">
              {{ installer }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Annuleren" @click="$emit('cancel')" />
        <b-button label="Opslaan" type="is-primary" @click="save" :loading="loading" />
      </footer>
    </div>
  </b-modal>
</template>

<script lang="js">
import { notifications } from '@/util'

export default {
  props: ['active', 'ticket', 'installers', 'address'],
  emits: ['finish', 'cancel'],
  data() {
    return {
      loading: false,
      selected_partner:
        this.ticket && this.ticket.ticket && this.ticket.ticket.partner
          ? this.ticket.ticket.partner
          : '123MW',
    }
  },
  methods: {
    async save() {
      const { id } = this.ticket
      const { partner, type } = this.ticket.ticket
      const new_partner = this.selected_partner

      const queryParams = `ticket_id=${id}&installer=${partner}`

      const urlCancel = `api/v1/address/${this.address}/task_type/maintenance/ticket/cancel?${queryParams}`

      let maintenanceInput = this.ticket.ticket.input

      const formatted_images = maintenanceInput.images
        .map(({ url, thumbnail_url, ...rest }) => rest)
        .map(({ key: url, ...rest }) => ({ url, ...rest }))
      let formatted_products = []

      for (const [type, products] of Object.entries(maintenanceInput.products)) {
        products.forEach((product) => {
          product['type'] = type
          formatted_products.push(product)
        })
      }

      this.loading = true
      const cancel = await fetch(urlCancel, {
        method: 'PUT',
        body: JSON.stringify({
          cancel_comment: 'partner changed by Ecorus',
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.getters['auth/getToken']}`,
        },
      })

      if (cancel.ok) {
        const url_create = `/api/v1/address/${this.address}/task_type/maintenance/action/assign_installer_multiple`
        let maintenanceInput = this.ticket.ticket.input

        const json = {
          maintenanceInput: {
            type: 'maintenance',
            partner: new_partner,
            project_name: this.ticket.ticket.project,
            client_info: this.ticket.ticket.client_info,
            products: formatted_products,
            images: formatted_images,
            extra_fields: maintenanceInput.extra_fields,
            finance: maintenanceInput.finance,
            task_id: this.ticket.ticket.task_id,
          },
          assignedInstaller: new_partner,
        }

        const create = await fetch(url_create, {
          method: 'PUT',
          body: JSON.stringify(json),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.$store.getters['auth/getToken']}`,
          },
        })

        this.loading = false
        if (create.ok) {
          const data = await create.json()
          notifications.addNotification({
            message: data.message,
            type: 'success',
          })

          this.$emit('finish')
        } else {
          notifications.addNotification({
            message: `Error while updating ticket, status ${create.status}`,
            type: 'danger',
          })
        }
      } else {
        notifications.addNotification({
          message: `Error while updating ticket, status ${cancel.status}`,
          type: 'danger',
        })
      }
    },
  },
}
</script>

<style></style>
