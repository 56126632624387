<template>
  <tr
    class="table-row"
    :class="[selected ? 'selected' : '']"
    :style="color && `background-color: ${color} !important`"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </tr>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import type { MasterItem, TasksScreenManager } from '@/logic/TasksScreen'

@Component({ name: 'TableRowTask' })
export default class TableRowTask extends Vue {
  @Prop() item!: MasterItem
  @Prop() manager!: TasksScreenManager
  @Prop({ default: false }) selected!: boolean

  get color() {
    return this.isSelectedForDetail ? '#fff3e6' : ''
  }

  get isSelectedForDetail() {
    return (
      this.manager.selectedMasterItem &&
      this.manager.selectedMasterItem.address === this.item.address
    )
  }
}
</script>
