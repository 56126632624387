<template>
  <div class="tw-flex tw-h-screen tw-gap-5 tw-p-5">
    <div class="tw-flex tw-flex-col tw-w-full tw-max-w-[600px]">
      <form-field
        class="tw-w-full"
        label="Zoek adres of serial nummer"
        type="text"
        v-model="queryInput"
      />
      <div v-if="error">Something went wrong</div>
      <generic-table
        v-else
        :columns="columns"
        :data="meters"
        highlightFieldId="serial_number"
        :has-next-page="hasNextPage"
        :is-loading="isLoading"
        :rowClickHandler="redirectToPollingDetail"
        @fetchNextPage="fetchNextPage()"
      >
        <template v-slot:cell-serial_number="{ row }">
          <div class="tw-cursor-pointer tw-flex">
            <PresentationChartLineIcon
              class="tw-w-5 tw-h-5"
              @click.stop="handleSerialNumberClick(row.serial_number, row.street, row.house_number)"
            />

            <span class="tw-ml-3">{{ row.serial_number }}</span>
          </div>
        </template>
        <template v-slot:cell-address="{ row }">
          {{ row.street }} {{ row.house_number }} {{ row.zip_code }} {{ row.zip_code_ext }}
        </template>
        <template v-slot:cell-status="{ row }">
          <div class="tw-flex tw-items-center">
            <span
              class="tw-w-3 tw-h-3 tw-rounded-full"
              :class="{
                'tw-bg-red-500': row.alive === false,
                'tw-bg-green-500': row.alive === true,
              }"
            >
            </span>
            <div class="tw-ml-3">
              {{ row.status }}
            </div>
          </div>
        </template>
      </generic-table>
    </div>
    <div class="tw-flex-auto" v-if="$route.params.id">
      <polling-detail :id="$route.params.id" :metersForComparison="metersForComparison" />
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, ref } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { actions } from '@/store'
import { notifications } from '@/util'
import GenericTable from '@/components/shared/GenericTable.vue'
import FormField from '@/components/shared/FormField.vue'
import PollingDetail from '@/components/Polling/PollingDetail.vue'
import { PresentationChartLineIcon } from '@vue-hero-icons/outline'
import { useRouter } from 'vue-router/composables'

const queryInput = ref('')
const router = useRouter()

const search = reactive({
  query: '',
})

watchDebounced(
  queryInput,
  () => {
    search.query = queryInput.value
  },
  {
    debounce: 300,
  }
)

const metersForComparison = ref([])

const redirectToPollingDetail = (row) => {
  if (row.serial_number === router?.currentRoute?.params?.id) return
  router.push({
    name: 'polling-detail',
    params: { id: row.serial_number },
  })
}

const handleSerialNumberClick = (serialNumber, street, house_number) => {
  const existingIndex = metersForComparison.value.findIndex(
    (item) => item.serialNumber === serialNumber
  )

  if (existingIndex > -1) {
    // If it exists, remove it
    metersForComparison.value.splice(existingIndex, 1)
  } else {
    if (metersForComparison.value.length >= 4) {
      metersForComparison.value.shift()
    }
    metersForComparison.value.push({
      serialNumber,
      address: `${street}, ${house_number}`,
    })
  }
}

const perPage = 50
const token = actions.auth.readToken()

const columns = [
  { label: 'Serial number', field: 'serial_number' },
  { label: 'Adres', field: 'address' },
  { label: 'Status', field: 'status' },
]

const getList = async ({ pageParam = 1 }) => {
  const { query } = search

  const urlParams = new URLSearchParams({
    offset: (pageParam - 1) * perPage,
    limit: perPage,
  })

  if (query) {
    urlParams.append('query', query)
  }

  try {
    const result = await fetch(`/eleena/api/v1/meters?${urlParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!result.ok) {
      notifications.addNotification({
        message: `Failed to get list of meters: ${result.statusText}`,
        type: 'danger',
      })
      return
    }
    return await result.json()
  } catch (error) {
    notifications.addNotification({
      message: `Failed to get list of meters, ${e.message}`,
      type: 'danger',
    })
    throw new Error('Failed to get list of meters with error: ' + error.message)
  }
}

const {
  data: metersQuery,
  isLoading,
  error,
  fetchNextPage,
  hasNextPage,
} = useInfiniteQuery({
  queryKey: computed(() => ['meters', search.query]),
  keepPreviousData: true,
  queryFn: getList,
  refetchOnWindowFocus: false,
  keepPreviousData: true,
  getNextPageParam: (_, allPages) =>
    allPages[allPages.length - 1].data?.length === perPage ? allPages.length + 1 : undefined,
})

const meters = computed(() => metersQuery?.value?.pages.flatMap((p) => p.data))
</script>
