<template>
  <section v-if="manager.isLoading" class="section">
    <div class="container">
      <h1 class="is-size-2 has-text-centered">Loading...</h1>
    </div>
  </section>
  <section v-else class="section">
    <group-delete-modal v-if="manager.groupMarkedForDelete" :manager="manager" />
    <div class="columns is-desktop">
      <div class="column is-3 is-narrow" style="width: 400px">
        <group-create :manager="manager" />
      </div>
      <div class="column">
        <div>
          <container
            className="is-flex is-align-items-center mb-3"
            style="max-width: none !important"
          >
            <search-bar
              :dataId="'groups-search?name=' + filter.state.search"
              size="is-normal"
              spacing="my-0 mr-2"
              :value="this.filter.state.search"
              @change="(e) => this.filter.setState({ search: e.target.value })"
            />
            <dropdown
              :dataId="'groups-filter-woco?woco=' + filter.filterState.woco"
              label="Filter wocos"
              display="label"
              margin="mr-2"
              :items="wocoDropdown"
              @change="(item) => item.handler()"
            />
            <dropdown
              :dataId="'groups-filter-user?user=' + filter.filterState.user"
              label="Filter gebruikers"
              display="label"
              margin="mr-2"
              :items="userDropdown"
              @change="(item) => item.handler()"
            />
            <dropdown
              label="Filter zichtbaarheid"
              display="label"
              margin="mr-2"
              :items="privateGroupDropdown"
              @change="(item) => item.handler()"
            />
            <dropdown
              dataId="groups-sort"
              label="Sorteer"
              display="label"
              :items="sortDropdown"
              margin="mr-2"
              @change="(item) => item.handler()"
            />
          </container>
          <group-table :manager="manager" style="font-size: 14px" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { GroupTable, GroupCreate, GroupDeleteModal } from '@/components/GroupCreationScreen'
import { GroupCreationScreenManager } from '@/logic/GroupCreationScreen'

import { actions } from '@/store'

@Component({
  name: 'GroupCreationView',
  components: {
    GroupCreate,
    GroupTable,
    GroupDeleteModal,
  },
})
export default class GroupCreationView extends Vue {
  private manager = new GroupCreationScreenManager(this.$router)

  get user() {
    return actions.auth.readUser()
  }

  get filter() {
    return this.manager.groupList.filter
  }

  get privateGroupDropdown() {
    const dropdown = [
      {
        label: 'Toon alles',
        handler: () =>
          this.filter!.setState({
            visibilityType: '',
          }),
      },
      {
        label: 'Openbaar',
        handler: () =>
          this.filter!.setState({
            visibilityType: 'public',
          }),
      },
      {
        label: 'Privé',
        handler: () =>
          this.filter!.setState({
            visibilityType: 'private',
          }),
      },
    ]
    return dropdown
  }

  get userDropdown() {
    const dropdown = this.manager.availableUsers.map((user) => ({
      label: user,
      handler: () => this.filter!.setState({ user }),
    }))
    dropdown.sort((a, b) => a.label.localeCompare(b.label))
    dropdown.unshift({ label: 'All', handler: () => this.filter!.setState({ user: '' }) })
    return dropdown
  }

  get wocoDropdown() {
    const wocos = [...new Set(this.manager.groupList.items.map((item) => item.woco))]
    const dropdown = wocos.map((woco) => ({
      label: woco,
      handler: () => this.filter!.setState({ woco }),
    }))
    dropdown.sort((a, b) => a.label.localeCompare(b.label))
    dropdown.unshift({ label: 'All', handler: () => this.filter!.setState({ woco: '' }) })
    return dropdown
  }

  get sortDropdown() {
    const mapNames: { [propName: string]: string } = {
      dateAscending: 'Datum [A-Z]',
      dateDescending: 'Datum [Z-A]',
      nameAscending: 'Groep Naam [A-Z]',
      nameDescending: 'Groep Naam [Z-A]',
      userAscending: 'Gebruiker [A-Z]',
      userDescending: 'Gebruiker [Z-A]',
    }
    const dropdown = this.manager.groupList.sortingNames.map((name: string) => ({
      label: mapNames[name],
      handler: () => {
        this.manager.groupList.sort(name)
      },
    }))
    return dropdown
  }
}
</script>
