<template>
  <div class="button-input">
    <!-- temp user feedback, improve later! -->
    <span v-if="actionStatus.type === 'error'">{{ actionStatus.message }}</span>
    <button
      class="button is-small m-0"
      data-id="detail-button"
      :class="[classes, color]"
      @click="onClick"
      :disabled="disabled"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script lang="ts">
import { STATUS_OK } from '@/constants'
import { Component, Prop } from 'vue-property-decorator'
import DetailsDataComponentBase from './DetailsDataComponentBase.vue'

@Component({
  name: 'DetailsActionButton',
})
export default class DetailsActionButton extends DetailsDataComponentBase {
  @Prop({ default: 'is-info' }) color!: string
  @Prop({ default: null }) label!: string | null

  get buttonText() {
    return this.label || this.component.label
  }

  protected async onClick() {
    const response = await this.submitAction()
    if (response.status === STATUS_OK) {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.button-input {
  span {
    color: red;
  }

  .button {
    font-size: calc(10px + 0.2vh);
    margin: 8px 1px;
    padding: 10px;
    height: calc(23px + 0.2vh);
  }
}
</style>
