<template>
  <div class="row-actions">
    <div
      v-for="[componentName, actionList] in Object.entries(actions)"
      :key="`action-${componentName}`"
      style="color: #000"
    >
      <component
        v-if="$options.components[`${componentName}Bulk`]"
        :is="$options.components[`${componentName}Bulk`]"
        :manager="manager"
        :actionList="actionList"
        :submitActions="onSubmitActions"
        :updateActions="updateActions"
        :enabled="actionList.every(({ action }) => action.enabled)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import type { MasterItem, TasksScreenManager } from '@/logic/TasksScreen'
import type { TaskTypeKey, Action } from '@/interfaces'

import UserSelectBulk from './UserSelectBulk.vue'

import {
  // UserSelectBulk, // for some reason won't load when imported here
  BaseButtonBulk,
  ApproveButtonBulk,
  AssignButtonBulk,
  AssignInstallerButtonBulk,
  CancelButtonBulk,
  FinishButtonBulk,
  ReopenButtonBulk,
  // RejectButtonBulk,
  StartButtonBulk,
  UndoButtonBulk,
  InstallerSelectBulk,
} from '.'

interface StoredAction {
  item: MasterItem
  callback: Function
  action: Action
  taskType: TaskTypeKey
}

@Component({
  name: 'RowActions',
  components: {
    UserSelectBulk,
    BaseButtonBulk,
    ApproveButtonBulk,
    AssignButtonBulk,
    AssignInstallerButtonBulk,
    CancelButtonBulk,
    FinishButtonBulk,
    ReopenButtonBulk,
    // RejectButtonBulk,
    StartButtonBulk,
    UndoButtonBulk,
    InstallerSelectBulk,
  },
})
export default class RowActions extends Vue {
  @Prop() manager!: TasksScreenManager
  @Prop() type!: string

  protected actions: {
    [prop: string]: Array<{
      item: MasterItem
      callback: Function
    }>
  } = {}

  get selectedItems() {
    return this.manager.list.selectedItems
  }

  @Watch('selectedItems')
  onSelectedItemsChange() {
    this.updateActions()
  }

  public updateActions() {
    this.actions = this.manager.getActionsForSelectedItems(this.type as TaskTypeKey)
  }

  public async onSubmitActions(actionList: Array<StoredAction>) {
    await this.manager.submitActionForItems(actionList)
    this.updateActions()
  }

  mounted() {
    this.updateActions()
  }
}
</script>

<style></style>
