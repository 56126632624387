<template>
  <div class="data-box">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'DataBox',
})
export default class DataBox extends Vue {}
</script>

<style lang="scss" scoped>
.data-box {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.1rem 0.25rem;
}

.data-box-type {
  font-weight: 400;
  font-size: 0.45rem;
  margin-bottom: -0.15rem;
}

.data-box-value {
  font-size: 0.5rem;

  &:nth-child(n + 2) {
    margin-left: 0.25rem;
  }
}
</style>
