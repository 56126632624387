import { actions } from '@/store'
import { hasPermission, panePermissions } from '@/logic/common/Permissions'

import {
  TASK_TYPE_CLIENT,
  TASK_TYPE_DESIGN,
  TASK_TYPE_INSTALLATION,
  TASK_TYPE_SALES,
  TASK_TYPE_FINANCE,
  TASK_TYPE_INSPECTION,
  TASK_TYPE_MAINTENANCE,
} from '@/constants'

import type { TaskTypeKey } from '@/interfaces'

interface TasksPaneManagerConfig {
  update: {
    [taskType: string]: () => Promise<void>
  }
  params: { panes?: string }
  updateQueryParams: Function
}

interface Panes {
  [name: string]: {
    open: boolean
    loading: boolean
    update: Function
  }
}

export default class TasksPaneManager {
  public options: Array<string> = []
  public closedPanes: Array<string> = []
  public openPanes: Array<string> = []
  public panes: Panes

  protected config: TasksPaneManagerConfig

  constructor(config: TasksPaneManagerConfig) {
    this.config = config

    this.panes = [
      TASK_TYPE_DESIGN,
      TASK_TYPE_INSTALLATION,
      TASK_TYPE_SALES,
      TASK_TYPE_FINANCE,
      TASK_TYPE_INSPECTION,
      TASK_TYPE_CLIENT,
      TASK_TYPE_MAINTENANCE,
    ].reduce((acc, taskType) => {
      if (!hasPermission(panePermissions[taskType])) return acc

      return {
        ...acc,
        [this.generateComponentName(taskType)]: {
          open: false,
          loading: false,
          update: config.update[taskType],
        },
      }
    }, {} as Panes)

    this.syncPaneSelectionWithStore()
  }

  public getPaneInfo = (pane: string) => this.panes[pane]

  public onPaneOpen = async (pane: string) => {
    const paneProps = this.panes[pane]
    paneProps.open = true
    paneProps.loading = true

    this.openPanes = Array.from(new Set([...this.openPanes, pane]))
    this.closedPanes = this.closedPanes.filter((p) => p !== pane)
    this.updateStoredPanes()

    await paneProps.update()
    paneProps.loading = false
  }

  public onPaneClose = (pane: string) => {
    this.panes[pane].open = false
    this.openPanes = this.openPanes.filter((p) => p !== pane)
    this.closedPanes = Array.from(new Set([...this.closedPanes, pane]))
    this.updateStoredPanes()
  }

  public updateTaskData = async () => {
    const openPanes = Object.values(this.panes).filter((pane) => pane.open)
    await Promise.all(
      openPanes.map((pane) => {
        // eslint-disable-next-line no-param-reassign
        pane.loading = true
        return pane.update()
      })
    )

    openPanes.forEach((pane) => {
      // eslint-disable-next-line no-param-reassign
      pane.loading = false
    })
  }

  public generateComponentName = (taskType: string) => {
    const titleCase = taskType[0].toUpperCase() + taskType.slice(1)
    return `${titleCase}Pane`
  }

  public updateStoredPanes = () => {
    actions.common.commitPanes([...this.openPanes])
    this.config.updateQueryParams(true)
  }

  public getTaskTypesOpenPanes = () => {
    const taskTypes = this.openPanes
      .map((componentName) => componentName.split('Pane')[0].toLowerCase())
      .filter((taskType) => taskType !== TASK_TYPE_CLIENT)

    return taskTypes as Array<TaskTypeKey>
  }

  public syncPaneSelectionWithStore = (ignoreQueryParam = false) => {
    const param = this.config.params.panes
    const queryPanes = param ? param.split(',') : null
    const storedPanes = actions.common.readPanes()
    const panes = ignoreQueryParam ? storedPanes : queryPanes || storedPanes
    const storedSelection = panes.filter((pane) => this.panes[pane] !== undefined)

    this.openPanes = [...storedSelection]
    this.closedPanes = Object.keys(this.panes).filter((pane) => !storedSelection.includes(pane))

    Object.entries(this.panes).forEach(([paneName, paneProps]) => {
      // eslint-disable-next-line no-param-reassign
      paneProps.open = this.openPanes.includes(paneName)
    })
  }
}
