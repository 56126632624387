<template>
  <div>
    <edit-ticket-modal></edit-ticket-modal>
    <div id="app-container" class="is-flex">
      <address-pane class="address-pane-container" :manager="manager" />
      <dynamic-pane-container
        v-if="(manager.selectedGroup || manager.wocoRequiredOnly) && !manager.isLoading"
        class="dynamic-pane-container"
        :config="dynamicPaneConfig"
      />
      <details-pane
        v-if="manager.selectedMasterItem"
        class="details-pane-container"
        :manager="manager"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AddressPane from '@/components/TasksScreen/AddressPane.vue'
import DynamicPaneContainer from '@/components/common/DynamicPaneContainer.vue'
import PaneRenderer from '@/components/TasksScreen/PaneRenderer.vue'
import { TasksScreenManager } from '@/logic/TasksScreen'
import DetailsPane from '@/components/TasksScreen/DetailsPane.vue'
import { actions } from '@/store'
import EditTicketModal from '@/components/tickets/EditTicketModal.vue'
import ChangeInstallerModal from '@/components/tickets/ChangeInstallerModal.vue'

@Component({
  name: 'TasksView',
  components: {
    AddressPane,
    DynamicPaneContainer,
    PaneRenderer,
    DetailsPane,
    EditTicketModal,
    ChangeInstallerModal,
  },
})
export default class TasksView extends Vue {
  public manager = new TasksScreenManager(this.$router)

  protected dynamicPaneConfig = {
    pane: {
      component: this.$options.components!.PaneRenderer,
      props: { manager: this.manager },
    },
    // minPaneWidth: 150, // px
    maxPanes: Object.keys(this.manager.panes.panes).length,
    preOpen: () => this.storedPaneSelection,
  }

  get storedPaneSelection() {
    return this.manager.panes.openPanes.map((pane) => ({
      preselect: pane,
    }))
  }
}
</script>

<style>
.address-pane-container {
  flex: 1;
  flex-grow: 0;
  flex-shrink: 0;
}
.dynamic-pane-container {
  flex: 5;
}
.details-pane-container {
  flex: 2.5; /*3*/
  margin-left: 0.75rem;
  min-width: 25rem;
}
</style>
