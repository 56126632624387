<template>
  <div ref="popoverdiv" class="popover" tabindex="0">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

type AttributeKey = 'top' | 'bottom' | 'left' | 'right'
type CenterKey = 'centerX' | 'centerY'
type PositionKey = AttributeKey | CenterKey

@Component({
  name: 'Popover',
})
export default class Popover extends Vue {
  @Prop({ default: () => ({ x: 0, y: 0 }) }) offset!: { x: number; y: number }
  @Prop({ default: () => ({ x: 'left', y: 'top' }) }) align!: { x: string; y: string }
  @Prop() target!: HTMLElement
  @Prop() containerClass!: string

  protected offsetY = this.offset.y
  protected offsetX = this.offset.x
  protected alignment = {
    x: this.align.x,
    y: this.align.y,
  }

  protected boundingBox: HTMLElement | null = null

  protected setBoundingBox() {
    const documentBody = document.body
    if (!this.containerClass) {
      this.boundingBox = documentBody
      return
    }

    const popoverdiv = this.$refs.popoverdiv as HTMLElement
    let parentNode = popoverdiv.parentNode as HTMLElement
    while (parentNode !== documentBody) {
      if (parentNode.classList.contains(this.containerClass)) {
        this.boundingBox = parentNode
        return
      }
      parentNode = parentNode.parentNode as HTMLElement
    }
    console.warn(`Failed to find container class [${this.containerClass}] for popover`)
    console.warn('Using document.body as default')
    this.boundingBox = document.body
  }

  protected getCenteringOffset(targetRect: DOMRect, popoverRect: DOMRect) {
    const offset = { x: 0, y: 0 }
    if (targetRect.width > popoverRect.width) {
      offset.x = -((targetRect.width - popoverRect.width) / 2)
    }
    if (targetRect.width < popoverRect.width) {
      offset.x = (popoverRect.width - targetRect.width) / 2
    }
    if (targetRect.height > popoverRect.height) {
      offset.y = -((targetRect.height - popoverRect.width) / 2)
    }
    if (targetRect.height < popoverRect.height) {
      offset.y = (popoverRect.height - targetRect.height) / 2
    }
    return offset
  }

  protected getPositionalData() {
    const viewHeight = window.innerHeight
    const viewWidth = window.innerWidth
    const popover = this.$refs.popoverdiv as HTMLElement
    const popoverRect = popover.getBoundingClientRect()
    const targetNode = this.target ? this.target : (popover.parentNode as HTMLElement)
    const targetRect = targetNode.getBoundingClientRect()
    const boundingBoxRect = this.boundingBox!.getBoundingClientRect()

    const centerOffset = this.getCenteringOffset(targetRect, popoverRect)

    const positions = {
      top: targetRect.top + targetRect.height + this.offsetY,
      bottom: viewHeight - targetRect.bottom + (targetRect.height + this.offsetY),
      left: targetRect.left + this.offsetX,
      right: viewWidth - targetRect.right + this.offsetX,
      centerX: targetRect.left - centerOffset.x,
      centerY: targetRect.top - centerOffset.y,
    }

    const exceedsBoundary = {
      top: positions.top + popoverRect.height > boundingBoxRect.bottom,
      bottom: positions.bottom + popoverRect.height + boundingBoxRect.top > viewHeight,
      left: positions.left + popoverRect.width > boundingBoxRect.right,
      right: positions.right + popoverRect.width + boundingBoxRect.left > viewWidth,
      centerX:
        positions.centerX + popoverRect.width > boundingBoxRect.right ||
        positions.centerX < boundingBoxRect.left,
      centerY:
        positions.centerY + popoverRect.height > boundingBoxRect.top ||
        positions.centerY < boundingBoxRect.bottom,
    }

    return { positions, exceedsBoundary }
  }

  protected setPosition() {
    const { positions, exceedsBoundary } = this.getPositionalData()
    const invert = {
      top: 'bottom',
      bottom: 'top',
      right: 'left',
      left: 'right',
    }
    const attributeFromCenterKey = { centerX: 'left', centerY: 'top' }

    for (let idx = 0; idx < 2; idx += 1) {
      // iterate for horizontal and vertical
      const axis = idx === 0 ? 'x' : 'y'
      const centerKey = `center${axis.toUpperCase()}` as CenterKey
      const positionKey = (
        this.alignment[axis] === 'center' ? centerKey : this.alignment[axis]
      ) as PositionKey

      let positionValue
      let attr

      const popoverdiv = this.$refs.popoverdiv as HTMLElement

      if (!exceedsBoundary[positionKey]) {
        // use preferred alignment
        attr = positionKey === centerKey ? attributeFromCenterKey[centerKey] : positionKey
        positionValue = positions[positionKey]
      } else {
        // use non-exceeding or preferred alignment if they all go out of bounds
        const options = axis === 'x' ? [centerKey, 'right', 'left'] : [centerKey, 'bottom', 'top']
        options.forEach((key) => {
          // look for fitting alignment option
          if (!exceedsBoundary[key as PositionKey]) {
            positionValue = positions[key as PositionKey]
            attr = key === centerKey ? attributeFromCenterKey[centerKey] : key
          }
        }) // use preferred alignment if none of the options fit
        if (!positionValue && !attr) {
          positionValue = positions[positionKey]
          attr = positionKey === centerKey ? attributeFromCenterKey[centerKey] : positionKey
        }
      }
      popoverdiv.style[attr as AttributeKey] = `${positionValue}px`
      popoverdiv.style[invert[attr as AttributeKey] as AttributeKey] = 'auto'
    }
  }

  mounted() {
    this.setBoundingBox()
    this.setPosition()
  }

  updated() {
    this.setPosition()
  }
}
</script>

<style lang="scss">
.popover {
  position: fixed;
  bottom: auto;
  top: auto;
  left: auto;
  right: auto;
  z-index: 1;
  background-color: white;
  color: black;
  padding: 0.5rem;
  border-radius: 4px;
  width: max-content;
  font-size: inherit;
  box-shadow:
    0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.popover:focus {
  outline: 0px solid transparent;
}
</style>
