<template>
  <nav class="nav mx-4">
    <router-link
      v-if="hasPermission(screenPermissions.clients)"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-clients"
      :to="{ name: screens.clients }"
    >
      Clients
    </router-link>

    <router-link
      v-if="hasPermission(screenPermissions.tasks)"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-tasks"
      :to="{ name: screens.tasks }"
    >
      Tasks
    </router-link>

    <router-link
      v-if="hasPermission(screenPermissions.dashboard)"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-dashboard"
      :to="{ name: screens.dashboard }"
    >
      Dashboard
    </router-link>

    <router-link
      v-if="hasPermission(screenPermissions.interactions)"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: screens.interactions }"
    >
      Interactions
    </router-link>

    <router-link
      v-if="hasPermission(screenPermissions.callCentre)"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: screens.callCentre }"
    >
      Call Centre
    </router-link>

    <router-link
      v-if="hasPermission(screenPermissions.tasks)"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: screens.tickets }"
    >
      Tickets
    </router-link>
    <router-link
      v-if="hasPermission(['partner_orders_manager'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: screens.partnerOrders }"
    >
      Bestellingen
    </router-link>

    <router-link
      v-if="hasPermission(screenPermissions.projectsView)"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: screens.projects }"
    >
      Projecten
    </router-link>

    <router-link
      v-if="hasPermission(['ERP:list_users'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'list-users' }"
    >
      Gebruikers
    </router-link>

    <router-link
      v-if="hasPermission(screenPermissions.clients)"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'view-groups' }"
    >
      Groups
    </router-link>

    <router-link
      v-if="hasPermission(['ERP:list_partner_users'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'partner-list' }"
    >
      Partners
    </router-link>

    <router-link
      v-if="hasPermission(['ERP:view_tasks_v2'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'tasks-v2' }"
    >
      TasksV2
    </router-link>

    <router-link
      v-if="hasPermission(['Eleena:view_alarms'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'alarms' }"
    >
      Alarms
    </router-link>

    <router-link
      v-if="hasPermission(['Tickets:view_cases'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'cases' }"
    >
      Cases
    </router-link>

    <router-link
      v-if="hasPermission(['ERP:view_polling'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'polling' }"
    >
      Polling
    </router-link>

    <router-link
      v-if="hasPermission(['ERP:view_orders'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'orders' }"
    >
      Purchase Orders
    </router-link>

    <router-link
      v-if="hasPermission(['ERP:view_buildings'])"
      class="nav-link mx-2 has-text-weight-semibold"
      data-id="router-interactions"
      :to="{ name: 'buildings' }"
    >
      Gebouwen
    </router-link>
  </nav>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { hasPermission, screenPermissions } from '@/logic/common/Permissions'
import {
  SCREEN_CLIENTS,
  SCREEN_TASKS,
  SCREEN_INTERACTIONS,
  SCREEN_DASHBOARD,
  SCREEN_CALL_CENTRE,
  SCREEN_TICKETS,
  SCREEN_PARTNER_ORDERS,
  SCREEN_PROJECTS,
} from '@/constants'

@Component({
  name: 'AppHeaderNavbar',
  computed: {
    ...mapState('auth', ['permissions']),
  },
})
export default class AppHeaderNavbar extends Vue {
  get screenPermissions() {
    return screenPermissions
  }

  get hasPermission() {
    return hasPermission
  }

  get screens() {
    return {
      clients: SCREEN_CLIENTS,
      tasks: SCREEN_TASKS,
      interactions: SCREEN_INTERACTIONS,
      dashboard: SCREEN_DASHBOARD,
      callCentre: SCREEN_CALL_CENTRE,
      tickets: SCREEN_TICKETS,
      partnerOrders: SCREEN_PARTNER_ORDERS,
      projects: SCREEN_PROJECTS,
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .nav-link {
  display: inline-block;
  position: relative;
  color: #bbb;
}

.nav-link.router-link-active {
  color: #fff;
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    width: 20px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #73c78f;
  }
}
</style>
