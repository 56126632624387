<template>
  <div class="ticket-form">
    <template v-for="[fieldGroup, groupField] in fieldGroups">
      <!-- TODO: implement images / services -->
      <template v-if="!['services'].includes(fieldGroup)">
        <div class="is-flex is-flex-wrap-wrap" :key="`group-${fieldGroup}`">
          <div class="box" :class="fieldGroup">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <details-box-heading :label="formatField(fieldGroup)" />
              <div v-if="getListOptions(fieldGroup).length > 0" class="is-flex">
                <dropdown
                  :items="getListOptions(fieldGroup)"
                  @change="(value) => onListSelectionChange(fieldGroup, value)"
                />
                <button class="button thin-button is-info" @click="addListItem(fieldGroup)">
                  Add
                </button>
              </div>
              <button
                v-else-if="getConfig(fieldGroup) && getConfig(fieldGroup).data_type === 'list'"
                class="button thin-button is-info"
                @click="addListItem(fieldGroup)"
              >
                Add
              </button>
            </div>

            <template v-if="!getConfig(fieldGroup) || !getConfig(fieldGroup).data_type">
              <template v-for="[fieldName, fieldData] in Object.entries(groupField)">
                <template v-if="getConfig(fieldGroup, fieldName)">
                  <details-box-heading
                    v-if="Array.isArray(fieldData) && fieldData.length > 0"
                    :label="formatField(fieldName)"
                    :key="fieldName"
                  />

                  <template v-if="Array.isArray(fieldData)">
                    <template v-for="(item, idx) in fieldData">
                      <details-maintenance-ticket-form-field
                        :key="JSON.stringify({ field: fieldName, index: idx, data: item })"
                        :config="getConfig(fieldGroup, fieldName)"
                        :listIndex="idx"
                        :input-data="item"
                        :remove="() => removeListItem(fieldGroup, fieldName, idx)"
                        @change="onFieldChange"
                      />
                    </template>
                  </template>

                  <details-maintenance-ticket-form-field
                    v-else
                    :key="JSON.stringify({ field: fieldName, data: fieldData })"
                    :config="getConfig(fieldGroup, fieldName)"
                    :input-data="fieldData"
                    :remove="() => removeListItem(fieldGroup, fieldName, idx)"
                    @change="onFieldChange"
                  />
                </template>
              </template>
            </template>
            <!-- ASSUME IMAGES FOR NOW -->
            <template v-else>
              <template v-if="Array.isArray(groupField)">
                <div class="is-flex is-flex-wrap-wrap is-align-items-flex-start">
                  <details-maintenance-ticket-form-field
                    v-for="(item, idx) in groupField"
                    :key="JSON.stringify({ index: idx, data: item })"
                    :config="getConfig(fieldGroup)"
                    :listIndex="idx"
                    :input-data="item"
                    :remove="() => removeListItem(fieldGroup, null, idx)"
                    :select-image="onSelectImage"
                    :selected-image-index="selectedImageIndex"
                    @change="onFieldChange"
                  />
                </div>
              </template>

              <details-maintenance-ticket-form-field
                v-else
                :key="JSON.stringify({ field: fieldGroup, data: groupField })"
                :config="getConfig(fieldGroup)"
                :input-data="groupField"
                @change="onFieldChange"
              />
            </template>
          </div>
        </div>
      </template>
    </template>

    <div class="is-flex is-flex-wrap-wrap">
      <div class="box save-ticket">
        <div class="box-content">
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <button
              class="button thin-button is-info is-small m-0"
              :disabled="isLoading"
              @click="refreshDetails"
            >
              Discard changes
            </button>

            <div class="is-flex is-align-items-center">
              <dropdown
                v-if="!ticket"
                class="mr-1"
                :items="partners"
                :preselect="selectedPartner"
                @change="onSelectPartner"
              />
              <button
                class="button thin-button is-success is-small m-0"
                :class="[isLoading && 'is-loading']"
                :disabled="isLoading || submitDisabled"
                @click="onSaveTicketInput"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <details-image-modal
      v-if="modalImageIndex !== null"
      :type="type"
      :close="onCloseModal"
      :images="modalImages"
      :initial-index="modalImageIndex"
    />
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop } from 'vue-property-decorator'

import requests from '@/requests'
// import notifications from '@/util/Notifications';
import { events, notifications } from '@/util'

import { STATUS_ERROR, STATUS_OK } from '@/constants'
import DetailsPaneBase from './DetailsPaneBase.vue'
import DetailsMaintenanceTicketFormField from './DetailsMaintenanceTicketFormField.vue'

interface FieldConfig {
  prop_name?: string
  label?: string
  field_group: string
  component_type?: string
  data_type?: string
  options?: Array<unknown>
  fields?: Array<FieldConfig>
}

interface FormConfig {
  [group: string]: {
    [field: string]: FieldConfig
  }
}

@Component({
  name: 'DetailsMaintenanceTicketForm',
  components: {
    DetailsMaintenanceTicketFormField,
  },
})
export default class DetailsMaintenanceTicketForm extends DetailsPaneBase {
  @Prop({}) config!: FormConfig
  @Prop({ default: null }) ticketItem!: any

  protected ticketInput: any = {}
  protected listSelectors: any = {}
  protected selectedPartner = ''
  protected isLoading = false

  protected selectedImageIndex: any = null
  protected modalImageIndex: number | null = null
  protected modalImages: any = []

  protected imagesDelete: Set<any> = new Set()

  get ticket() {
    if (!this.ticketItem) return null
    return this.ticketItem.ticket.input
  }

  get partners() {
    return this.components.installers.sort()
  }

  get imageComponent() {
    const image = this.ticketInput.images[this.selectedImageIndex]
    if (!image) return null
    return {
      name: image.type,
      value: image.url,
    }
  }

  get fieldGroups() {
    const fieldWeights = {
      extra_fields: 1,
      finance: 2,
      images: 3,
      products: 4,
    } as any
    const defaultWeightValue = Object.keys(fieldWeights).length + 1

    const groups = Object.entries(this.ticketInput)
    return groups.sort(([a], [b]) => {
      const av = fieldWeights[a] || defaultWeightValue
      const bv = fieldWeights[b] || defaultWeightValue
      return av - bv
    })
  }

  get submitDisabled() {
    if (!this.ticketInput.images) return false
    return this.ticketInput.images.some((image: any) => !image.type || !image.url)
  }

  protected clearSelectedImage() {
    this.selectedImageIndex = null
  }

  protected onImageUpload(file: File) {
    const targetImage = this.ticketInput.images[this.selectedImageIndex]
    const newImage = { ...targetImage, url: file }
    this.ticketInput.images.splice(this.selectedImageIndex, 1, newImage)
  }

  protected onSelectImage(listIndex: number) {
    this.selectedImageIndex = listIndex
  }

  protected onSelectPartner(partner: string) {
    this.selectedPartner = partner
  }

  protected getDefaultValue(dataType: string) {
    switch (dataType) {
      case 'number':
        return 1
      case 'list':
        return []
      case 'dict':
        return {}
      default:
        return ''
    }
  }

  protected getListOptions(group: string) {
    const options: Array<any> = []
    if (!this.config[group]) return options

    Object.entries(this.config[group]).forEach(([fieldName, fieldConfig]) => {
      if (fieldConfig.data_type === 'list') {
        options.push(fieldName)
      }
    })
    return options
  }

  protected onListSelectionChange(key: string, value: any) {
    this.listSelectors = { ...this.listSelectors, [key]: value }
    this.$forceUpdate()
  }

  protected setInitialListSelectors() {
    Object.keys(this.config).forEach((fieldGroup) => {
      const listOptions: Array<any> = this.getListOptions(fieldGroup)
      if (listOptions.length > 0) {
        const [selector] = listOptions
        this.listSelectors = { ...this.listSelectors, [fieldGroup]: selector }
      }
    })
  }

  protected addListItem(group: string) {
    const key = this.listSelectors[group]
    const targetList = key ? this.ticketInput[group][key] : this.ticketInput[group]
    if (!Array.isArray(targetList)) return

    let item: any

    const config = this.getConfig(group, key)
    if (!config) return

    if (config.fields) {
      ;(config.fields as Array<FieldConfig>)!.forEach((fieldConfig: any) => {
        let value: any
        if (fieldConfig.options) {
          ;[value] = fieldConfig.options
        } else {
          value = this.getDefaultValue(fieldConfig.data_type)
        }
        item = fieldConfig.prop_name
          ? { ...item, [fieldConfig.prop_name]: value }
          : { ...item, ...value }
      })
    } else {
      item = ''
    }

    targetList.push(item)
  }

  protected removeListItem(group: string, key: string | null, listIndex: number) {
    const list = key ? this.ticketInput[group][key] : this.ticketInput[group]
    if (!Array.isArray(list)) return

    if (group === 'images') {
      const image = list[listIndex]
      if (!(image.url instanceof Object)) {
        this.imagesDelete.add(image.type)
      }
    }

    list.splice(listIndex, 1)
  }

  protected getConfig(fieldGroup: string, field?: string) {
    if (!this.config[fieldGroup]) return null

    return field ? this.config[fieldGroup][field] : this.config[fieldGroup]
  }

  protected generateTicketInputData() {
    const ticketInput: any = {}
    Object.entries(this.config).forEach(([fieldGroup, fieldConfig]: [string, any]) => {
      if (fieldConfig.data_type) {
        ticketInput[fieldGroup] = this.getDefaultValue(fieldConfig.data_type)
      } else {
        const groupData: any = {}
        ticketInput[fieldGroup] = groupData
        Object.values(fieldConfig).forEach((cfg: any) => {
          groupData[cfg.prop_name!] = this.getDefaultValue(cfg.data_type)
        })
      }
    })

    return ticketInput
  }

  protected async onSaveTicketInput() {
    this.isLoading = true
    const inputJSON = { ...this.ticketInput }
    delete inputJSON.images

    const formData = new FormData()
    const json = this.ticket
      ? { maintenanceInput: inputJSON }
      : { maintenanceInput: inputJSON, assignedInstaller: this.selectedPartner }
    formData.append('body', JSON.stringify(json))
    this.ticketInput.images.forEach((image: any) => {
      if (!(image.url instanceof Object)) return
      const src = image.url.name.split('.')
      const ext = src[src.length - 1]
      formData.append('images', image.url, `${image.type}.${ext}`)
    })

    formData.append('images_to_delete', JSON.stringify(Array.from(this.imagesDelete)))

    const response = !this.ticket
      ? await requests.tasks.submitNewMaintenanceTicket(this.address, formData)
      : await requests.tasks.submitUpdateMaintenanceTicket(
          this.address,
          formData,
          this.ticketItem.id,
          this.ticketItem.ticket.partner
        )
    if (response.status !== STATUS_ERROR) {
      this.refreshDetails()
      notifications.addNotification({
        message: this.ticket ? 'Ticket updated' : 'Ticket created',
        type: 'success',
      })
    } else {
      notifications.addNotification({
        message: response.message,
        type: 'danger',
      })
    }

    this.isLoading = false
  }

  protected formatField(str: string) {
    return `${str[0].toUpperCase()}${str.slice(1).replaceAll('_', ' ')}`
  }

  protected onFieldChange({ config, data, listIndex }: { [prop: string]: any }) {
    if (config.data_type === 'list') {
      const list = config.prop_name
        ? this.ticketInput[config.field_group][config.prop_name]
        : this.ticketInput[config.field_group]
      list.splice(listIndex, 1, data)
      if (config.field_group === 'images') {
        this.forceUniqueImageFieldNames()
      }
    } else {
      this.ticketInput[config.field_group][config.prop_name] = data
    }
  }

  protected splitFieldNumber(field: string) {
    const match = field.match(/(?<number>_\d+$)/)
    const number = match ? parseInt(match.groups!.number.replace('_', ''), 10) : null
    return { field: field.replace(/_\d+$/, ''), number }
  }

  protected forceUniqueImageFieldNames() {
    // Get dict of field names stripped of '_N' and unchangable numbers (i.e. photo_1, photo_2)
    const images = this.ticketInput.images.reduce((acc: any, image: any) => {
      const { field, number } = this.splitFieldNumber(image.type)
      if (acc[field] === undefined) {
        acc[field] = {
          usedNumbers: [],
          instances: [],
        }
      }
      const { usedNumbers, instances } = acc[field]
      if (typeof image.url === 'string' && !!image.url) {
        // existing image, field name should not be changed
        usedNumbers.push(number)
      }
      instances.push(image)
      return acc
    }, {})

    // Append '_N' to field names that conflict
    Object.values(images).forEach((entry: any) => {
      const { usedNumbers, instances } = entry
      if (instances.length > 1) {
        instances.forEach((image: any) => {
          if (typeof image.url === 'string' && !!image.url) return

          const { field } = this.splitFieldNumber(image.type)
          let n = 0
          while (n < 25) {
            n += 1
            if (!usedNumbers.includes(n)) {
              // eslint-disable-next-line no-param-reassign
              image.type = `${field}_${n}`
              usedNumbers.push(n)
              break
            }
          }
        })
      }
    })

    this.$forceUpdate()
  }

  protected async setModalImages() {
    if (!this.ticketInput.images) this.modalImages = []

    const images = this.ticketInput.images.filter((image: any) => {
      if (image.url instanceof Object && !image.url.type.includes('image')) return false

      return image.type && image.url
    })

    this.modalImages = await Promise.all(
      images.map(
        (image: any) =>
          new Promise((resolve) => {
            const src = image.url
            const field = image.type
            if (src instanceof Object) {
              const reader = new FileReader()
              reader.onload = () => {
                resolve([field, { name: field, value: reader.result }])
              }
              reader.readAsDataURL(src)
            } else {
              resolve([field, { name: field, value: src }])
            }
          })
      )
    )
  }

  protected async onOpenModal(fieldName: string) {
    let index: number | null = null
    await this.setModalImages()

    for (let idx = 0; idx < this.modalImages.length; idx += 1) {
      const [field] = this.modalImages[idx]
      if (field === fieldName) {
        index = idx
        break
      }
    }

    this.modalImageIndex = index
  }

  protected onCloseModal() {
    this.modalImageIndex = null
  }

  mounted() {
    this.ticketInput = this.ticket || this.generateTicketInputData()
    this.setInitialListSelectors()
    ;[this.selectedPartner] = this.partners
    this.setModalImages()
    events.on('open-modal', this.onOpenModal)
  }

  beforeDestroy() {
    events.off('open-modal', this.onOpenModal)
  }
}
</script>
