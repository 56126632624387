<template>
  <component :is="is" :label="label" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import CreatedStatusLabel from './CreatedStatusLabel.vue'
import RejectedStatusLabel from './RejectedStatusLabel.vue'
import CancelledStatusLabel from './CancelledStatusLabel.vue'
import FinishedStatusLabel from './FinishedStatusLabel.vue'
import OngoingStatusLabel from './OngoingStatusLabel.vue'
import ApprovedStatusLabel from './ApprovedStatusLabel.vue'
import AssignedStatusLabel from './AssignedStatusLabel.vue'

@Component({
  components: {
    ApprovedStatusLabel,
    OngoingStatusLabel,
    FinishedStatusLabel,
    RejectedStatusLabel,
    CancelledStatusLabel,
    CreatedStatusLabel,
    AssignedStatusLabel,
  },
})
export default class StatusLabel extends Vue {
  @Prop({}) label!: string

  get is() {
    const components = this.$options.components || {}

    if (components[this.convertComponentName(`${this.label}-status-label`)]) {
      return `${this.label}-status-label`
    }
    return 'base-label'
  }

  private convertComponentName = (component: string) =>
    component
      .split('-')
      .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
      .join('')
}
</script>
