<template>
  <div class="modal-outer-wrapper">
    <div class="modal-inner-wrapper">
      <span class="close-modal is-clickable" @click="close"><i class="fas fa-times"></i></span>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'Modal',
})
export default class Modal extends Vue {
  @Prop({}) close!: Function

  protected onKeyDown(e: KeyboardEvent) {
    if (e.key.toLowerCase() === 'escape') {
      this.close()
    }
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  }
}
</script>

<style lang="scss">
.modal-outer-wrapper {
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: #000000cc;
  top: 0;
  left: 0;

  .modal-inner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .close-modal {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      font-size: 1rem;
    }
  }
}
</style>
