<template>
  <scroll-container
    :height="height"
    :minHeight="minHeight"
    :maxHeight="maxHeight"
    :border="border"
    :background="background"
    :overflow="overflow"
  >
    <table class="table is-bordered table-fixed">
      <slot></slot>
    </table>
  </scroll-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'ScrollableTable' })
export default class ScrollableTable extends Vue {
  @Prop({}) width!: number
  @Prop({ default: 'auto' }) height!: number | string
  @Prop({ default: 0 }) minHeight!: number | string
  @Prop({ default: 'none' }) maxHeight!: number | string
  @Prop({ default: 'none' }) border!: string
  @Prop({ default: 'transparent' }) background!: string
  @Prop({ default: 'auto' }) overflow!: string
}
</script>

<style lang="scss">
.table-fixed {
  table-layout: fixed;
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  tr {
    background-color: #fff;

    &:nth-child(2n + 1) {
      background-color: #f5f5f5;
    }
  }

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 0;

    background-color: #444;
    color: #fff !important;
  }

  td {
    padding: 0;
  }
}
</style>
