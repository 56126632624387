import { actions } from '@/store'
import { isBefore, isAfter, isSameDay } from 'date-fns'

import type {
  AddressDetails,
  ExistingGroup,
  GroupFilter,
  FilterState,
  RegExpAddressTokens,
} from '@/interfaces'

export const filterAddress = (item: AddressDetails, state: FilterState) => {
  if (!state.search) return true
  const re = new RegExp(`^${state.search}`, 'gi')
  return re.test(item.address)
}

export const filterAddressWithTokens = (item: AddressDetails, state: FilterState) => {
  const { street, houseNumber, postalCode } = state.tokens as RegExpAddressTokens

  const matchFunction = (value: string | undefined, token: RegExp | null) =>
    token && value ? token.test(value) : true

  return [
    matchFunction(item.street, street),
    matchFunction(item.houseNumber, houseNumber),
    matchFunction(item.postalCode, postalCode),
  ].every((result) => result)
}

export const filterNoGroup = (item: AddressDetails) => !item.isAssignedToGroup

export const filterGroupUser = (group: ExistingGroup, state: GroupFilter) =>
  !state.user || group.created_by === state.user

export const filterGroupWoco = (group: ExistingGroup, state: GroupFilter) =>
  !state.woco || group.woco === state.woco

export const filterGroupForCurrentUserOnly = (group: ExistingGroup) =>
  group.created_by === actions.auth.readUser()

export const filterSearchGroupName = (group: ExistingGroup, state: GroupFilter) => {
  if (!state.search) return true
  const reString = state.search.replace(/[\s_-]/g, '')
  const re = new RegExp(`${reString}`, 'i')
  return re.test(group.name.replace(/[\s_-]/g, ''))
}

export const filterUnavailable = (item: AddressDetails) => item.isAvailable

export const filterOnDate = (
  dateInput: string | Date | null | undefined,
  range: { start: string | Date; end: string | Date }
) => {
  const { start, end } = range
  if (!start && !end) return true // pass if range is empty
  if (!dateInput) return false // fail if input has no date but range is set

  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
  const dateStart = typeof start === 'string' ? new Date(start) : start
  const dateEnd = typeof end === 'string' ? new Date(end) : end

  return (
    (!start || isAfter(date, dateStart) || isSameDay(date, dateStart)) &&
    (!end || isBefore(date, dateEnd) || isSameDay(date, dateEnd))
  )
}
