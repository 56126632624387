<template>
  <span v-if="label" class="tag" :class="color">
    {{ label }}
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class BaseLabel extends Vue {
  @Prop({}) readonly label!: string

  protected color = 'is-info'
}
</script>

<style scoped lang="scss">
.tag {
  font-size: 10px !important;
  padding: 6px;
  // margin-left: 6px;
  margin: auto;
  height: 14px;
}
</style>
