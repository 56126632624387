<template>
  <div class="is-flex is-align-items-center">
    <master-data-filter :manager="manager" type="installation" />

    <!-- SIGNED AT DATE RANGE -->
    <div class="is-flex is-align-items-center mr-2">
      <span class="mr-1">Appointment at:</span>
      <dropdown-slotted min-width="0" margin-top="0.6rem">
        <template v-slot:trigger>
          <i class="far fa-calendar-alt has-text-white"></i>
        </template>

        <template v-slot:default="{ close }">
          <div class="has-text-black">
            <div class="is-flex is-align-items-center">
              <small-tag
                label="apply"
                margin="2px 4px"
                @click="() => onApplyAppointmentAt(close)"
              />
              <small-tag
                label="clear"
                margin="2px 0"
                color="is-info"
                @click="() => onClearAppointmentAt(close)"
              />
            </div>
            <date-range-picker
              :start="appointmentAt.start"
              :end="appointmentAt.end"
              @change="(range) => (appointmentAt = range)"
            />
          </div>
        </template>
      </dropdown-slotted>
    </div>

    <!-- INSTALLER FILTER -->
    <div class="is-flex is-align-items-center">
      <dropdown-slotted label="Installer" iconColor="#fff" min-width="0">
        <template v-slot:default>
          <div class="is-flex is-justify-content-center is-flex-wrap-wrap">
            <small-tag
              v-for="(item, idx) in installerList.items"
              :key="item"
              :label="item"
              :class="[idx !== installerList.items.length - 1 && 'mb-1']"
              :color="selectedInstallers.includes(item) ? 'is-dark' : 'is-light'"
              @click="(event) => onInstallerSelectionChange(item, event)"
            />
          </div>
        </template>
      </dropdown-slotted>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { TASK_TYPE_INSTALLATION } from '@/constants'
import { MultiSelectList } from '@/logic/common'
import type { TasksScreenManager } from '@/logic/TasksScreen'
import MasterDataFilter from './MasterDataFilter.vue'

@Component({
  name: 'TableHeadDataInstallation',
  components: {
    MasterDataFilter,
  },
})
export default class TableHeadDataInstallation extends Vue {
  @Prop() manager!: TasksScreenManager

  protected installerList = new MultiSelectList<string>([], {
    checkboxMode: true,
  })

  protected appointmentAt = {
    start: '',
    end: '',
  }

  get filter() {
    return this.manager.list.filter!
  }

  get installerOptions() {
    const installerOptions = new Set()
    this.manager.list.getItems().forEach((item) => {
      const ticketString = (item.getTaskData(TASK_TYPE_INSTALLATION).tickets || '') as string

      const tickets = ticketString.split(';')

      tickets.forEach((ticket) => {
        const installer = ticket.split(':')[0].trim()
        if (installer) {
          installerOptions.add(installer)
        }
      })
    })

    return Array.from(installerOptions) as Array<string>
  }

  get selectedInstallers() {
    return this.installerList.selectedItems
  }

  protected onClearAppointmentAt(closeCallback: Function) {
    this.appointmentAt = { start: '', end: '' }
    this.filter.setState({ installationAppointmentAt: this.appointmentAt })
    closeCallback()
  }

  protected onApplyAppointmentAt(closeCallback: Function) {
    this.filter.setState({ installationAppointmentAt: this.appointmentAt })
    closeCallback()
  }

  protected onTicketFilterChange(filterOption: string, closeCallback: Function) {
    this.filter.setState({ installationTickets: filterOption })
    closeCallback()
  }

  protected onInstallerSelectionChange(installer: string, event: MouseEvent) {
    this.installerList.onClickItem(installer, event)
    this.filter.setState({ installationInstallers: this.installerList.selectedItems })
  }

  mounted() {
    this.installerList.setItems(this.installerOptions)
    this.installerList.setPreselectedItems(this.filter.getStateProp('installationInstallers'))
  }
}
</script>
