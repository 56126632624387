<template>
  <div class="maintenance-data">
    <span v-if="client.tasks.maintenance" class="maintenance-info">
      <div v-for="(val, k, index) in client.tasks.maintenance" :key="index">
        <span v-if="String(k).includes('ticket')">
          <span><br /><b> Installer:</b> {{ val.split(':')[0] }}</span>
          <span class="maintenance-ticket-link"><b> Ticket id:</b> {{ val.split(':')[1] }}</span>
          <a
            :href="`https://tickets.ecorus.com/${val.split(':')[0]}/ticketing?invoice=
          Alle+tickets&ticket=${val.split(':')[1]}`"
            >Click here to go to the ticket</a
          >
        </span>
        <span v-else>
          <b>{{ String(k).replace('_', ' ') }}:</b> {{ val }}
        </span>
      </div>
    </span>
    <div v-else>
      <p>No Maintenance task exists yet for this address</p>
      <button class="button is-info is-small mt-2" @click="createMaintenanceTask">
        Create maintenance task
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import requests from '@/requests'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class CallCentreMaintenanceData extends Vue {
  @Prop({}) client!: any
  @Prop({}) manager!: any

  private createMaintenanceTask = async () => {
    await requests.tasks.createTask(this.manager.fullAddress, 'maintenance')
    this.manager.setSelectedAddress(this.manager.fullAddress)
  }
}
</script>

<style lang="scss" scoped>
.maintenance-data {
  display: flex;
  flex-direction: column;

  .maintenance-info {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.6rem;

    & > * {
      b {
        padding-right: 0.3rem;
      }
    }
  }

  .maintenance-ticket-link {
    display: block;
  }

  & > * {
    b {
      padding-right: 0.3rem;
    }
  }
}
</style>
