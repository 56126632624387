<template>
  <figure
    class="thumbnail-container"
    :style="`height: ${size}px; width: ${size}px;`"
    @click="$emit('click', $event)"
  >
    <img :src="src" :alt="alt" />
  </figure>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'Thumbnail' })
export default class Thumbnail extends Vue {
  @Prop({ default: 100 }) size!: number
  @Prop({ default: '' }) src!: string
  @Prop({ default: 'thumbnail' }) alt!: string
}
</script>

<style lang="scss" scoped>
.thumbnail-container {
  position: relative;
  overflow: hidden;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    min-width: 101%;
    min-height: 101%;
    max-width: 160%;
    max-height: 160%;
  }
}
</style>
