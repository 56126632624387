import request from '@/requests'
import { STATUS_OK } from '@/constants'
// import { notifications } from '@/util';

import type { Address, ExistingGroup } from '@/interfaces'

export default class SelectableAddress {
  public isAssignedToGroup: boolean
  public isAvailable: boolean
  public groups: Array<string> = ['Loading...']

  private _address: Address

  private timeoutId: number | undefined = undefined

  constructor(address: Address, isAssignedToGroup = false, isAvailable = false) {
    this._address = address
    this.isAssignedToGroup = isAssignedToGroup
    this.isAvailable = isAvailable
  }

  public get street() {
    return this._address.street
  }

  public get houseNumber() {
    return this._address.house_number
  }

  public get postalCode() {
    return this._address.postal_code
  }

  public get household_id() {
    return this._address.household_id
  }

  public get id() {
    return this._address.id
  }

  public get address() {
    return this._address.address
  }

  public handleMouseEvent = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = undefined
      return
    }

    this.timeoutId = setTimeout(async () => {
      const response = await request.groups.getAddressDetails(this.address)
      if (response.status !== STATUS_OK) {
        this.groups = ['Failed to load']
        return
      }
      this.groups = response.data.groups.map((group: ExistingGroup) => group.name)
    }, 500)
  }

  public json = () => ({ ...this._address })
}
