<template>
  <div>
    <div>
      <input
        class="tw-w-full tw-border tw-border-gray-300 tw-rounded-sm tw-py-1 tw-px-2 tw-mb-3"
        placeholder="Address search"
        v-model="queryInput"
      />
    </div>
    <div class="text-sm">
      <span v-if="numberOfTasks"> We found {{ numberOfTasks }} tasks </span>
    </div>
    <table class="tw-w-full tw-border-separate tw-border-spacing-y-3" v-if="list">
      <tr class="tw-leading-10">
        <th class="tw-w-[20px]" v-if="filteredTypeOptions?.length === 1"></th>
        <th class="tw-w-1/3">Address</th>
        <th v-for="typeOption in filteredTypeOptions" :key="typeOption" class="tw-capitalize">
          {{ typeOption }}
        </th>
        <th class="tw-w-1/2 tw-pl-2" v-if="filteredTypeOptions?.length === 1">
          <select
            v-model="selectedAction"
            class="tw-border tw-border-2 tw-border-gray-300 tw-rounded-lg tw-py-1 tw-px-2"
            @change="showConfirmationModal"
          >
            <option value="" disabled>Select Action</option>
            <option value="reopen">Options will be here</option>
          </select>
        </th>
      </tr>
      <task-list-item
        v-for="address in list"
        :address="address"
        :filteredTypeOptions="filteredTypeOptions"
        :selectedAddresses="selectedAddresses"
        class="hover:tw-bg-gray-100"
      />
    </table>
    <notification-message :message="notification.message" :type="notification.type" />
    <div v-if="showModal">
      <confirmation-modal
        :action="selectedAction"
        :addresses="selectedAddresses"
        @confirm="handleBulkAction"
        @cancel="hideConfirmationModal"
      />
    </div>
    <div class="flex" v-show="hasNextPage">
      <div class="tw-mx-auto tw-mt-3 tw-mb-5">
        <button
          @click="fetchNextPage"
          class="tw-bg-blue-400 tw-rounded tw-p-1 disabled:tw-bg-gray-100"
        >
          Load more...
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useQuery, useInfiniteQuery, useQueryClient } from '@tanstack/vue-query'
import { ref, computed, reactive } from 'vue'
import ConfirmationModal from './ConfirmationModal.vue'
import fetchData from '@/util/fetch'
import store from '@/store'
import { watchDebounced } from '@vueuse/core'
import NotificationMessage from './NotificationMessage.vue'
import TaskListItem from './TaskListItem.vue'

const props = defineProps({
  filter: {
    type: Object,
    required: true,
  },
  filteredTypeOptions: {
    type: Array,
    required: true,
  },
})

const queryClient = useQueryClient()
const showModal = ref(false)
const selectedAddresses = ref([])
const queryInput = ref('')
const search = reactive({
  query: '',
})
const notification = ref({ message: '', type: '' })

const showConfirmationModal = () => {
  showModal.value = true
}

const hideConfirmationModal = () => {
  showModal.value = false
}

const selectedAction = ref('')

const handleBulkAction = () => {
  console.log('Selected Rows:', selectedAddresses.value)
  console.log('Selected Action:', selectedAction.value)

  for (const addressId of selectedAddresses.value) {
    console.log(`Updating address id ${addressId} with action ${selectedAction.value}`)
  }
  selectedAddresses.value = []
  selectedAction.value = ''
  hideConfirmationModal()
}

const perPage = 30
const getList = ({ pageParam = 1 }) => {
  const { statuses, projects, groups, wocos } = filter.value

  const urlParams = new URLSearchParams({
    view: 'list',
    offset: (pageParam - 1) * perPage,
    limit: perPage,
    query: search.query,
  })
  if (statuses) {
    for (const statusKey in statuses) {
      const statusValues = statuses[statusKey]
      for (const status of statusValues) {
        urlParams.append(`${statusKey}_statuses`, status)
      }
    }
  }
  if (projects) {
    for (const p of projects) {
      urlParams.append('project_ids', p)
    }
  }
  if (groups) {
    for (const g of groups) {
      urlParams.append('group_ids', g)
    }
  }
  if (wocos) {
    urlParams.append('woco', wocos)
  }
  return fetchData(`/groups/api/v2/address?${urlParams}`, {
    headers: {
      Authorization: `Bearer ${store.getters['auth/getToken']}`,
    },
  })
}

watchDebounced(
  queryInput,
  () => {
    search.query = queryInput.value
  },
  {
    debounce: 300,
  }
)

const filter = computed(() => props.filter)
const addressQuery = useInfiniteQuery({
  queryKey: computed(() => ['taskAddresses', filter.value, search.query]),
  keepPreviousData: true,
  queryFn: getList,
  keepPreviousData: true,
  getNextPageParam: (_, allPages) =>
    allPages[allPages.length - 1].data.length === perPage ? allPages.length + 1 : undefined,
  getPreviousPageParam: (_, allPages) => allPages.length - 1,
  getPreviousPageParam: (_, allPages) => allPages.length - 1,
})

const numberOfTasks = computed(() => addressQuery?.data?.value?.pages[0].total)
const fetchNextPage = addressQuery.fetchNextPage
const hasNextPage = addressQuery.hasNextPage
const list = computed(() => addressQuery?.data?.value?.pages.flatMap((p) => p.data))
</script>
