<template>
  <div :class="['dropdown is-flex is-align-items-center', margin]">
    <label v-if="label" class="label my-0 mr-2">{{ label }}</label>
    <div class="select">
      <select
        @change="onChange"
        :id="id"
        :style="width && `width: ${width}px;`"
        :disabled="disabled"
        :data-id="dataId"
      >
        <option
          v-for="item in list.items"
          :key="`dropdown-item-${controller.getDisplayPropertyValue(item, display)}`"
          :selected="list.isSelected(item)"
          :value="
            item.value || (display ? controller.getDisplayPropertyValue(item, display) : item)
          "
        >
          {{ display ? controller.getDisplayPropertyValue(item, display) : item }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { isDeepEqual } from '@/util'
import { DropdownController } from '@/logic/common'

@Component({ name: 'Dropdown' })
export default class Dropdown extends Vue {
  @Prop({ default: '' }) label!: string
  @Prop({ default: () => [] }) items!: Array<unknown>
  @Prop({ default: '' }) preselect!: unknown
  @Prop({ default: '' }) margin!: string
  @Prop({ default: '' }) display!: string
  @Prop({ default: '' }) id!: string
  @Prop({ default: '' }) width!: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({}) dataId!: string

  private controller = new DropdownController(this.items, this.display, this.preselect)

  get list() {
    return this.controller.list
  }

  onChange(event: InputEvent) {
    const target = event.target as HTMLInputElement
    const targetItem = this.list.items.find((item: unknown) => {
      if ((item as any).value) return String((item as any).value) === target.value
      return (this.controller.getDisplayPropertyValue(item) as string).trim() === target.value
    })

    if (targetItem) {
      this.$emit('change', targetItem)
    } else {
      console.warn(`Failed to find dropdown item with value: [${target.value}]`)
    }
  }

  @Watch('preselect')
  onPreselectedChange(newValue: unknown, oldValue: unknown) {
    if (!isDeepEqual(newValue, oldValue)) {
      this.controller.setPreselectedItems(this.preselect)
    }
  }

  @Watch('items')
  onItemsChange() {
    this.list.setItems(this.items)
  }
}
</script>
