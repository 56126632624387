<template>
  <div class="box">
    <div class="appointment mb-2">
      <details-box-heading label="Appointment date" />
      <details-static-data-field field="Date" :value="appointmentDateValue" />
    </div>
    <template v-if="this.note">
      <div class="appointment mb-2">
        <details-box-heading label="Notes" />
        <p v-for="note in this.note" :key="`work-${note}`">- {{ note }}</p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import DetailsPaneBase from './DetailsPaneBase.vue'

@Component({
  name: 'DetailsTicketAppointmentBox',
  components: {
    DetailsPaneBase,
  },
})
export default class DetailsTicketAppointmentBox extends DetailsPaneBase {
  @Prop({}) ticket!: any

  get note() {
    if (
      !(
        this.ticket &&
        this.ticket.ticket &&
        this.ticket.ticket.note &&
        this.ticket.ticket.note.length > 0
      )
    )
      return null

    return this.ticket.ticket.note
  }

  get appointmentDateValue() {
    if (!this.appointmentDate) return 'To be planned'

    const dateStr = this.appointmentDate.replaceAll('-', ' ').slice(0, -3)
    return `${this.getDay(new Date(this.appointmentDate))}${dateStr}`
  }

  get appointmentDate() {
    if (
      !(
        this.ticket &&
        this.ticket.ticket &&
        this.ticket.ticket.appointment &&
        this.ticket.ticket.appointment.start
      )
    )
      return null

    return this.ticket.ticket.appointment.start
  }

  protected getDay(date: Date) {
    enum days {
      'Sun ',
      'Mon ',
      'Tue ',
      'Wed ',
      'Thu ',
      'Fri ',
      'Sat ',
    }

    const dayNumber = date.getDay()
    return days[dayNumber]
  }
}
</script>
