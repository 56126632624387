<template>
  <div class="inspection-content">
    <template v-if="hasTicketData && isEcorusTicket">
      <!-- TICKET CANCEL -->
      <div
        v-if="selectedTicket && selectedTicket.actions.cancel_ticket_button"
        class="is-flex is-flex-wrap-wrap"
      >
        <details-ticket-cancellation-box
          :manager="manager"
          :tickets="tickets"
          :selected="selectedTicketIndex"
        />
      </div>

      <!-- TICKET APPROVAL -->
      <div
        v-if="
          selectedTicket.actions.approve_ticket_button ||
          selectedTicket.actions.reject_ticket_button
        "
        class="is-flex is-flex-wrap-wrap"
      >
        <details-ticket-approval-box
          :manager="manager"
          :tickets="tickets"
          :selected="selectedTicketIndex"
        />
      </div>
    </template>

    <template v-else-if="hasTicketData">
      <div class="box">
        <details-box-heading label="Ticket" />
        <div style="margin-bottom: 0.8rem">
          <span><b>Installer: </b>{{ selectedTicket.installer }}</span
          ><br />
          <span><b>Ticket ID: </b>{{ selectedTicket.id }}</span>
        </div>
        <div
          style="padding-bottom: 0.1rem"
          v-for="[key, value] in getNonEcorusTicketFields"
          :key="key"
        >
          <span
            ><b>{{ formatKey(key) }}:</b> {{ value }}</span
          >
        </div>
        <details-ticket-images :manager="manager" :images="images" />
      </div>
    </template>

    <!-- TICKETS / APPOINTMENT  -->
    <div class="is-flex is-flex-wrap-wrap">
      <details-ticket-box
        v-if="shouldRenderComponentBox(components.tickets)"
        :manager="manager"
        :tickets="tickets"
        :selected="selectedTicketIndex"
        @change="updateTicketSelection"
      />

      <details-ticket-appointment-box
        v-if="hasTicketData"
        :manager="manager"
        :ticket="selectedTicket"
      />
    </div>

    <!-- OUTPUT / INPUT -->
    <div class="is-flex is-flex-wrap-wrap">
      <details-inspection-output-box
        v-if="hasTicketOutput"
        :output="selectedTicket.ticket.output"
      />

      <details-inspection-input-box
        v-if="hasTicketInput"
        :key="selectedTicketIndex"
        :manager="manager"
        :input="selectedTicket.ticket.input"
        :selectedTicket="selectedTicket"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import DetailsPaneBase from './DetailsPaneBase.vue'
import DetailsTicketBox from './DetailsTicketBox.vue'
import DetailsTicketAppointmentBox from './DetailsTicketAppointmentBox.vue'
import DetailsInspectionOutputBox from './DetailsInspectionOutputBox.vue'
import DetailsInspectionInputBox from './DetailsInspectionInputBox.vue'
import DetailsTicketImages from './DetailsTicketImages.vue'
import DetailsTicketApprovalBox from './DetailsTicketApprovalBox.vue'
import DetailsTicketStatusCommentBox from './DetailsTicketStatusCommentBox.vue'
import DetailsTicketCancellationBox from './DetailsTicketCancellationBox.vue'

interface Dictionary {
  [key: string]: Dictionary
}

@Component({
  name: 'DetailsPaneInspection',
  components: {
    DetailsTicketBox,
    DetailsTicketAppointmentBox,
    DetailsInspectionOutputBox,
    DetailsInspectionInputBox,
    DetailsTicketApprovalBox,
    DetailsTicketImages,
    DetailsTicketStatusCommentBox,
    DetailsTicketCancellationBox,
  },
})
export default class DetailsPaneInspection extends DetailsPaneBase {
  protected selectedTicketIndex: number | null = null

  get selectedTicket() {
    if (this.selectedTicketIndex === null) return null

    return this.tickets[this.selectedTicketIndex]
  }

  get tickets() {
    return this.components.tickets
  }

  get isEcorusTicket() {
    return (
      this.hasTicketData &&
      ['input', 'output', 'appointment', 'status'].every((field) =>
        Object.keys(this.selectedTicket.ticket).includes(field)
      )
    )
  }

  get hasTicketData() {
    return !!(this.selectedTicket && this.selectedTicket.ticket)
  }

  get hasTicketOutput() {
    return !!(this.isEcorusTicket && this.selectedTicket.ticket.output)
  }

  get hasTicketInput() {
    return !!(this.hasTicketData && this.selectedTicket.ticket.input)
  }

  get getNonEcorusTicketFields() {
    return Object.entries(this.selectedTicket.ticket)
      .filter((entry: [string, unknown]) => entry[1])
      .filter(
        (entry: [string, unknown]) =>
          !entry[0].toLowerCase().includes('foto') &&
          !entry[0].toLowerCase().includes('screenshot') &&
          !entry[0].toLowerCase().includes('output')
      )
  }

  get images() {
    return Object.entries(this.selectedTicket.ticket)
      .filter(
        (entry: [string, unknown]) =>
          entry[0].toLowerCase().includes('foto') || entry[0].toLowerCase().includes('screenshot')
      )
      .filter((entry: [string, unknown]) => entry[1])
      .map((entry: [string, unknown]) => ({ type: entry[0], url: entry[1] }))
  }

  protected formatKey(key: string) {
    let newKey = key.replaceAll('_', ' ')
    newKey = newKey.replace(/([A-Z][a-z])/g, ' $1')
    newKey = newKey.toLowerCase()
    newKey = newKey.charAt(0).toUpperCase() + newKey.slice(1)
    return newKey
  }

  protected capitalized(str: string) {
    return (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ')
  }

  protected updateTicketSelection(ticketIndex: number) {
    this.selectedTicketIndex = ticketIndex
  }

  created() {
    if (this.tickets && this.tickets.length > 0) {
      this.selectedTicketIndex = this.tickets.length - 1
    }
  }
}
</script>
