<template>
  <div class="is-flex is-align-items-center">
    <!-- STATUS TYPE DROPDOWN -->
    <dropdown-slotted label="Status" iconColor="#fff" min-width="0">
      <template v-slot:default>
        <div
          v-for="item in selectionList.items"
          :key="item.props.label"
          :class="[
            containerClasses,
            selectedStatuses.includes(item.props.label) && 'selected',
            item === hovered && 'hovered',
          ]"
          @click="(event) => selectionList.onClickItem(item, event)"
          @mouseenter.prevent="() => (hovered = item)"
          @mouseleave.prevent="() => (hovered = null)"
        >
          <component :is="item.component" v-bind="item.props" />
        </div>
      </template>
    </dropdown-slotted>

    <!-- STATUS DATE RANGE -->
    <dropdown-slotted min-width="0" margin-top="10px">
      <template v-slot:trigger>
        <i class="far fa-calendar-alt has-text-white"></i>
      </template>

      <template v-slot:default="{ close }">
        <div class="has-text-black">
          <div class="is-flex is-align-items-center">
            <small-tag label="apply" margin="2px 4px" @click="() => onApplyStatusAt(close)" />
            <small-tag
              label="clear"
              margin="2px 0"
              color="is-info"
              @click="() => onClearStatusAt(close)"
            />
          </div>
          <date-range-picker
            :start="statusAt.start"
            :end="statusAt.end"
            @change="(range) => (statusAt = range)"
          />
        </div>
      </template>
    </dropdown-slotted>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { MultiSelectList } from '@/logic/common'

import type { TasksScreenManager } from '@/logic/TasksScreen'
import type { TaskTypeKey } from '@/interfaces'
import {
  ApprovedStatusLabel,
  AssignedStatusLabel,
  CancelledStatusLabel,
  CreatedStatusLabel,
  FinishedStatusLabel,
  OngoingStatusLabel,
  RejectedStatusLabel,
  EmptyStatusLabel,
} from '../actions'

interface StatusSelectionOption {
  component: string
  props: {
    label: string
  }
}

@Component({
  name: 'TableHeadStatus',
  components: {
    ApprovedStatusLabel,
    AssignedStatusLabel,
    CancelledStatusLabel,
    CreatedStatusLabel,
    FinishedStatusLabel,
    OngoingStatusLabel,
    RejectedStatusLabel,
    EmptyStatusLabel,
  },
})
export default class TableHeadStatus extends Vue {
  @Prop() manager!: TasksScreenManager
  @Prop({ default: 'design' }) type!: TaskTypeKey

  protected selectionList = new MultiSelectList<StatusSelectionOption>([], {
    checkboxMode: true,
  })

  protected hovered = null

  protected containerClasses = [
    'status-item  my-1 ',
    'is-flex is-justify-content-center',
    'is-clickable is-unselectable',
  ]

  protected statusAt = {
    start: '',
    end: '',
  }
  // protected statusAt = this.getInitialStatusAtState();

  get list() {
    return this.manager.list
  }

  get listItems() {
    return this.list.items
  }

  get selectedItems() {
    return this.selectionList.selectedItems
  }

  get filter() {
    return this.list.filter!
  }

  get options() {
    const statusMap = this.list.items.reduce(
      (acc, item) => {
        const itemStatus = item.getStatus(this.type)
        return itemStatus ? { ...acc, [itemStatus]: true } : acc
      },
      {} as { [status: string]: boolean }
    )

    const options = Object.keys(statusMap).map((status) => ({
      component: `${status}-status-label`,
      props: {
        label: status,
      },
    }))

    options.sort((a, b) => a.props.label.localeCompare(b.props.label))

    if (this.list.items.some((item) => !item.getStatus(this.type))) {
      options.unshift({
        component: 'empty-status-label',
        props: {
          label: 'no status',
        },
      })
    }

    return options
  }

  get selectedStatuses() {
    return this.selectionList.selectedItems.map((item) => item.props.label)
  }

  @Watch('selectedItems')
  protected onSelectionChange() {
    const selectedStatuses = this.selectedItems.map((item) => item.props.label)

    this.filter.setState({
      [`${this.type}Status`]: selectedStatuses,
    })
  }

  @Watch('listItems')
  protected onListItemsChange() {
    const { options } = this
    const selectedOptions = options.filter(({ props }) => {
      const filterStateStatus = this.filter.state[`${this.type}Status`] as Array<string>
      return filterStateStatus && filterStateStatus.includes(props.label)
    })
    this.selectionList.setItems(this.options)
    this.selectionList.setPreselectedItems(selectedOptions)
  }

  protected onClearStatusAt(closeCallback: Function) {
    this.statusAt = { start: '', end: '' }
    this.filter.setState({ [`${this.type}StatusAt`]: this.statusAt })
    closeCallback()
  }

  protected onApplyStatusAt(closeCallback: Function) {
    this.filter.setState({
      [`${this.type}StatusAt`]: this.statusAt,
    })
    closeCallback()
  }

  protected getInitialStatusAtState() {
    const filterState = this.filter.state[`${this.type}StatusAt`] as {
      start: string
      end: string
    }

    return { ...filterState }
  }

  mounted() {
    this.statusAt = this.getInitialStatusAtState()
    this.selectionList.setItems(this.options)
  }
}
</script>

<style lang="scss" scoped>
.status-item:not(.hovered):not(.selected) {
  .tag {
    background-color: #f5f5f5 !important;
    color: #000;
  }
}
</style>
