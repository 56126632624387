<template>
  <input
    class="input"
    :value="value"
    :class="[colorClass]"
    :type="type"
    :id="id"
    :data-id="dataId"
    @input="onChange"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'TextInput' })
export default class TextInput extends Vue {
  @Prop({ default: 'text' }) readonly type!: string
  @Prop({ default: '' }) color!: string
  @Prop() value!: string
  @Prop({ default: '' }) id!: string
  @Prop({}) dataId!: string

  onChange(event: InputEvent) {
    this.$emit('change', event)
    this.$forceUpdate()
  }

  get colorClass() {
    return `is-${this.color}`
  }
}
</script>
