export { default as requestHandler, RequestHandler } from './RequestHandler'
export { default as notifications, Notifications } from './Notifications'
export { default as plugins, Plugins } from './Plugins'
export { default as events, Events } from './Events'
export { default as Timer } from './Timer'
export { copyToClipboard } from './Clipboard'
export { downloadExcel } from './Excel'
export {
  formatDateTime,
  formatDate,
  formatDateTimeAlt,
  isDateTimeField,
  addZeroPadding,
} from './DateTime'

export {
  imageFileExtensions,
  tableFileExtensions,
  getFileExtensionFromURL,
  hasImageFileExtension,
  hasTableFileExtension,
} from './Files'

export const capitalize = (str: string) =>
  (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ')

export const escapeRegExpChars = (str: string) => str.replace(/[()$^[\]?+*.]/g, '\\$&')

export const pipe =
  <T>(...args: Function[]) =>
  (input: unknown) =>
    args.reduce((v, fn) => fn(v), input) as T

export const composeFilter =
  (...args: Function[]) =>
  <T>(input: Array<T>, filterState?: object) =>
    input.filter((item) => args.every((arg) => arg(item, filterState)))

export const composeSort =
  <T>(...args: Function[]) =>
  (input: Array<T>) => {
    input.sort((a, b) => {
      for (let idx = 0; idx < args.length; idx += 1) {
        const sortResult = args[idx](a, b)
        if (sortResult !== 0) {
          return sortResult
        }
      }
      return 0
    })
  }

export const composePureSort =
  <T>(...args: Function[]) =>
  (input: Array<T>) => {
    const inputCopy = [...input]
    composeSort(...args)(inputCopy)
    return inputCopy
  }

export const invertSort = (sortingMethod: Function) => (a: unknown, b: unknown) =>
  -sortingMethod(a, b)

export const print = (...args: unknown[]) => {
  console.log(...args.map((arg) => JSON.parse(JSON.stringify(arg))))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDeepEqual(a: any, b: any, treatFunctionAsEqual = false): boolean {
  if (a === b) return true // pointer, boolean, primitive, null
  if (a === null || b === null) return false // null counts as type object, check it early

  if (typeof a !== typeof b) return false
  if (typeof a === 'function') return treatFunctionAsEqual
  // typeof NaN -> number, NaN === NaN -> false, check specifically for NaN
  if (Number.isNaN(a) && Number.isNaN(b)) return true
  if (Number.isNaN(a) || Number.isNaN(b)) return false
  // non-matching primitives
  if (a !== b && typeof a !== 'object') return false

  // Compare various object types
  const objectTypes = [Map, WeakMap, Set, Array]
  const isNotSameType = objectTypes.some((obj) => a instanceof obj !== b instanceof obj)
  if (isNotSameType) return false

  if (a instanceof Array) {
    if (a.length !== b.length) return false

    for (let idx = 0; idx < a.length; idx += 1) {
      if (!isDeepEqual(a[idx], b[idx])) return false
    }
    return true
  }
  if (a instanceof Set) {
    if (a.size !== b.size) return false
    if (new Set([...a, ...b]).size !== a.size) return false
    return true
  }
  if (a instanceof Map) {
    if (a.size !== b.size) return false
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of a) {
      if (!b.has(key) || !isDeepEqual(value, b.get(key))) return false
    }
    return true
  }
  if (a instanceof WeakMap) {
    console.log('WeakMap found - returning false')
    return false
  }
  if (a instanceof Object) {
    return isDeepEqual(
      Object.entries(a).sort(([a1], [a2]) => a1.localeCompare(a2)),
      Object.entries(b).sort(([b1], [b2]) => b1.localeCompare(b2))
    )
  }
  // Should never get here, if it does it means something is missing
  console.warn('Failed to catch comparison condition:', a, b)
  return false
}

export function deepCopy<T>(input: T): T {
  if (input === null) return input

  if (Array.isArray(input)) {
    return input.reduce((acc, item) => [...acc, deepCopy(item)], [])
  }

  if (typeof input === 'object') {
    return Object.entries(input).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: deepCopy(v),
      }),
      {}
    ) as T
  }

  return input
}
