<template>
  <table class="table dashboard-table is-bordered is-hoverable">
    <thead>
      <tr>
        <dashboard-table-header
          v-for="week in Object.keys(statistics.week)"
          :key="`week-${week}`"
          :week="week"
          :current="currentWeek === week"
          :showYTD="manager.showYTD"
        />
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="type in Object.keys(statistics.total)"
        :key="`weekly-row-${type}`"
        @click="$emit('click', $event, type)"
      >
        <td v-for="[week, data] in Object.entries(statistics.week)" :key="`${type}-week-${week}`">
          <div class="is-flex has-text-right is-align-items-center">
            <!-- YTD -->
            <div v-if="manager.showYTD" style="flex: 1; align-self: flex-start">
              <div class="is-flex is-flex-direction-column">
                <span class="fixed-height">{{ data[type][defaultStatus].ytd }}</span>
                <template v-if="isExpanded(type)">
                  <span
                    class="fixed-height"
                    v-for="[status, values] in Object.entries(data[type])"
                    :key="`${type}-${status}-${values.ytd}`"
                    :title="`${type} - ${status}`"
                  >
                    {{ values.ytd }}
                  </span>
                </template>
              </div>
            </div>

            <!-- FORECAST -->
            <div style="flex: 1; align-self: flex-start">
              <span v-if="!enabledInputWeeks.includes(week)">
                {{ data[type][defaultStatus].forecast }}
              </span>
              <span v-else>
                <input
                  v-if="projectSelection.length && enabledInputWeeks.includes(week)"
                  data-id="dashboard-forecast-input"
                  type="number"
                  class="forecast-input"
                  :placeholder="data[type][defaultStatus].forecast"
                  @change="(event) => onForecastChange(event, week, type)"
                />
              </span>
            </div>

            <!-- ACTUALS -->
            <div style="flex: 1">
              <div class="is-flex is-flex-direction-column">
                <span class="fixed-height">{{ data[type][defaultStatus].actual }}</span>
                <template v-if="isExpanded(type)">
                  <span
                    class="fixed-height"
                    v-for="[status, values] in Object.entries(data[type])"
                    :key="`${type}-${status}-${values.actual}`"
                    :title="`${type} - ${status}`"
                  >
                    {{ values.actual }}
                  </span>
                </template>
              </div>
            </div>

            <!-- DIFFERENCE -->
            <div style="flex: 1.5; align-self: flex-start">
              <div class="is-flex is-flex-direction-column">
                <dashboard-week-actuals :data="data[type][defaultStatus]" />
                <template v-if="isExpanded(type)">
                  <dashboard-week-actuals
                    v-for="[status, values] in Object.entries(data[type])"
                    :key="`${type}-${status}-${values.actual}`"
                    :data="values"
                  />
                </template>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import request from '@/requests'
import { STATUS_OK } from '@/constants'

import type { DashboardScreenManager } from '@/logic/DashboardScreen'
import DashboardTableHeader from './DashboardTableHeader.vue'
import DashboardWeekActuals from './DashboardWeekActuals.vue'

type TaskType = 'design' | 'installation' | 'sales'

@Component({
  name: 'DashboardTableStatusWeekly',
  components: {
    DashboardTableHeader,
    DashboardWeekActuals,
  },
})
export default class DashboardTableStatusWeekly extends Vue {
  @Prop({}) manager!: DashboardScreenManager
  @Prop({ default: '' }) expandedRow!: string

  protected defaultStatus = 'approved'

  get statistics() {
    return this.manager.statistics
  }

  get currentWeek() {
    return this.manager.currentWeek
  }

  get projectSelection() {
    return this.manager.selectedProjects
  }

  get enabledInputWeeks() {
    return this.manager.getEnabledForecastWeeks()
  }

  protected isExpanded(type: string) {
    return type === this.expandedRow
  }

  protected async onForecastChange(event: InputEvent, week: string, taskType: TaskType) {
    const target = event.target as HTMLInputElement
    const project = this.projectSelection[0]
    const response = await request.dashboard.submitForecast(
      project,
      taskType,
      Number(target.value),
      week
    )

    if (response.status === STATUS_OK) {
      this.manager.insertForecastItem(response.data, project, week, taskType)

      target.value = ''
    }
  }
}
</script>
