var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"maintenance-content"},[_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('div',{staticClass:"box client-info"},[_c('details-box-heading',{attrs:{"label":"Client details"}}),_c('div',{staticClass:"box-content"},[_c('p',[_c('b',[_vm._v("Name: ")]),_c('span',[_vm._v(_vm._s(_vm.components.client_info.name))])]),_c('p',[_c('b',[_vm._v("Phone: ")]),_c('span',[_vm._v(_vm._s(_vm.components.client_info.phone))])]),_c('p',[_c('b',[_vm._v("Email: ")]),_c('span',[_vm._v(_vm._s(_vm.components.client_info.email))])])])],1)]),_c('div',{staticClass:"box"},[_c('details-box-heading',{attrs:{"label":"Manual"}}),(['created'].includes(_vm.components.status.status))?_c('div',[_c('details-action-button',{attrs:{"component":{
          required: false,
          enabled: true,
          name: 'Solve Manually',
          label: 'Solve Manually',
          action: {
            update: `api/v1/address/${_vm.address}/task_type/maintenance/action/start`,
          },
        }},on:{"click":_vm.refreshDetails}})],1):_c('button',{staticClass:"button is-small is-success",on:{"click":_vm.refresh}},[_vm._v("Refresh")])],1),(_vm.showInputForm)?_c('details-maintenance-ticket-form',{key:_vm.selectedTicketIndex,attrs:{"manager":_vm.manager,"config":_vm.components.ticket_form,"ticket-item":_vm.selectedTicket}}):_vm._e(),(_vm.hasTicketData && !_vm.showInputForm)?[(['cancelled', 'rejected'].includes(_vm.selectedTicket.ticket.current_status))?_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('details-ticket-status-comment-box',{attrs:{"manager":_vm.manager,"tickets":_vm.tickets,"selected":_vm.selectedTicketIndex}})],1):_vm._e(),(_vm.selectedTicket && _vm.selectedTicket.actions.cancel_ticket_button)?_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('details-ticket-cancellation-box',{attrs:{"manager":_vm.manager,"tickets":_vm.tickets,"selected":_vm.selectedTicketIndex}})],1):_vm._e(),(
        _vm.selectedTicket.actions.approve_ticket_button ||
        _vm.selectedTicket.actions.reject_ticket_button
      )?_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('details-ticket-approval-box',{attrs:{"manager":_vm.manager,"tickets":_vm.tickets,"selected":_vm.selectedTicketIndex,"has-reject-type":false}})],1):_vm._e()]:_vm._e(),(!_vm.showInputForm)?_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('details-ticket-box',{attrs:{"manager":_vm.manager,"tickets":_vm.tickets,"selected":_vm.selectedTicketIndex},on:{"change":_vm.updateTicketSelection,"new-ticket":_vm.onNewTicket,"change-installer":function($event){_vm.changeInstallerModal = true}}}),(_vm.components.eleena_data)?_c('details-eleena-box',{attrs:{"manager":_vm.manager}}):_vm._e(),(_vm.hasTicketData)?_c('details-ticket-appointment-box',{attrs:{"ticket":_vm.selectedTicket,"manager":_vm.manager}}):_vm._e()],1):_vm._e(),(!_vm.showInputForm)?_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[(_vm.hasTicketOutput)?_c('details-maintenance-output-box',{attrs:{"output":_vm.selectedTicket.ticket.output,"manager":_vm.manager}}):_vm._e(),(_vm.hasTicketInput)?_c('details-maintenance-input-box',{key:_vm.selectedTicketIndex,attrs:{"input":_vm.selectedTicket.ticket.input,"selectedTicket":_vm.selectedTicket,"manager":_vm.manager},on:{"edit":() => (_vm.showInputForm = true),"edit-finance":function($event){_vm.editTicketModal = true}}}):_vm._e()],1):_vm._e(),_c('edit-ticket-modal',{attrs:{"active":_vm.editTicketModal,"ticket":_vm.selectedTicket},on:{"update:active":function($event){_vm.editTicketModal=$event},"cancel":function($event){_vm.editTicketModal = false},"finish":function($event){_vm.editTicketModal = false
      _vm.refreshDetails()}}}),_c('change-installer-modal',{attrs:{"active":_vm.changeInstallerModal,"ticket":_vm.selectedTicket,"installers":_vm.installers,"address":_vm.address},on:{"update:active":function($event){_vm.changeInstallerModal=$event},"cancel":function($event){_vm.changeInstallerModal = false},"finish":function($event){_vm.changeInstallerModal = false
      _vm.refreshDetails()}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }