export function getStatusClass(status) {
  switch (status) {
    case 'approved':
      return 'tw-bg-emerald-400'
    case 'cancelled':
      return 'tw-bg-pink-600'
    case 'assigned':
      return 'tw-bg-violet-950'
    case 'created':
      return 'tw-bg-green-800'
    case 'planned':
      return 'tw-bg-blue-400'
    case 'ongoing':
      return 'tw-bg-amber-400'
    case 'finished':
      return 'tw-bg-sky-400'
    case 'rejected':
      return 'tw-bg-pink-600'
    default:
      return 'tw-bg-gray-400'
  }
}
