<template>
  <div class="installation-data">
    <span><b>Status:</b> {{ client.tasks.installation.status }}</span>
    <span v-if="installers.length > 0"> <b>Installer(s):</b> {{ installers.join() }} </span>
    <span class="pb-2" v-if="client.tasks.installation.appointment_date">
      <b>Appointment date:</b> {{ client.tasks.installation.appointment_date }}
    </span>
    <span v-for="product in products" :key="product.description" class="products">
      <span><b>Type:</b> {{ product.type }}</span>
      <span><b>Amount:</b> {{ product.amount }}</span>
      <span><b>Description:</b> {{ product.description }}</span>
      <span><b>Serial numbers:</b> {{ product.serialNumbers.join(', ') }}</span>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class InstallationData extends Vue {
  @Prop({}) client!: any

  private formatProducts(products: any) {
    const newProducts: Array<any> = []

    Object.entries(products).forEach(([type, value]: Array<any>) => {
      if (!value) return

      Object.entries(value).forEach((prod: Array<any>) => {
        const newProduct = {
          type,
          description: '',
          amount: 0,
          power: '',
          serialNumbers: [],
        }

        const { amount } = prod[1]
        const serialNumbers = prod[1].serial_numbers
        const productType = prod[1].description
        const power = prod[1].W || prod[1].wp

        if (productType) {
          newProduct.description = productType
        }
        if (amount) {
          newProduct.amount = amount
        }
        if (power) {
          newProduct.power = power
        }
        if (serialNumbers) {
          newProduct.serialNumbers = serialNumbers
        }

        newProducts.push(newProduct)
      })
    })

    return newProducts
  }

  get products() {
    let products: Array<any> = []

    if (!this.client.tasks.installation.tickets) return products

    Object.values(this.client.tasks.installation.tickets).forEach((ticket: any) => {
      if (!ticket.output || !ticket.output.products) return
      products = [...products, ...this.formatProducts(ticket.output.products)]
    })

    return products
  }

  get installers() {
    if (!this.client.tasks.installation.tickets) return []

    return [
      ...new Set(
        Object.values(this.client.tasks.installation.tickets).map((ticket: any) => ticket.partner)
      ),
    ]
  }
}
</script>

<style lang="scss" scoped>
.installation-data {
  display: flex;
  flex-direction: column;

  .products {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.6rem;

    & > * {
      b {
        padding-right: 0.3rem;
      }
    }
  }

  & > * {
    b {
      padding-right: 0.3rem;
    }
  }
}
</style>
