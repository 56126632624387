<template>
  <div class="ticket-images">
    <details-box-heading label="Images" />
    <div class="is-flex is-flex-wrap-wrap is-align-items-center">
      <details-image-field
        v-for="[field, component] in ticketImageFields"
        :key="`image-${field}-${component.value}`"
        class="mr-1 mb-1"
        :field="field"
        :component="component"
        @click="onClickImageField"
        @mouseenter="onHoverImageField"
      />
    </div>
    <details-image-preview
      v-if="hasImage(activeImage)"
      :field="activeImageField"
      :component="activeImageComponent"
      @click="onClickImageField"
    />
    <details-image-modal
      v-if="showImageModal"
      :key="`${ticketImages.length}-${activeImageIndex}`"
      :images="ticketImages"
      :initial-index="activeImageIndex"
      :close="onCloseImageModal"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import DetailsPaneBase from './DetailsPaneBase.vue'

interface ImageComponent {
  value: string | null
  name: string
  imageType: string
}

type ImageField = [string, ImageComponent]

@Component({
  name: 'DetailsTicketImages',
})
export default class DetailsTicketImages extends DetailsPaneBase {
  @Prop({ default: [] }) images!: Array<{ type: string; url: string }>

  protected activeImage: ImageField | null = null
  protected showImageModal = false

  get ticketImageFields() {
    return this.images.map((image) => [
      image.type,
      {
        value: image.url,
        name: image.type,
        imageType: 'ticket',
      },
    ]) as Array<ImageField>
  }

  get ticketImages() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return this.ticketImageFields.filter(([_, imageData]) => !!imageData.value)
  }

  get activeImageIndex() {
    if (!this.activeImage) return 0

    for (let idx = 0; idx < this.ticketImages.length; idx += 1) {
      if (this.ticketImages[idx][0] === this.activeImage[0]) {
        return idx
      }
    }

    return 0
  }

  get activeImageField() {
    return this.activeImage ? this.activeImage[0] : null
  }

  get activeImageComponent() {
    return this.activeImage ? this.activeImage[1] : null
  }

  protected hasImage(imageField: ImageField) {
    return imageField && !!imageField[1].value
  }

  protected onClickImageField([field, component]: ImageField) {
    this.activeImage = [field, component]
    if (component.value) {
      this.showImageModal = true
    }
  }

  protected onHoverImageField([field, component]: ImageField) {
    this.activeImage = [field, component]
  }

  protected onCloseImageModal() {
    this.showImageModal = false
  }

  created() {
    this.activeImage = this.ticketImages.length > 0 ? this.ticketImages[0] : null
  }
}
</script>

<style lang="scss">
.ticket-images {
  min-width: 24vh;
}
</style>
