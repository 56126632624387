<template>
  <div class="number-input is-relative my-1">
    <details-data-field-label :label="name" :required="required" />

    <span v-if="!enabled" class="text">{{ value }}</span>
    <input
      v-else
      class="input is-small"
      :class="classes"
      type="number"
      :disabled="!enabled"
      v-model="inputValue"
      :data-id="`detail-number-input?=${inputValue}`"
      @keydown.enter="onBlur"
      @blur="onBlur"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import { STATUS_OK } from '@/constants'
import DetailsDataComponentBase from './DetailsDataComponentBase.vue'

@Component({
  name: 'DetailsNumericInput',
})
export default class DetailsNumericInput extends DetailsDataComponentBase {
  @Prop({ default: true }) autoUpdate!: boolean

  protected inputValue = ''

  get numericValue() {
    return typeof this.inputValue === 'number' ? this.inputValue : parseInt(this.inputValue, 10)
  }

  protected async onBlur() {
    if (!this.autoUpdate) {
      this.$emit('change', this.numericValue)
      return
    }

    const response = await this.submitAction({ [this.field]: this.numericValue })
    if (response.status === STATUS_OK) {
      this.$emit('change', this.numericValue)
    }
  }

  created() {
    this.inputValue = typeof this.value === 'number' ? this.value.toString() : this.value || ''
  }
}
</script>

<style lang="scss" scoped>
.number-input {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    display: block;
    padding-left: 5px;
  }

  .text {
    font-size: 0.73rem;
    padding: 0.3em 0.6em;
    background-color: rgb(228, 228, 228);
    border-radius: 0.3em;
    min-height: 1.3rem;
  }

  input {
    height: calc(18px + 0.2vh);
    font-size: 0.73rem;
    width: 5em;
    padding: 0.3em;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:disabled {
      color: rgb(58, 58, 58);
    }

    &::placeholder {
      color: rgb(123, 123, 123);
    }
  }
}
</style>
