<template>
  <div class="is-flex is-align-items-center">
    <master-data-filter :manager="manager" type="maintenance" />
    <!-- INSTALLER FILTER -->
    <div class="is-flex is-align-items-center">
      <dropdown-slotted label="Installer" iconColor="#fff" min-width="0">
        <template v-slot:default>
          <div class="is-flex is-justify-content-center is-flex-wrap-wrap">
            <small-tag
              v-for="(item, idx) in installerList.items"
              :key="item"
              :label="item"
              :class="{ 'mb-1': idx !== installerList.items.length - 1 }"
              :color="selectedInstallers.includes(item) ? 'is-dark' : 'is-light'"
              @click="(event) => onInstallerSelectionChange(item, event)"
            />
          </div>
        </template>
      </dropdown-slotted>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { TASK_TYPE_MAINTENANCE } from '@/constants'
import { MultiSelectList } from '@/logic/common'
import type { TasksScreenManager } from '@/logic/TasksScreen'
import MasterDataFilter from './MasterDataFilter.vue'

@Component({
  name: 'TableHeadDataMaintenance',
  components: {
    MasterDataFilter,
  },
})
export default class TableHeadDataMaintenance extends Vue {
  @Prop() manager!: TasksScreenManager

  protected installerList = new MultiSelectList<string>([], {
    checkboxMode: true,
  })

  get filter() {
    return this.manager.list.filter!
  }

  get installerOptions() {
    const installerOptions = new Set()
    this.manager.list.getItems().forEach((item) => {
      const ticketString = (item.getTaskData(TASK_TYPE_MAINTENANCE).tickets || '') as string

      const tickets = ticketString.split(';')

      tickets.forEach((ticket) => {
        const installer = ticket.split(':')[0].trim()
        if (installer) {
          installerOptions.add(installer)
        }
      })
    })

    return Array.from(installerOptions) as Array<string>
  }

  get selectedInstallers() {
    return this.installerList.selectedItems
  }

  protected onInstallerSelectionChange(installer: string, event: MouseEvent) {
    this.installerList.onClickItem(installer, event)
    this.filter.setState({ maintenanceInstallers: this.installerList.selectedItems })
  }

  mounted() {
    this.installerList.setItems(this.installerOptions)
    this.installerList.setPreselectedItems(this.filter.getStateProp('maintenanceInstallers'))
  }
}
</script>
