<template>
  <div class="client-data">
    <span><b>Name:</b> {{ client.client.name }}</span>
    <span><b>Phone:</b> {{ client.client.phone }}</span>
    <span><b>Email:</b> {{ client.client.email }}</span>

    <p class="buttons">
      <button class="button thin-button is-info is-outlined mb-2" @click="openClientEditor">
        Edit contact details
      </button>
    </p>

    <span><b>City:</b> {{ client.address.city }}</span>
    <span><b>Street:</b> {{ client.address.street }}</span>
    <span><b>Postal code:</b> {{ client.address.postal_code }}</span>
    <span class="mb-2"><b>House number:</b> {{ client.address.house_number }}</span>

    <span v-if="client.woco"><b>Woco:</b> {{ client.woco }}</span>
    <span v-if="client.project"><b>Project:</b> {{ client.project }}</span>
    <span v-if="client.groups" class="mb-2"><b>Groups:</b> {{ client.groups.join(', ') }}</span>
    <span v-else>This address does not belong to any groups yet.</span>

    <template v-if="showClientEditor">
      <modal :close="closeClientEditor">
        <client-editor :client="editableClient" :woco="client.woco" />
      </modal>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import request from '@/requests'

import { STATUS_OK } from '@/constants'
import { ClientEditor } from './ClientEditor'

interface Address {
  street: string
  house_number: string
  postal_code: string
}

@Component({
  name: 'CallCentreClientData',
  components: { ClientEditor },
})
export default class CallCentreClientData extends Vue {
  @Prop({}) client!: { address: Address; fullAddress: string }

  protected editableClient: null | object = null
  protected showClientEditor = false

  protected get address() {
    return this.client.fullAddress
  }

  protected openClientEditor() {
    setTimeout(() => {
      // Prevents the first click to be registered for closing the modal (outer click)
      this.showClientEditor = true
    }, 0)
  }

  protected closeClientEditor() {
    this.showClientEditor = false
  }

  mounted() {
    request.clients.getClientDetailsForEdit(this.client.fullAddress).then((response) => {
      if (response.status === STATUS_OK) {
        this.editableClient = response.data
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.client-data {
  display: flex;
  flex-direction: column;

  & > * {
    b {
      padding-right: 0.3rem;
    }
  }
}
</style>
