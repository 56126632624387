<template>
  <h6 class="data-field-label mr-1">
    <b v-if="required">{{ label }}</b>
    <template v-else>{{ label }}</template>
  </h6>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'DetailsDataFieldLabel',
})
export default class DetailsDataFieldLabel extends Vue {
  @Prop({ default: 'Label' }) label!: string
  @Prop({ default: false }) required!: boolean
}
</script>

<style lang="scss" scoped>
.data-field-label {
  font-size: 0.73rem;
  white-space: nowrap;
}
</style>
