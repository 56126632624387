<template>
  <div class="text-input is-relative my-1">
    <details-data-field-label v-if="!usePlaceholder" :label="name" :required="required" />

    <span v-if="!enabled" class="text">{{ value }}</span>
    <input
      v-else
      class="input is-small"
      :class="classes"
      type="text"
      :disabled="!enabled"
      v-model="inputValue"
      :placeholder="usePlaceholder && name"
      :data-id="`detail-text-input?=${inputValue}`"
      @keydown.enter="onBlur"
      @blur="onBlur"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import { STATUS_OK } from '@/constants'
import DetailsDataComponentBase from './DetailsDataComponentBase.vue'

@Component({
  name: 'DetailsTextInput',
})
export default class DetailsTextInput extends DetailsDataComponentBase {
  @Prop({ default: true }) autoUpdate!: boolean
  @Prop({ default: false }) usePlaceholder!: boolean

  protected inputValue = ''

  protected async onBlur() {
    if (!this.autoUpdate) {
      this.$emit('change', this.inputValue)
      return
    }

    const response = await this.submitAction({ [this.field]: this.inputValue })
    if (response.status === STATUS_OK) {
      this.$emit('change', this.inputValue)
    }
  }

  created() {
    this.inputValue = (this.value as string) || ''
  }
}
</script>

<style lang="scss" scoped>
.text-input {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    display: block;
    padding-left: 5px;
  }

  .text {
    font-size: 0.73rem;
    padding: 0.3em 0.6em;
    background-color: rgb(228, 228, 228);
    border-radius: 0.3em;
    min-height: 1.3rem;
  }

  input {
    height: calc(18px + 0.2vh);
    font-size: 0.73rem;
    // width: 5em;
    padding: 0.3em;

    &:disabled {
      color: rgb(58, 58, 58);
    }

    &::placeholder {
      color: rgb(123, 123, 123);
    }
  }
}
</style>
