<template>
  <table class="table dashboard-table is-bordered is-hoverable">
    <thead>
      <tr>
        <th
          class="dashboard-table-header dashboard-totals-header px-2"
          style="vertical-align: bottom"
        >
          <div class="is-flex has-text-right">
            <span style="flex: 1">For.</span>
            <span style="flex: 1">Act.</span>
            <span style="flex: 2">Cat.</span>
          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="type in Object.keys(statistics.total)"
        :key="`status-totals-row-${type}`"
        @click="$emit('click', $event, type)"
      >
        <!-- TASK TYPE TOTALS -->
        <td>
          <div class="is-flex has-text-right">
            <!-- FORECAST -->
            <div class="mx-1" style="flex: 1">
              <span class="fixed-height" style="align-self: flex-start">
                {{ statistics.total[type][defaultStatus].forecast }}
              </span>
            </div>
            <!-- ACTUALS -->
            <div class="mx-1" style="flex: 1">
              <div class="is-flex is-flex-direction-column">
                <span class="fixed-height">
                  {{ statistics.total[type][defaultStatus].actuals }}
                </span>
                <template v-if="isExpanded(type)">
                  <span
                    class="fixed-height"
                    v-for="[status, { actuals }] in Object.entries(statistics.total[type])"
                    :key="`total-${status}-${actuals}`"
                  >
                    {{ actuals }}
                  </span>
                </template>
              </div>
            </div>
            <!-- TYPE / STATUS -->
            <div class="mx-1" style="flex: 2">
              <div class="is-flex is-flex-direction-column">
                <b class="fixed-height">{{ type }}</b>
                <template v-if="isExpanded(type)">
                  <i
                    class="fixed-height"
                    v-for="status in Object.keys(statistics.total[type])"
                    :key="`${type}-${status}`"
                  >
                    {{ status }}
                  </i>
                </template>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import type { DashboardScreenManager } from '@/logic/DashboardScreen'

@Component({
  name: 'DashboardTableStatusTotals',
})
export default class DashboardTableStatusTotals extends Vue {
  @Prop({}) manager!: DashboardScreenManager
  @Prop({ default: '' }) expandedRow!: string

  protected defaultStatus = 'approved'

  get statistics() {
    return this.manager.statistics
  }

  protected isExpanded(type: string) {
    return type === this.expandedRow
  }
}
</script>
