<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import BaseButtonBulk from './BaseButtonBulk.vue'

@Component({
  name: 'BaseButtonBulk',
})
export default class ApproveButtonNulk extends BaseButtonBulk {
  // protected label = 'Send';
  protected color = 'is-assign'

  get disabled() {
    return !this.actionList.every(
      (storedAction) => !!storedAction.item.actionData.assignedInstaller
    )
  }

  protected onClick() {
    if (this.disabled) return

    this.submitActions(this.actionList)
  }
}
</script>
