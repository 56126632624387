<template>
  <div
    class="dropdown"
    :class="!controller.isCollapsed && 'is-active'"
    v-outer-click="controller.close"
  >
    <div class="dropdown-trigger">
      <button
        class="button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        @click="controller.toggleCollapse"
      >
        <span :data-id="dataId">{{ label }}</span>
        <span class="icon">
          <i
            class="fas fa-angle-down"
            aria-hidden="true"
            style="color: #3273dc; font-size: 1.125em"
          ></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content" style="border: 1px solid #ccc">
        <a
          v-for="item in list.items"
          :key="`item-${display ? controller.getDisplayPropertyValue(item, display) : item}`"
          class="dropdown-item is-unselectable"
          :class="list.isSelected(item) && 'is-active'"
          @click="(event) => controller.onClickItem(item, event)"
        >
          {{ display ? controller.getDisplayPropertyValue(item, display) : item }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { MultiSelectDropdownController } from '@/logic/common'
import { isDeepEqual } from '@/util'

interface Dictionary<T> {
  [key: string]: T
}

@Component({ name: 'MultipleSelectDropdown' })
export default class MultipleSelectDropdown extends Vue {
  @Prop({ default: () => [] }) items!: Array<unknown>
  @Prop({ default: '' }) display!: string
  @Prop({ default: () => [] }) preselect!: Array<unknown>
  @Prop({ default: '' }) id!: string
  @Prop({}) dataId!: string

  private controller = new MultiSelectDropdownController(this.items, this.preselect)

  get list() {
    return this.controller.list
  }

  get label() {
    return this.list.selectedItems.length
      ? this.preselect
          .map((item) => (this.display ? (item as Dictionary<string>)[this.display] : item))
          .join(', ')
      : 'Maak een selectie'
  }

  get listItems() {
    return this.list.selectedItems
  }

  @Watch('list', { deep: true })
  onListChange() {
    this.$emit('change', this.list.selectedItems)
  }

  @Watch('preselect')
  onPreselectedChange(newValue: unknown, oldValue: unknown) {
    if (!isDeepEqual(newValue, oldValue)) {
      this.controller.setPreselectedItems(this.preselect)
    }
  }
}
</script>
