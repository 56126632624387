<template>
  <div class="detail-content">
    <!-- DESIGN INFO -->
    <div v-if="shouldRenderComponentBox(components.design)" class="is-flex is-flex-wrap-wrap">
      <div class="box design-info">
        <details-box-heading label="Design information" />
        <div class="box-content">
          <p v-for="{ field, value } in designInfo" :key="`field-${field}`">
            <b>{{ field }}:&nbsp;</b>
            <span>{{ value }}</span>
          </p>
        </div>

        <details-image-preview
          v-if="components.design.panel_configuration"
          field="Panel configuration"
          :component="{ value: components.design.panel_configuration }"
          @click="() => (showImageModal = true)"
        />

        <details-image-modal
          v-if="showImageModal"
          :images="designImages"
          :close="() => (showImageModal = false)"
        />
      </div>
    </div>

    <!-- SIGNREQUEST -->
    <div v-if="shouldRenderComponentBox(components.signrequest)" class="is-flex is-flex-wrap-wrap">
      <details-sales-signrequest-box :manager="manager" />
    </div>

    <!-- LETTER -->
    <div v-if="shouldRenderComponentBox(components.letter)" class="is-flex is-flex-wrap-wrap">
      <details-sales-letter-box :manager="manager" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import DetailsPaneBase from './DetailsPaneBase.vue'
import DetailsSalesSignrequestBox from './DetailsSalesSignrequestBox.vue'
import DetailsSalesLetterBox from './DetailsSalesLetterBox.vue'

@Component({
  name: 'DetailsPaneSales',
  components: {
    DetailsSalesSignrequestBox,
    DetailsSalesLetterBox,
  },
})
export default class DetailsPaneSales extends DetailsPaneBase {
  protected showImageModal = false

  get designInfo() {
    const design = this.components.design as { [prop: string]: string }
    if (!design) return {}

    return Object.entries(design).reduce(
      (acc, [field, value]) =>
        typeof value === 'string' && value.includes('http')
          ? [...acc]
          : [
              ...acc,
              {
                field: this.formatField(field),
                value,
              },
            ],
      [] as Array<{ field: string; value: string }>
    )
  }

  get designImages() {
    const imageFields = ['panel_configuration']
    const design = this.components.design as { [prop: string]: string }
    if (!design) return []

    const images: Array<[string, { name: string; value: string | number }]> = []

    Object.entries(design).forEach(([field, value]) => {
      if (imageFields.includes(field)) {
        images.push([field, { name: this.formatField(field), value }])
      }
    })

    return images
  }

  protected formatField(field: string) {
    return (field[0].toUpperCase() + field.slice(1)).replaceAll('_', ' ')
  }
}
</script>
