import {
  MultiSelectList,
  BaseFilter,
  filterAddressWithTokens,
  filterOnDate,
  sortByDate,
  sortAddress,
} from '@/logic/common'

import { composeSort, invertSort, escapeRegExpChars } from '@/util'

import {
  TASK_TYPE_DESIGN,
  TASK_TYPE_INSTALLATION,
  TASK_TYPE_SALES,
  TASK_TYPE_FINANCE,
  TASK_TYPE_INSPECTION,
  TASK_TYPE_MAINTENANCE,
} from '@/constants'

import type {
  TaskTypeKey,
  AddressTokenKey,
  StringAddressTokens,
  RegExpAddressTokens,
} from '@/interfaces'

import type MasterItem from './MasterItem'

interface DateStartEnd {
  start: string
  end: string
}

interface TaskStatusState {
  designStatus: Array<string>
  designStatusAt: DateStartEnd
  installationStatus: Array<string>
  installationStatusAt: DateStartEnd
  salesStatus: Array<string>
  salesStatusAt: DateStartEnd
  financeStatus: Array<string>
  financeStatusAt: DateStartEnd
  inspectionStatus: Array<string>
  inspectionStatusAt: DateStartEnd
  maintenanceStatus: Array<string>
  maintenanceStatusAt: DateStartEnd
  salesSignedAt: DateStartEnd
  installationAppointmentAt: DateStartEnd
}

interface TasksFilterState extends TaskStatusState {
  designData: {
    [prop: string]: {
      values: Array<string>
      inverted: boolean
    }
  }
  installationTickets: string
  inspectionTickets: string
  installationInstallers: Array<string>
  inspectionInstallers: Array<string>
  maintenanceInstallers: Array<string>
  tokens: {
    street: RegExp | null
    houseNumber: RegExp | null
    postalCode: RegExp | null
  }
  searchValue: string
  [prop: string]: unknown
}

export default class MasterItemList {
  static createList = () =>
    new MultiSelectList([] as Array<MasterItem>, {
      name: 'masteritem_list',
      autoSort: true,
      autoRemoveDuplicates: true,
      checkboxMode: true,
      filter: MasterItemList.createFilter(),
      sorters: MasterItemList.createSorters(),
    })

  static createTaskStatusFilterState = () =>
    [
      TASK_TYPE_DESIGN,
      TASK_TYPE_INSTALLATION,
      TASK_TYPE_SALES,
      TASK_TYPE_FINANCE,
      TASK_TYPE_INSPECTION,
      TASK_TYPE_MAINTENANCE,
    ].reduce(
      (acc, type) => ({
        ...acc,
        [`${type}Status`]: [],
        [`${type}StatusAt`]: {
          start: '',
          end: '',
        },
      }),
      {}
    ) as TaskStatusState

  static createFilterState = () =>
    ({
      ...MasterItemList.createTaskStatusFilterState(),
      salesSignedAt: {
        start: '',
        end: '',
      },
      installationAppointmentAt: {
        start: '',
        end: '',
      },
      installationTickets: '',
      inspectionTickets: '',
      installationInstallers: [],
      inspectionInstallers: [],
      maintenanceInstallers: [],
      designData: {},
      searchValue: '',
      tokens: {
        street: null,
        houseNumber: null,
        postalCode: null,
      },
    }) as TasksFilterState

  static createTaskStatusFilters = () =>
    [
      TASK_TYPE_DESIGN,
      TASK_TYPE_INSTALLATION,
      TASK_TYPE_SALES,
      TASK_TYPE_FINANCE,
      TASK_TYPE_INSPECTION,
      TASK_TYPE_MAINTENANCE,
    ].reduce(
      (acc, type) => ({
        ...acc,
        [`${type}Status`]: MasterItemList.filterTaskStatus(type as TaskTypeKey),
        [`${type}StatusAt`]: MasterItemList.filterTaskStatusAt(type as TaskTypeKey),
      }),
      {}
    )

  static createFilter = () =>
    new BaseFilter({
      state: MasterItemList.createFilterState(),
      filters: {
        ...MasterItemList.createTaskStatusFilters(),
        salesSignedAt: MasterItemList.filterSalesSignedAt,
        installationAppointmentAt: MasterItemList.filterInstallationAppointmentAt,
        installationTickets: MasterItemList.filterTaskTickets('installation'),
        inspectionTickets: MasterItemList.filterTaskTickets('inspection'),
        installationInstallers: MasterItemList.filterTicketInstaller('installation'),
        inspectionInstallers: MasterItemList.filterTicketInstaller('inspection'),
        maintenanceInstallers: MasterItemList.filterTicketInstaller('maintenance'),
        designData: MasterItemList.filterDesignData,
        address: filterAddressWithTokens,
      },
      handlers: {
        search: MasterItemList.addressSearchHandler,
      },
    })

  static createSorters = () => ({
    // Address
    sortAddressAscending: composeSort(sortAddress),
    sortAddressDescending: composeSort(invertSort(sortAddress)),
    // Task status
    ...MasterItemList.createTaskStatusSorters(),
  })

  static createTaskStatusSorters = () => {
    const sorters: { [sorter: string]: Function } = {}
    const taskTypes = [
      TASK_TYPE_DESIGN,
      TASK_TYPE_FINANCE,
      TASK_TYPE_INSTALLATION,
      TASK_TYPE_SALES,
      TASK_TYPE_INSPECTION,
      TASK_TYPE_MAINTENANCE,
    ] as Array<TaskTypeKey>

    taskTypes.forEach((taskType) => {
      const titleCase = taskType[0].toUpperCase() + taskType.slice(1)

      sorters[`sort${titleCase}StatusTypeAscending`] = composeSort(
        MasterItemList.sortTaskStatus(taskType),
        MasterItemList.sortTaskStatusAt(taskType)
      )
      sorters[`sort${titleCase}StatusTypeDescending`] = composeSort(
        invertSort(MasterItemList.sortTaskStatus(taskType)),
        MasterItemList.sortTaskStatusAt(taskType)
      )

      sorters[`sort${titleCase}StatusAtAscending`] = composeSort(
        MasterItemList.sortTaskStatusAt(taskType),
        MasterItemList.sortTaskStatus(taskType)
      )
      sorters[`sort${titleCase}StatusAtDescending`] = composeSort(
        invertSort(MasterItemList.sortTaskStatusAt(taskType)),
        MasterItemList.sortTaskStatus(taskType)
      )
    })

    return sorters
  }

  // FILTERS
  static filterTaskStatus =
    (taskType: TaskTypeKey) => (item: MasterItem, state: TasksFilterState) => {
      const stateValue = state[`${taskType}Status`] as Array<string>
      if (stateValue === undefined) {
        console.warn(`Failed to get filter state value [${taskType}Status]`)
        return true
      }

      if (stateValue.length < 1) return true

      const taskStatus = item.getStatus(taskType)
      if (!taskStatus) return stateValue.includes('no status')

      return stateValue.includes(taskStatus!)
    }

  static filterTicketInstaller =
    (taskType: TaskTypeKey) => (item: MasterItem, state: TasksFilterState) => {
      const stateValue = state[`${taskType}Installers`] as Array<string>
      if (stateValue.length === 0) return true

      const tickets = (item.getTaskData(taskType).tickets || '') as string
      return stateValue.some((installer) => !!tickets.match(installer))
    }

  static filterTaskStatusAt =
    (taskType: TaskTypeKey) => (item: MasterItem, state: TasksFilterState) => {
      const stateValue = state[`${taskType}StatusAt`] as { start: string; end: string }
      if (!stateValue) {
        console.warn(`Failed to get state value for [${taskType}StatusAt]`)
        return true
      }

      return filterOnDate(item.getStatusAt(taskType), stateValue)
    }

  static filterDesignData = (item: MasterItem, state: TasksFilterState) => {
    const taskData = item.getTaskData(TASK_TYPE_DESIGN)
    const productFields = ['inverters', 'panels', 'smartmeters']
    const productQuantities = ['panels_quantity']

    return Object.entries(state.designData).every(([filterField, { inverted, values }]) => {
      if (!Array.isArray(values) || values.length === 0) return true
      if (taskData[filterField] === undefined && taskData[filterField.split('_')[0]] === undefined)
        return inverted

      let result = true
      if (productFields.includes(filterField)) {
        result = values.some((value) =>
          Object.keys(taskData[filterField] as object).includes(value)
        )
      } else if (productQuantities.includes(filterField)) {
        result = values.some((value) =>
          Object.values(taskData[filterField.split('_')[0]] as object).includes(value)
        )
      } else {
        result = values.some((value) => value === taskData[filterField])
      }

      return inverted ? !result : result
    })
  }

  static filterInstallationAppointmentAt = (item: MasterItem, state: TasksFilterState) => {
    const { start, end } = state.installationAppointmentAt
    const installationData = item.getTaskData(TASK_TYPE_INSTALLATION) || {}
    const appointmentAt = installationData.appointment_date as string | undefined

    return filterOnDate(appointmentAt, { start, end })
  }

  static filterTaskTickets =
    (taskType: 'installation' | 'inspection') => (item: MasterItem, state: TasksFilterState) => {
      const filterValue = state[`${taskType}Tickets`]
      if (!filterValue) return true

      const taskData = item.getTaskExtraData(taskType)
      if (!taskData) return false

      const tickets = (taskData.tickets as Array<unknown>) || []
      const hasTickets = tickets && tickets.length > 0

      if (filterValue === 'none' && !hasTickets) return true
      if (filterValue === 'single' && hasTickets && tickets.length === 1) return true
      if (filterValue === 'multiple' && hasTickets && tickets.length > 1) return true

      return false
    }

  static filterSalesSignedAt = (item: MasterItem, state: TasksFilterState) => {
    const { start, end } = state.salesSignedAt

    const salesData = item.getTaskData(TASK_TYPE_SALES) || {}

    let signedStatusAt: string | undefined
    const dataEntries = Object.entries(salesData)
    for (let idx = 0; idx < dataEntries.length; idx += 1) {
      const [key, value] = dataEntries[idx]
      if (key.includes('signed_at')) {
        signedStatusAt = value as string
        break
      }
    }

    return filterOnDate(signedStatusAt, state.salesSignedAt)
  }

  // FILTER HANDLERS
  static addressSearchHandler = (event: InputEvent | string, filter: BaseFilter) => {
    // TODO: make it reusable (copied from EditScreen)
    let input = ''
    if (typeof event === 'string') {
      input = event
    } else {
      const target = event.target as HTMLInputElement
      input = target.value
    }

    let stringTokens = {
      street: null,
      houseNumber: null,
      postalCode: null,
    } as StringAddressTokens

    let regexp = new RegExp(
      [
        // get ^<street> <house_no>? <postal_code>?
        '^(?<street>\\d{0,1}\\s*[a-z.()\\/\\-]+(\\s*[a-z.()\\/\\-]+)*){1}',
        // eslint-disable-next-line max-len
        '\\s*(?<houseNumber>\\d+(\\s*-?[a-z0-9]{1,3})?(?=\\s+\\d{4})|\\d+(\\s*-?[a-z0-9]{1,3})?){0,1}',
        '\\s*(?<postalCode>\\d{4}(\\s*[a-z]{1,2})?){0,1}',
      ].join(''),
      'i'
    )
    let match = input.match(regexp)

    if (!match) {
      regexp = new RegExp(
        [
          // get ^<postal_code> <house_no>?
          '^(?<postalCode>(\\d{4}(\\s*[a-z]{1,2}){0,1}|\\d{1,4})){1}',
          '\\s*(?<houseNumber>\\d+(\\s*-?[a-z0-9]{1,3})?){0,1}',
        ].join(''),
        'i'
      )
      match = input.match(regexp)
    }

    stringTokens = match ? { ...stringTokens, ...match.groups } : stringTokens
    const regexpTokens = Object.entries(stringTokens).reduce((acc, [key, value]) => {
      if (key === 'postalCode' && value && value.length > 4) {
        const v = value.replace(/\s/g, '')
        acc[key] = new RegExp(`^${v.slice(0, 4)}\\s*${v.slice(4)}`, 'i')
      } else if (key === 'houseNumber' && value) {
        acc[key] = new RegExp(`^${value.replace(/[\s-]/g, '(\\s{1}|-{1})')}`, 'i')
      } else if (key === 'street' && value) {
        acc[key] = new RegExp(`${escapeRegExpChars(value)}`, 'i')
      } else {
        acc[key as AddressTokenKey] = value
          ? new RegExp(`^${escapeRegExpChars(value)}`, 'i')
          : (value as null)
      }
      return acc
    }, {} as RegExpAddressTokens)

    filter.setState({ tokens: regexpTokens, searchValue: input })
  }

  // SORTERS
  static sortTaskStatus = (taskType: TaskTypeKey) => (a: MasterItem, b: MasterItem) => {
    const statusA = a.getStatus(taskType)
    const statusB = b.getStatus(taskType)
    if (!statusA && !statusB) return 0

    if (!statusA || !statusB) return statusA ? -1 : 1

    return statusA?.localeCompare(statusB)
  }

  static sortTaskStatusAt = (taskType: TaskTypeKey) => (a: MasterItem, b: MasterItem) => {
    const dateA = a.getStatusAt(taskType)
    const dateB = b.getStatusAt(taskType)

    if (!dateA || !dateB) return 0

    return sortByDate(new Date(dateA), new Date(dateB))
  }
}
