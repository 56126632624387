/* eslint-disable import/prefer-default-export */
import { actions } from '@/store'
import { composeSort, invertSort, notifications, escapeRegExpChars, print } from '@/util'

import {
  BaseScreenManager,
  MultiSelectListController,
  MultiSelectList,
  BaseFilter,
  GroupEditor,
  sortStreet,
  sortHouseNumber,
  sortPostalCode,
  filterNoGroup,
  filterAddressWithTokens,
  filterUnavailable,
} from '@/logic/common'

import { hasPermission, actionPermissions } from '@/logic/common/Permissions'

import { STATUS_FORBIDDEN, STATUS_OK } from '@/constants'

import type {
  Address,
  AddressMap,
  RegExpAddressTokens,
  StringAddressTokens,
  ExistingGroup,
  AddressTokenKey,
} from '@/interfaces'

import request from '@/requests'
import VueRouter from 'vue-router'
import SelectableAddress from './SelectableAddress'

// type AddressTokenKey = 'street' | 'houseNumber' | 'postalCode';

export default class GroupEditScreenManager extends BaseScreenManager {
  public isLoading = true
  public editor = new GroupEditor(this.router)
  public group: ExistingGroup | null = null
  public listController = new MultiSelectListController<SelectableAddress>()

  constructor(router: VueRouter) {
    super(router)
    const group = this.router.currentRoute.params.name
    this.init(group)
  }

  protected init = async (group: string) => {
    this.group = await this.fetchGroup(group)
    if (!this.group) {
      this.isLoading = false
      return
    }

    if (!this.userCanEditGroup()) {
      actions.common.commitStatus(STATUS_FORBIDDEN)
      return
    }

    this.editor.setGroupInfo(this.group)
    this.editor.getProjects()
    const { assigned, unassigned } = await this.createAddressLists()
    this.initializeListController([assigned, unassigned])
    if (actions.common.readApps().length === 0) {
      await this.fetchApps()
    }
    this.isLoading = false
  }

  protected userCanEditGroup() {
    const user = actions.auth.readUser()
    return !(
      this.group!.is_private &&
      this.group!.created_by !== user &&
      !hasPermission(actionPermissions.editPrivateGroup, true)
    )
  }

  protected initializeListController = (lists: MultiSelectList<SelectableAddress>[]) => {
    const [assigned, unassigned] = lists
    this.listController.setConfig({
      assigned: {
        instance: assigned,
      },
      unassigned: {
        instance: unassigned,
      },
    })
  }

  protected sortStreetAscending = composeSort(sortStreet, sortHouseNumber, sortPostalCode)

  protected sortStreetDescending = composeSort(
    invertSort(sortStreet),
    sortHouseNumber,
    sortPostalCode
  )

  protected addressSearchHandler(event: InputEvent, filter: BaseFilter) {
    const target = event.target as HTMLInputElement
    const input = target.value
    let stringTokens = {
      street: null,
      houseNumber: null,
      postalCode: null,
    } as StringAddressTokens

    let regexp = new RegExp(
      [
        // get ^<street> <house_no>? <postal_code>?
        '^(?<street>\\d{0,1}\\s*[a-z.()\\/\\-]+(\\s*[a-z.()\\/\\-]+)*){1}',
        // eslint-disable-next-line max-len
        '\\s*(?<houseNumber>\\d+(\\s*-?[a-z0-9]{1,3})?(?=\\s+\\d{4})|\\d+(\\s*-?[a-z0-9]{1,3})?){0,1}',
        '\\s*(?<postalCode>\\d{4}(\\s*[a-z]{1,2})?){0,1}',
      ].join(''),
      'i'
    )
    let match = input.match(regexp)

    if (!match) {
      regexp = new RegExp(
        [
          // get ^<postal_code> <house_no>?
          '^(?<postalCode>(\\d{4}(\\s*[a-z]{1,2}){0,1}|\\d{1,4})){1}',
          '\\s*(?<houseNumber>\\d+(\\s*-?[a-z0-9]{1,3})?){0,1}',
        ].join(''),
        'i'
      )
      match = input.match(regexp)
    }

    stringTokens = match ? { ...stringTokens, ...match.groups } : stringTokens
    const regexpTokens = Object.entries(stringTokens).reduce((acc, [key, value]) => {
      if (key === 'postalCode' && value && value.length > 4) {
        const v = value.replace(/\s/g, '')
        acc[key] = new RegExp(`^${v.slice(0, 4)}\\s*${v.slice(4)}`, 'i')
      } else if (key === 'houseNumber' && value) {
        acc[key] = new RegExp(`^${value.replace(/[\s-]/g, '(\\s{1}|-{1})')}`, 'i')
      } else if (key === 'street' && value) {
        acc[key] = new RegExp(`${escapeRegExpChars(value)}`, 'i')
      } else {
        acc[key as AddressTokenKey] = value
          ? new RegExp(`^${escapeRegExpChars(value)}`, 'i')
          : (value as null)
      }
      return acc
    }, {} as RegExpAddressTokens)

    filter.setState({ tokens: regexpTokens, searchValue: target.value })
  }

  protected createAddressLists = async () => {
    const [existingAddresses, assignedAddresses, addressesWithGroup, unavailableAddresses] =
      await this.fetchAddresses()

    const unassignedAddresses = this.filterOutAssignedAddresses(
      existingAddresses,
      assignedAddresses
    )

    const addressesWithGroupMap = new Map()
    const unavailableAddressesToProject = new Map()

    addressesWithGroup.forEach((a) => addressesWithGroupMap.set(a.address, true))
    unavailableAddresses.forEach((a) => !unavailableAddressesToProject.set(a.address, true))

    const assigned = assignedAddresses.map(
      (address) =>
        new SelectableAddress(
          address,
          addressesWithGroupMap.has(address.address),
          !unavailableAddressesToProject.has(address.address)
        )
    )
    const unassigned = unassignedAddresses.map(
      (address) =>
        new SelectableAddress(
          address,
          addressesWithGroupMap.has(address.address),
          !unavailableAddressesToProject.has(address.address)
        )
    )

    const filterConfig = {
      state: {
        searchValue: '',
        tokens: {
          street: null,
          houseNumber: null,
          postalCode: null,
        },
      },
      filters: {
        address: filterAddressWithTokens,
        noGroup: {
          method: filterNoGroup,
          enabled: false,
        },
        unavailable: {
          method: filterUnavailable,
          enabled: false,
        },
      },
      handlers: { search: this.addressSearchHandler },
    }

    const listConfig = {
      autoSort: true,
      autoRemoveDuplicates: true,
      sorters: {
        streetAscending: this.sortStreetAscending,
        streetDescending: this.sortStreetDescending,
      },
    }

    const createList = (addresses: Array<SelectableAddress>, listName: string) =>
      new MultiSelectList<SelectableAddress>(addresses, {
        ...listConfig,
        name: listName,
        filter: new BaseFilter({ ...filterConfig, state: { ...filterConfig.state } }),
      })

    return {
      assigned: createList(assigned, 'Assigned'),
      unassigned: createList(unassigned, 'Unassigned'),
    }
  }

  protected filterOutAssignedAddresses = (
    existingAddresses: Array<Address>,
    assignedAddresses: Array<Address>
  ) => {
    const addressMap: AddressMap = assignedAddresses.reduce(
      (map, address) => ({ ...map, [address.address]: address }),
      {} as AddressMap
    )

    const filteredAddresses = existingAddresses.filter(
      ({ address }) => addressMap[address] === undefined
    )

    return filteredAddresses
  }

  protected fetchGroup = async (groupName: string) => {
    // const username = actions.auth.readUser();
    const response = await request.groups.getGroupByName(groupName)
    if (response.status === STATUS_OK) {
      return response.data
    }

    notifications.addNotificationFromResponse(response)
    console.warn('Failed to fetch group')
    return null
  }

  protected fetchExistingAddresses = async (): Promise<Address[]> => {
    const { woco } = this.group!
    const response = await request.groups.getAssignedAddressesForWoco(woco)
    if (response.status === STATUS_OK) {
      return response.data
    }

    notifications.addNotificationFromResponse(response)
    console.warn('Failed to fetch addresses')
    return []
  }

  protected fetchClientServiceAddresses = async () => {
    const { woco } = this.group!
    const response = await request.groups.getClientServiceAddressesForWoco(woco)
    if (response.status === STATUS_OK) {
      return response.data
    }

    notifications.addNotificationFromResponse(response)
    console.warn('Failed to fetch addresses from clients service')
    return []
  }

  protected fetchUnavailableAddresses = async () => {
    const { woco, project } = this.group!
    const response = await request.groups.getUnavailableAddressesForProject(woco, project!)
    if (response.status === STATUS_OK) {
      return response.data
    }

    notifications.addNotificationFromResponse(response)
    console.warn('Failed to fetch addresses from clients service')
    return []
  }

  protected fetchAddresses = async (): Promise<[Address[], Address[], Address[], Address[]]> => {
    const [existingAddresses, addressesWithGroup, unavailableAddresses] = await Promise.all([
      this.fetchClientServiceAddresses(),
      this.fetchExistingAddresses(),
      this.fetchUnavailableAddresses(),
    ])
    const assignedAddresses = this.group!.addresses
    return [existingAddresses, assignedAddresses!, addressesWithGroup, unavailableAddresses]
  }

  protected fetchApps = async () => {
    const response = await request.groups.getAvailableApps()
    if (response.status === STATUS_OK) {
      actions.common.commitApps(response.data)
    } else {
      notifications.addNotificationFromResponse(response)
    }
    return response
  }
}
