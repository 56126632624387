<template>
  <table-task :config="tableConfig" :manager="manager" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import type { TasksScreenManager } from '@/logic/TasksScreen'

import { TASK_TYPE_INSTALLATION } from '@/constants'

import TableTask from './TableTask.vue'
// import TableHeadActionsBase from './TableHeadActionsBase.vue';
import TableHeadStatusBase from './TableHeadStatusBase.vue'
import TableHeadDataInstallation from './TableHeadDataInstallation.vue'
import TableHeadActionsInstallation from './TableHeadActionsInstallation.vue'

import TableRowBase from './TableRowBase.vue'

@Component({
  name: 'InstallationTable',
  components: {
    TableTask,
  },
})
export default class InstallationTable extends Vue {
  @Prop() manager!: TasksScreenManager

  protected tableConfig = {
    headers: [
      {
        component: TableHeadStatusBase,
        props: {
          manager: this.manager,
          type: TASK_TYPE_INSTALLATION,
        },
        width: '20%',
      },
      {
        component: TableHeadDataInstallation,
        props: {
          manager: this.manager,
        },
      },
      {
        component: TableHeadActionsInstallation,
        props: {
          manager: this.manager,
          type: TASK_TYPE_INSTALLATION,
        },
        width: '25%',
      },
    ],
    row: {
      component: TableRowBase,
      props: {
        manager: this.manager,
        type: TASK_TYPE_INSTALLATION,
      },
    },
  }
}
</script>
