<template>
  <tr
    class="table-row"
    :class="[selected ? 'selected' : '']"
    :style="color && `background-color: ${color} !important`"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </tr>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'TableRow' })
export default class TableRow extends Vue {
  @Prop({ default: false }) selected!: boolean

  get color() {
    return ''
  }
}
</script>

<style lang="scss">
.table-row {
  &.selected {
    background-color: #dce5e8 !important;
  }
}
</style>
