<template>
  <table-task :config="tableConfig" :manager="manager" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import type { TasksScreenManager } from '@/logic/TasksScreen'

import { TASK_TYPE_DESIGN } from '@/constants'

import TableTask from './TableTask.vue'
import TableHeadActionsDesign from './TableHeadActionsDesign.vue'
import TableHeadStatusBase from './TableHeadStatusBase.vue'
import TableHeadDataDesign from './TableHeadDataDesign.vue'
import TableRowBase from './TableRowBase.vue'

@Component({
  name: 'DesignTable',
  components: {
    TableTask,
  },
})
export default class DesignTable extends Vue {
  @Prop() manager!: TasksScreenManager

  protected tableConfig = {
    headers: [
      {
        component: TableHeadStatusBase,
        props: {
          manager: this.manager,
          type: TASK_TYPE_DESIGN,
        },
        width: '20%',
      },
      {
        component: TableHeadDataDesign,
        props: {
          manager: this.manager,
        },
      },
      {
        component: TableHeadActionsDesign,
        props: {
          manager: this.manager,
          type: TASK_TYPE_DESIGN,
        },
        width: '25%',
      },
    ],
    row: {
      component: TableRowBase,
      props: {
        manager: this.manager,
        type: TASK_TYPE_DESIGN,
      },
    },
  }
}
</script>
