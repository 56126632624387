import { composeFilter } from '@/util'
import { FilterState, Filters, BaseFilterConfig } from '@/interfaces/filters'

export default class BaseFilter {
  public filters: Filters = {}

  protected filterState: FilterState = {}
  protected onFilterStateChange: Function = () => console.warn('Filter is missing a callback')
  protected handlers: { [handlerName: string]: Function } = {}

  constructor(config: BaseFilterConfig) {
    this.setConfig(config)
  }

  get state() {
    return this.filterState
  }

  public getStateProp = <T>(prop: string) => {
    if (!Object.prototype.hasOwnProperty.call(this.state, prop)) {
      console.warn(`Property [${prop}] does not exist on state object`)
    }
    return this.state[prop] as T
  }

  public setState = (arg: FilterState | Function) => {
    if (typeof arg === 'function') {
      this.filterState = { ...this.filterState, ...arg(this.filterState) }
    } else {
      this.filterState = { ...this.filterState, ...arg }
    }
    this.onFilterStateChange()
  }

  public setConfig = (config: BaseFilterConfig) => {
    if (config.state) this.filterState = config.state
    if (config.callback) this.onFilterStateChange = config.callback
    if (config.handlers) this.handlers = config.handlers
    if (config.filters) {
      this.filters = Object.entries(config.filters!).reduce(
        (accumulator, [name, settings]) =>
          typeof settings === 'function'
            ? { ...accumulator, [name]: { enabled: true, method: settings } }
            : { ...accumulator, [name]: { enabled: true, ...settings } },
        {} as Filters
      )
    }
  }

  public isEnabled = (filterName: string) => {
    const filter = this.filters[filterName]
    if (!filter) {
      console.warn(`Filter [${filterName}] does not exist.`)
      return false
    }
    return filter.enabled
  }

  public enable = (filterName: string) => {
    const filter = this.filters[filterName]
    if (!filter) {
      console.warn(`Filter [${filterName}] does not exist.`)
      return
    }
    filter.enabled = true
    this.onFilterStateChange()
  }

  public disable = (filterName: string) => {
    const filter = this.filters[filterName]
    if (!filter) {
      console.warn(`Filter [${filterName}] does not exist.`)
      return
    }
    filter.enabled = false
    this.onFilterStateChange()
  }

  public toggle = (filterName: string) => {
    const filter = this.filters[filterName]
    if (!filter) {
      console.warn(`Filter [${filterName}] does not exist.`)
      return
    }
    filter.enabled = !filter.enabled
    this.onFilterStateChange()
  }

  public evaluate = <T>(items: Array<unknown>) => {
    const filter = this.composeFilters()
    return filter(items, this.filterState) as Array<T>
  }

  public handler = (handlerName: string) => {
    const fn = this.handlers[handlerName]
    if (!fn) {
      return () => console.warn(`Handler with name [${handlerName}] does not exist.`)
    }

    return (...args: unknown[]) => {
      fn(...args, this)
    }
  }

  protected composeFilters = () => {
    const activeFilters = Object.values(this.filters).reduce(
      (accumulator, filter) =>
        filter.enabled ? [...accumulator, filter.method] : [...accumulator],
      [] as Function[]
    )
    return composeFilter(...activeFilters)
  }
}
