<template>
  <table class="table is-bordered is-narrow" style="font-size: 0.6rem">
    <thead>
      <tr>
        <th v-for="header in tableHeaders" :key="`header-${header}`">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="`item-${item.code}-${index}`">
        <td
          v-for="[field, value] in Object.entries(item)"
          :key="`field-${field}-${value}`"
          :style="getStyle(field, value)"
        >
          {{ value }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

interface RowItem {
  code: string
  description: string
  purchase_price: number
  quantity: number
  sell_price: number
  total_margin: number
  total_margin_percentage: number
  total_purchase_price: number
  total_sell_price: number
}

interface Dictionary {
  [prop: string]: string
}

@Component({
  name: 'DetailsFinanceTable',
})
export default class DetailsFinanceTable extends Vue {
  @Prop({ default: [] }) items!: Array<RowItem>
  @Prop({ default: true }) columnSizeEnabled!: boolean

  protected fieldTranslations: Dictionary = {
    total_margin_percentage: 'total_margin_%',
  }

  protected columnSizes: Dictionary = {
    code: '7rem',
    description: '10rem',
    purchase_price: '4rem',
    quantity: '4rem',
    sell_price: '4rem',
    total_margin: '4rem',
    total_margin_percentage: '4rem',
    total_purchase_price: '4rem',
    total_sell_price: '4rem',
  }

  get tableHeaders() {
    if (this.items.length === 0) return []

    return Object.keys(this.items[0]).map((field) =>
      this.capitalized(this.fieldTranslations[field] || field)
    )
  }

  protected capitalized(field: string) {
    return (field[0].toUpperCase() + field.slice(1)).replaceAll('_', ' ')
  }

  protected getStyle(field: string, value: string | number) {
    const styleRules = [`text-align: ${typeof value === 'string' ? 'left' : 'right'}`]

    if (this.columnSizeEnabled) {
      styleRules.push(`width: ${this.columnSizes[field]}`)
    }

    return styleRules.join(';')
  }
}
</script>
