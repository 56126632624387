<template>
  <div v-if="!isMultiFieldComponent">
    <component
      :is="getComponent(config.component_type, fieldData)"
      v-bind="getComponentProps(config)"
      @change="(value) => onFieldChange(config, value)"
    />
  </div>

  <details-dynamic-image-field
    v-else-if="isImage"
    :data="fieldData"
    :remove="remove"
    :select-image="onClickImageField"
    :list-index="listIndex"
    :selected-image-index="selectedImageIndex"
    @change="onImageUpdate"
  />

  <div v-else>
    <template v-for="(fieldConfig, idx) in config.fields">
      <component
        :key="`${fieldConfig.label}-${idx}`"
        :is="
          getComponent(
            fieldConfig.component_type,
            fieldConfig.prop_name ? fieldData[fieldConfig.prop_name] : fieldData
          )
        "
        v-bind="getComponentProps(fieldConfig)"
        @change="(value) => onFieldChange(fieldConfig, value)"
      />
    </template>
    <div v-if="remove" class="buttons is-right mb-1">
      <button class="delete is-small" @click="remove"></button>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop } from 'vue-property-decorator'

import { isDeepEqual } from '@/util'
import DetailsNumericInput from './DetailsNumericInput.vue'
import DetailsTextInput from './DetailsTextInput.vue'
import DetailsDateInput from './DetailsDateInput.vue'
import DetailsTextAreaInput from './DetailsTextAreaInput.vue'
import DetailsSelector from './DetailsSelector.vue'
import DetailsSearchSelector from './DetailsSearchSelector.vue'
import DetailsDynamicImageField from './DetailsDynamicImageField.vue'

interface FieldConfig {
  prop_name?: string
  label?: string
  field_group: string
  component_type?: string
  data_type?: string
  options?: Array<unknown>
  fields?: Array<FieldConfig>
  display_key?: string
}

@Component({
  name: 'DetailsMaintenanceTicketFormField',
  components: {
    DetailsDynamicImageField,
  },
})
export default class DetailsMaintenanceTicketFormField extends Vue {
  @Prop({}) config!: FieldConfig
  @Prop({ default: 0 }) listIndex!: number
  @Prop({ default: null }) selectedImageIndex!: number | null
  @Prop({}) inputData!: any
  @Prop({}) remove!: Function | null
  @Prop({}) selectImage!: Function | null

  protected fieldData = this.inputData

  get isMultiFieldComponent() {
    return !!this.config.fields
  }

  get isImage() {
    if (!this.isMultiFieldComponent) {
      return this.config.data_type === 'image'
    }

    return this.config.fields!.some((fieldConfig) => fieldConfig.data_type === 'image')
  }

  protected onClickImageField() {
    if (this.selectImage) {
      this.selectImage(this.listIndex)
    }
  }

  protected onImageUpdate(data: any) {
    this.fieldData = data
    this.$emit('change', { config: this.config, data: this.fieldData, listIndex: this.listIndex })
  }

  protected onFieldChange(fieldConfig: FieldConfig, value: any) {
    if (!this.isMultiFieldComponent) {
      this.fieldData = value
    } else {
      this.fieldData = fieldConfig.prop_name
        ? { ...this.fieldData, [fieldConfig.prop_name]: value }
        : { ...this.fieldData, ...value }
    }
    this.$emit('change', { config: this.config, data: this.fieldData, listIndex: this.listIndex })
  }

  protected getComponent(componentType: string, value: any, useSearchSelector = false) {
    switch (componentType) {
      case 'textInput':
        return DetailsTextInput
      case 'dateInput':
        return DetailsDateInput
      case 'textAreaInput':
        return DetailsTextAreaInput
      case 'selector':
        return useSearchSelector ? DetailsSearchSelector : DetailsSelector
      case 'numberInput':
        return DetailsNumericInput
      case 'image':
        return DetailsDynamicImageField
      default:
        console.warn(`No match for component [${componentType}]`)
        return null
    }
  }

  protected getComponentProps(fieldConfig?: FieldConfig) {
    const cfg = fieldConfig || this.config
    const componentAsProp: any = {
      name: cfg.label,
    }
    const props: any = {
      component: componentAsProp, // Detail input fields require a component prop
      autoUpdate: false,
    }

    if (!this.isMultiFieldComponent) {
      componentAsProp.value = this.fieldData
    } else {
      if (cfg.prop_name) {
        componentAsProp.value = this.fieldData[cfg.prop_name]
      }
      if (cfg.options && cfg.data_type === 'dict') {
        const keys = cfg.options.length > 0 ? Object.keys(cfg.options![0] as any) : []
        const value: any = {}
        keys.forEach((key) => {
          value[key] = this.fieldData[key]
        })
        componentAsProp.value = value
      }
    }

    if (cfg.component_type === 'textInput') {
      if (this.isImage) {
        props.usePlaceholder = true
      }
    }

    if (cfg.component_type === 'selector') {
      componentAsProp.values = cfg.options
      if (componentAsProp.value) {
        const match = componentAsProp.values.find((v: any) => isDeepEqual(v, componentAsProp.value))
        if (!match) {
          componentAsProp.values.unshift(componentAsProp.value)
        }
      }
      if (cfg.data_type === 'dict') {
        props.display = cfg.display_key
      }
    }

    return props
  }
}
</script>

<style lang="scss">
.remove-item {
  display: block;
  margin-left: auto;
  font-size: 1rem;
}
</style>
