<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import type { TasksScreenManager } from '@/logic/TasksScreen'

import DetailsBoxHeading from './DetailsBoxHeading.vue'
import DetailsNumericInput from './DetailsNumericInput.vue'
import DetailsDateInput from './DetailsDateInput.vue'
import DetailsSelector from './DetailsSelector.vue'
import DetailsSearchSelector from './DetailsSearchSelector.vue'
import DetailsActionButton from './DetailsActionButton.vue'
import DetailsDeleteCross from './DetailsDeleteCross.vue'
import DetailsImageField from './DetailsImageField.vue'
import DetailsImageModal from './DetailsImageModal.vue'
import DetailsImagePreview from './DetailsImagePreview.vue'
import DetailsImageUpload from './DetailsImageUpload.vue'
import DetailsTicketButton from './DetailsTicketButton.vue'
import DetailsStaticDataField from './DetailsStaticDataField.vue'
import DetailsSignrequestButton from './DetailsSignrequestButton.vue'
import DetailsPDFViewer from './DetailsPDFViewer.vue'
import DetailsTextInput from './DetailsTextInput.vue'
import DetailsTextAreaInput from './DetailsTextAreaInput.vue'
import DetailsCheckboxInput from './DetailsCheckboxInput.vue'

import StatusLabel from '../actions/StatusLabel.vue'

@Component({
  name: 'DetailsPaneBase',
  components: {
    DetailsBoxHeading,
    DetailsNumericInput,
    DetailsTextInput,
    DetailsTextAreaInput,
    DetailsDateInput,
    DetailsSelector,
    DetailsSearchSelector,
    DetailsActionButton,
    DetailsDeleteCross,
    DetailsImageField,
    DetailsImageModal,
    DetailsImagePreview,
    DetailsImageUpload,
    DetailsTicketButton,
    DetailsStaticDataField,
    DetailsSignrequestButton,
    DetailsPDFViewer,
    StatusLabel,
    DetailsCheckboxInput,
  },
})
export default class DetailsPaneBase extends Vue {
  @Prop({}) manager!: TasksScreenManager

  get selectedMasterItem() {
    return this.manager.selectedMasterItem
  }

  get address() {
    return this.selectedMasterItem.address
  }

  get type() {
    return this.selectedMasterItem.detailTaskType
  }

  get components() {
    return this.selectedMasterItem.components
  }

  get status() {
    return this.components.status ? this.components.status.status : null
  }

  protected componentType(component: { component: string }) {
    return component.component
  }

  protected shouldRenderComponentBox(component: object | Array<unknown>) {
    if (!component) return false

    if (Array.isArray(component)) {
      return component.length > 0
    }

    if (typeof component === 'object') {
      return Object.keys(component).length > 1
    }

    console.warn('Component is neither an array nor object')

    return false
  }

  protected async updateMasterData() {
    await this.manager.updateList(this.address)
  }

  protected async refreshDetails() {
    await Promise.all([
      this.updateMasterData(),
      this.manager.setSelectedMasterItem(this.selectedMasterItem, this.type, true),
    ])
  }
}
</script>
