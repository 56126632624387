<template>
  <div class="text-area-input is-relative my-1">
    <details-data-field-label v-if="showLabel" :label="name" :required="required" />

    <span v-if="!enabled && !forceShow" class="text">{{ value }}</span>
    <textarea
      v-else
      class="tw-border"
      :class="classes"
      rows="3"
      style="resize: vertical"
      :disabled="!enabled"
      v-model="inputValue"
      :data-id="`detail-textarea-input?=${inputValue}`"
      @blur="onBlur"
      @input="onInput"
    ></textarea>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator'
import DetailsTextInput from './DetailsTextInput.vue'

@Component({
  name: 'DetailsTextAreaInput',
})
export default class DetailsTextAreaInput extends DetailsTextInput {
  @Prop({ default: true }) showLabel!: boolean
  @Prop({ default: false }) forceShow!: boolean
  @Prop({ default: false }) emitOnInput!: boolean

  protected onInput() {
    if (this.emitOnInput) {
      this.$emit('change', this.inputValue)
    }
  }

  // @Watch('valueOverride')
  // onValueOverride() {
  //   this.inputValue = String(this.valueOverride);
  // }
}
</script>

<style lang="scss">
.text-area-input {
  textarea {
    width: 100%;
  }
}
</style>
