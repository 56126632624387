import { NAMESPACE_GLOBAL } from '@/constants'

interface Namespace {
  [eventName: string]: Array<Function>
}

interface Namespaces {
  [namespace: string]: Namespace
}

export class Events {
  private namespaces: Namespaces = {}

  // public
  public namespace = (namespace: string) => ({
    on: (eventName: string, callback: Function) => {
      this.addListener(namespace, eventName, callback)
    },
    off: (eventName: string, callback: Function) => {
      this.removeListener(namespace, eventName, callback)
    },
    emit: (eventName: string, ...args: Array<unknown>) => {
      this.emitToListeners(namespace, eventName, ...args)
    },
  })

  public on = (eventName: string, callback: Function) => {
    this.addListener(NAMESPACE_GLOBAL, eventName, callback)
  }

  public off = (eventName: string, callback: Function) => {
    this.removeListener(NAMESPACE_GLOBAL, eventName, callback)
  }

  public emit = (eventName: string, ...args: Array<unknown>) => {
    this.emitToListeners(NAMESPACE_GLOBAL, eventName, ...args)
  }

  // private
  private addListener = (namespace: string, eventName: string, callback: Function) => {
    const eventList = this.getEventList(namespace, eventName)
    eventList.push(callback)
  }

  private removeListener = (namespace: string, eventName: string, callback: Function) => {
    const eventList = this.getEventList(namespace, eventName)
    const callbackIndex = eventList.indexOf(callback)
    if (callbackIndex === -1) return

    eventList.splice(callbackIndex, 1)
  }

  private emitToListeners = (namespace: string, eventName: string, ...args: Array<unknown>) => {
    const eventList = this.getEventList(namespace, eventName)
    eventList.forEach((callback) => callback(...args))
  }

  private getNamespace = (namespace: string) => {
    if (this.namespaces[namespace] === undefined) {
      this.namespaces[namespace] = {}
    }
    return this.namespaces[namespace]
  }

  private getEventList = (namespace: string, eventName: string) => {
    const nspc = this.getNamespace(namespace)
    if (nspc[eventName] === undefined) {
      nspc[eventName] = []
    }
    return nspc[eventName]
  }
}

const events = new Events()

export default events
