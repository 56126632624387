<template>
  <div class="box sales-letter">
    <details-box-heading :label="letter.name" />

    <div class="box-content mb-2">
      <template v-for="[field, component] in letterInputFields">
        <details-numeric-input
          :key="`field-${field}`"
          v-if="componentType(component) === 'numberInput'"
          :field="field"
          :component="component"
          @change="updateMasterData"
        />

        <details-date-input
          :key="`field-${field}`"
          v-if="componentType(component) === 'dateInput'"
          :field="field"
          :component="component"
          @change="updateMasterData"
        />
      </template>
    </div>

    <div class="box-content mb-2">
      <template v-for="[field, component] in letterImageFields">
        <details-image-field
          v-if="componentType(component) === 'image'"
          class="mr-1"
          :key="`image-${field}-${component.value}`"
          :field="field"
          :component="component"
          @click="onClickImageField"
          @mouseenter="onHoverImageField"
        />

        <a
          v-if="componentType(component) === 'PDFViewer'"
          :key="`field-${field}`"
          class="button is-small"
          style="margin: 0"
          :href="component.url"
          target="_blank"
        >
          Show contract
        </a>
      </template>
    </div>

    <!-- IMAGE PREVIEW / UPLOAD -->
    <details-image-preview
      v-if="hasImage(activeImage)"
      :field="activeImageField"
      :component="activeImageComponent"
      @click="onClickImageField"
    />
    <details-image-upload
      v-else-if="activeImage"
      :field="activeImageField"
      :component="activeImageComponent"
      @uploadstart="onUploadImageStart"
      @uploadend="onUploadImageEnd"
    />

    <!-- IMAGE MODAL -->
    <details-image-modal
      v-if="showImageModal"
      :key="`${letterImages.length}-${activeImageIndex}`"
      :images="letterImages"
      :initial-index="activeImageIndex"
      :delete="onDeleteImage"
      :close="onCloseImageModal"
    />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import { STATUS_OK } from '@/constants'
import type { CustomResponse } from '@/interfaces'

import requests from '@/requests'
import DetailsPaneBase from './DetailsPaneBase.vue'

interface ImageComponent {
  value: string
  action: { [prop: string]: string }
}

type ImageField = [string, ImageComponent]

@Component({
  name: 'DetailsSalesLetterBox',
})
export default class DetailsSalesLetterBox extends DetailsPaneBase {
  protected activeImage: ImageField | null = null
  protected showImageModal = false
  protected isProcessingImage = false

  get letter() {
    return this.components.letter || {}
  }

  get letterInputFields() {
    const keepTypes = ['numberInput', 'dateInput']

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(this.letter).filter(([_, component]) =>
      keepTypes.includes(this.componentType(component as { component: string }))
    )
  }

  get letterImageFields() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(this.letter).filter(([_, component]) =>
      ['image', 'PDFViewer'].includes(this.componentType(component as { component: string }))
    ) as Array<[string, ImageComponent]>
  }

  get letterImages() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return this.letterImageFields.filter(([_, component]) => !!component.value)
  }

  get activeImageField() {
    return this.activeImage ? this.activeImage[0] : null
  }

  get activeImageComponent() {
    return this.activeImage ? this.activeImage[1] : null
  }

  get activeImageIndex() {
    if (!this.activeImage) return 0

    for (let idx = 0; idx < this.letterImages.length; idx += 1) {
      if (this.letterImages[idx][0] === this.activeImage[0]) {
        return idx
      }
    }

    return 0
  }

  protected hasImage(imageField: ImageField) {
    return imageField && !!imageField[1].value
  }

  protected onClickImageField([field, component]: ImageField) {
    if (this.isProcessingImage) return

    this.activeImage = [field, component]
    if (component.value) {
      this.showImageModal = true
    }
  }

  protected onCloseImageModal() {
    this.showImageModal = false
  }

  protected async onDeleteImage(image: ImageField) {
    this.isProcessingImage = true

    const [field, component] = image
    const response = await requests.details.deleteTaskDetailForClient(
      `api/v1/${component.action.delete}`,
      { [field]: component.value }
    )

    if (response.status === STATUS_OK) {
      component.value = ''
      this.components.letter = { ...this.letter }
      if (this.letterImages.length < 1) {
        this.onCloseImageModal()
      }

      this.refreshDetails()
    }

    this.isProcessingImage = false
  }

  protected onHoverImageField([field, component]: ImageField) {
    if (this.isProcessingImage) return

    this.activeImage = [field, component]
  }

  protected onUploadImageStart() {
    this.isProcessingImage = true
  }

  protected onUploadImageEnd(response: CustomResponse) {
    this.isProcessingImage = false
    if (response.status === STATUS_OK) {
      const responseData = response.data[0]

      for (let idx = 0; idx < this.letterImageFields.length; idx += 1) {
        const [field, component] = this.letterImageFields[idx]
        if (field === responseData.attribute_name) {
          component.value = responseData.attribute_value
          this.components.letter = { ...this.letter }
          this.refreshDetails()
          return
        }
      }
    }
  }

  created() {
    this.activeImage = this.letterImages.length > 0 ? this.letterImages[0] : null
  }
}
</script>
