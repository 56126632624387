import BaseList from '@/logic/common/BaseList'
import { NAMESPACE_GLOBAL } from '@/constants'
import { events } from '@/util'
import type { MultiSelectListConfig } from '@/interfaces'

class MultiSelectList<T> extends BaseList<T> {
  public anchor: T | null = null
  public selectedItems: Array<T> = []
  public selectAllActive = false

  protected selectedItemsMap: Map<T, boolean> = new Map()
  protected multiSelectEnabled: boolean
  protected checkboxMode: boolean
  protected selectionRange = [0, 0]
  protected anchorIndex = 0

  constructor(items: Array<T> = [], config: MultiSelectListConfig<T> = {}) {
    super(items, config)
    this.multiSelectEnabled =
      config.multiSelectEnabled !== undefined ? config.multiSelectEnabled : true
    this.checkboxMode = !!config.checkboxMode
    if (this.filter) {
      this.filter.setConfig({ callback: this.filterItems })
    }
  }

  get selectedItemCount() {
    return this.selectedItems.length
  }

  public removeSelected = () => {
    const accumulator: [Array<T>, Array<T>] = [[], []]
    const [selectedItems, unselectedItems] = this.items.reduce((acc, item) => {
      const list = this.isSelected(item) ? acc[0] : acc[1]
      list.push(item)
      return acc
    }, accumulator)
    this.setItems(unselectedItems)
    this.resetState()
    return selectedItems
  }

  public isSelected = (item: T) => this.selectedItemsMap.has(item)

  public selectAll = () => {
    this.selectedItemsMap = new Map()
    this.filteredItems.forEach((item) => this.selectedItemsMap.set(item, true))
    this.selectAllActive = true
    this.update(false)
  }

  public deselectAll = () => {
    this.selectedItemsMap = new Map()
    this.resetState()
    this.selectAllActive = false
    this.update(false)
  }

  public toggleSelectAll = () => (this.selectAllActive ? this.deselectAll() : this.selectAll())

  public setPreselectedItems = (items: Array<T>) => {
    const targetItemsMap = new Map()
    items.forEach((item) => targetItemsMap.set(item, true))
    this.items.forEach((item) => {
      if (targetItemsMap.has(item)) {
        this.selectItem(item)
      } else {
        this.deselectItem(item)
      }
    })
    this.update(false)
  }

  public onClickItem = (item: T, event: MouseEvent) => {
    if (!this.multiSelectEnabled) {
      this.onNormalClick(item)
      this.update(false)
      return
    }

    if (event.ctrlKey || event.metaKey || (event.shiftKey && this.anchor === null)) {
      this.onControlClick(item)
    } else if (event.shiftKey) {
      this.onShiftClick(item)
    } else {
      this.onNormalClick(item)
    }
    this.update(false)
  }

  public filterItems = () => {
    super.filterItems()
    this.deselectFilteredItems()
  }

  protected onNormalClick = (item: T) => {
    if (this.checkboxMode) {
      this.onControlClick(item)
      return
    }
    const itemIndex = this.items.indexOf(item)
    this.items.forEach((i) => (i === item ? this.selectItem(i) : this.deselectItem(i)))
    this.selectionRange = [itemIndex, itemIndex]
    this.setAnchor(item)
  }

  protected onControlClick = (item: T) => {
    const itemIndex = this.items.indexOf(item)
    this.toggleSelected(item)
    this.selectionRange = [itemIndex, itemIndex]
    this.setAnchor(item)
  }

  protected onShiftClick = (item: T) => {
    const itemIndex = this.items.indexOf(item)
    const [start, end] = this.selectionRange
    if (itemIndex < start) this.selectionRange = [itemIndex, end]
    if (itemIndex > end) this.selectionRange = [start, itemIndex]

    const itemsToReset = this.getItemsInRange()
    const selectedRange = this.getItemsInRange(
      itemIndex < this.anchorIndex ? [itemIndex, this.anchorIndex] : [this.anchorIndex, itemIndex]
    )

    const isSelected = this.isSelected(this.anchor!)
    if (isSelected) {
      itemsToReset.forEach((i) => this.setSelected(i, !isSelected))
    }
    selectedRange.forEach((i) => this.setSelected(i, isSelected))
  }

  protected setSelected = (item: T, isSelected: boolean) => {
    const selectMethod = isSelected ? this.selectItem : this.deselectItem
    selectMethod(item)
  }

  protected selectItem = (item: T) => this.selectedItemsMap.set(item, true)

  protected deselectItem = (item: T) => this.selectedItemsMap.delete(item)

  protected toggleSelected = (item: T) => {
    if (this.isSelected(item)) {
      this.deselectItem(item)
    } else {
      this.selectItem(item)
    }
  }

  protected deselectFilteredItems = () => {
    this.items.forEach((item) => {
      if (!this.filteredItemsMap.has(item)) {
        this.selectedItemsMap.delete(item)
        if (item === this.anchor) {
          this.resetState()
        }
      }
    })
    this.selectedItems = this.items.filter((item) => this.selectedItemsMap.has(item))
  }

  protected resetState = () => {
    this.selectionRange = [0, 0]
    this.anchorIndex = 0
    this.anchor = null
  }

  protected setAnchor = (item: T) => {
    this.anchor = item
    this.anchorIndex = this.items.indexOf(item)
  }

  protected getItemsInRange = (range?: [number, number]) => {
    const [start, end] = range || this.selectionRange
    return this.items.slice(start, end + 1)
  }

  protected update = (sortOnUpdate = true) => {
    this.itemsMap = new Map()
    this.items.forEach((item) => this.itemsMap.set(item, true))

    if (this.autoRemoveDuplicates) {
      this.removeDuplicates(false)
    }

    if (this.autoSort && sortOnUpdate) {
      this.sort(this.activeSorter, false)
    }

    this.filterItems()
    this.selectedItems = this.items.filter((item) => this.selectedItemsMap.has(item))
    if (this.emitOnChange) {
      const { namespace, eventName, key } = this.emitOnChange
      events.namespace(namespace || NAMESPACE_GLOBAL).emit(eventName, this, key)
    }
  }
}

export default MultiSelectList
