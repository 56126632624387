<template>
  <div class="image-upload is-flex is-justify-content-center is-align-items-center">
    <div class="image-input has-text-centered">
      <h6 class="has-text-centered mb-2">{{ name }}</h6>
      <template v-if="previewImage">
        <!-- IMAGE -->
        <thumbnail
          v-if="hasImageFileExtension(fileExtension)"
          :key="fileExtension"
          :src="previewImage"
          :alt="fileName"
          size="150"
        />
        <!-- PDF ICON -->
        <i
          v-else-if="fileExtension === 'pdf'"
          :key="fileExtension"
          class="fa-file-icon fas fa-file-pdf"
        ></i>
        <!-- EXCEL ICON -->
        <i
          v-else-if="hasTableFileExtension(fileExtension)"
          :key="fileExtension"
          class="fa-file-icon fas fa-file-excel"
        ></i>
      </template>

      <div class="file has-name">
        <span v-if="actionStatus.type === 'loading'">Uploading...</span>
        <span v-else-if="actionStatus.type === 'error'" style="color: red">
          {{ actionStatus.message }}
        </span>
        <span v-else-if="actionStatus.type === 'success'" style="color: green">
          {{ actionStatus.message }}
        </span>

        <label v-else class="file-label">
          <template v-if="!previewImage">
            <input
              class="file-input"
              type="file"
              name="resume"
              data-id="detail-image-upload"
              @input="onFileChange"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Choose a file… </span>
            </span>
            <span class="file-name">
              {{ fileName || 'No file uploaded yet' }}
            </span>
          </template>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'

import { getFileExtensionFromURL, hasImageFileExtension, hasTableFileExtension } from '@/util'

import DetailsDataComponentBase from './DetailsDataComponentBase.vue'

@Component({
  name: 'DetailsImageUpload',
})
export default class DetailsImageUpload extends DetailsDataComponentBase {
  @Prop({ default: true }) autoUpdate!: boolean

  protected previewImage: string | ArrayBuffer | null = null
  protected fileName = ''
  protected fileExtension: string | null = null

  get action() {
    return 'update'
  }

  protected onFileChange(event: Event) {
    const target = event.target! as HTMLInputElement
    const image = target.files![0]
    const reader = new FileReader()

    reader.readAsDataURL(image)
    reader.onload = () => {
      this.fileName = image.name
      this.fileExtension = getFileExtensionFromURL(image.name)
      this.previewImage = reader.result
    }

    this.onUploadImage(image)
  }

  protected onImagePaste(event: ClipboardEvent) {
    if (this.previewImage) return

    const { items } = event.clipboardData!
    if (items.length < 1) return

    const file = items[0]
    if (file.type.indexOf('image') === -1) return
    const fileType = file.type
    event.preventDefault()

    // check focus, don't add image if focus is on an input; user may've accidentally pasted an image instead of text
    const focussedElement = document.activeElement as HTMLElement
    if (
      focussedElement &&
      (['INPUT', 'TEXTAREA'].includes(focussedElement.tagName) || focussedElement.isContentEditable)
    )
      return

    const blob = file.getAsFile()!
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => {
      this.previewImage = reader.result
      this.fileExtension = fileType.split('/')[1] as string
    }

    this.onUploadImage(blob)
  }

  protected async onUploadImage(blob: File) {
    if (!this.autoUpdate) {
      this.$emit('change', blob)
      return
    }
    const formData = new FormData()
    formData.append('type', this.field)
    formData.append('image', blob)

    this.$emit('uploadstart')

    this.submitAction({}, formData).then((response) => {
      this.$emit('uploadend', response)
    })
    this.$emit('upload', blob)
  }

  protected getFileExtensionFromURL(source: string) {
    return getFileExtensionFromURL(source)
  }

  protected hasImageFileExtension(source: string) {
    return hasImageFileExtension(source)
  }

  protected hasTableFileExtension(source: string) {
    return hasTableFileExtension(source)
  }

  mounted() {
    document.addEventListener('paste', this.onImagePaste)
  }

  beforeDestroy() {
    document.removeEventListener('paste', this.onImagePaste)
  }
}
</script>

<style lang="scss">
.image-upload {
  min-height: 27vh;

  .fa-file-icon {
    font-size: 20vh !important;
  }
}
</style>
