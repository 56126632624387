<template>
  <generic-filters :filter="filter">
    <form-field
      label="Type"
      placeholder="All types"
      :options="filterOptions.types"
      mode="tags"
      type="multi"
      v-model="filter.types"
      @resetFilters="resetFilters"
    />

    <template v-for="type in filter.types">
      <form-field
        v-if="type !== ''"
        :key="type"
        :label="`Status ${type}`"
        :placeholder="`All ${type} statuses`"
        :options="statusOptions"
        mode="tags"
        type="multi"
        v-model="filter.statuses[type]"
      />
    </template>

    <form-field
      label="Woco"
      nullOption="All wocos"
      :options="filterOptions.wocos"
      type="select"
      v-model="filter.wocos"
    />

    <form-field
      label="Projecten"
      :options="filterOptions.projects"
      mode="tags"
      type="multi"
      v-model="filter.projects"
      placeholder="All projects"
    />

    <form-field
      label="Groups"
      placeholder="All groups"
      :options="filterOptions.groups"
      mode="tags"
      type="multi"
      v-model="filter.groups"
    />
  </generic-filters>
</template>

<script setup>
import { useQuery } from '@tanstack/vue-query'
import fetchData from '@/util/fetch'
import { computed } from 'vue'
import FormField from '@/components/shared/FormField.vue'
import GenericFilters from '../shared/GenericFilters.vue'

const props = defineProps({
  filter: {
    type: Object,
    required: true,
  },
  statusOptions: {
    type: Array,
    required: true,
  },
  typeOptions: {
    type: Array,
    required: true,
  },
})

const getGroups = () => fetchData(`/groups/api/v1/group`)

const groupQuery = useQuery({
  queryKey: ['groups'],
  queryFn: getGroups,
})

// groups and projects
const groups = computed(() => {
  if (props.filter.wocos.length && groupQuery.data.value) {
    return Array.from(
      new Set(groupQuery.data.value.data.filter((group) => group.woco === props.filter.wocos))
    )
  }

  return (groupQuery.data.value && Array.from(new Set(groupQuery.data.value.data))) || []
})

const groupsOptions = computed(() =>
  groups.value.map((group) => ({ value: group.id, label: group.name }))
)

// new projects
const getProjects = () => fetchData('/config/api/v1/projects/?view=full')
const projectsQuery = useQuery({
  queryKey: ['projects'],
  queryFn: getProjects,
})

const projects = computed(() => {
  if (props.filter.wocos.length) {
    return projectsQuery.data.value?.data
      .filter((prj) => prj.woco === props.filter.wocos)
      .map((prj) => ({ value: prj.id, label: prj.name }))
  }
  return projectsQuery.data.value?.data.map((prj) => ({ value: prj.id, label: prj.name }))
})

// wocos
const getWocos = () => fetchData('/config/api/v1/wocos/?view=full')
const wocosQuery = useQuery({
  queryKey: ['wocos'],
  queryFn: getWocos,
})
const wocos = computed(() => wocosQuery.data.value?.data.map((w) => w.name))

// partners
const getPartners = () => fetchData('/tickets/api/v1/partners')
const partnersQuery = useQuery({
  queryKey: ['partners'],
  queryFn: getPartners,
})

const partners = computed(() => partnersQuery.data.value?.data.map((partner) => partner.full_name))

const filterOptions = {
  statuses: props.statusOptions,
  types: props.typeOptions,
  projects: projects,
  groups: groupsOptions,
  partners: partners,
  wocos: wocos,
}

const resetFilters = () => {
  Object.assign(props.filter, {
    projects: [],
    groups: [],
    statuses: {},
    types: [],
    partners: '',
    wocos: '',
    query: '',
  })
}
</script>
