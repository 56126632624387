<template>
  <div class="tw-flex tw-h-screen tw-w-full tw-gap-2 tw-p-2">
    <div class="tw-flex-column tw-h-screen tw-justify-between tw-gap-2">
      <div class="tw-flex tw-flex-row tw-gap-2 tw-ml-2">
        <generic-filters @resetFilters="resetFilters">
          <form-field
            label="Partner"
            nullOption="Alle partners"
            :options="partners"
            type="select"
            mode="tags"
            v-model="filter.partner"
            class="tw-w-1/5 tw-mr-5"
          />
          <form-field
            label="Jaar"
            nullOption="Alle jaren"
            :options="availableYears"
            type="select"
            v-model="filter.year"
          />
          <form-field
            label="Week"
            nullOption="Alle weken"
            :options="weeks"
            type="select"
            v-model="filter.week"
          />
          <form-field
            label="Project"
            nullOption="All projecten"
            :options="projects"
            mode="single"
            multiple="false"
            type="multi"
            v-model="filter.project"
          />
        </generic-filters>

        <div class="tw-w-full tw-flex tw-gap-2 tw-mr-2">
          <div class="tw-my-2 tw-flex-auto tw-flex-col tw-h-full">
            <div class="tw-mx-2 tw-font-bold tw-tracking-wider tw-uppercase tw-text-gray-500">
              Purchase orders
            </div>
            <generic-table
              :columns="columns"
              :data="orders"
              :has-next-page="hasNextPage"
              :is-loading="isLoading"
              @fetchNextPage="fetchNextPage()"
              :rowClickHandler="showOrderDetail"
            >
              <template v-slot:cell-project_id="{ row }">
                {{ projects.find((p) => p.value === row.project_id)?.label }}
              </template>

              <template v-slot:cell-exact_stock_count_id="{ row }">
                <CheckIcon v-if="row.exact_stock_count_id" class="tw-w-5 tw-h-5" />
                <XIcon v-else class="tw-w-5 tw-h-5" />
              </template>

              <template v-slot:cell-exact_purchase_order_id="{ row }">
                <CheckIcon v-if="row.exact_purchase_order_id" class="tw-w-5 tw-h-5" />
                <XIcon v-else class="tw-w-5 tw-h-5" />
              </template>
            </generic-table>
          </div>
          <div
            class="tw-mr-2 tw-flex-auto tw-flex-col tw-w-1/4 tw-max-w-1/4"
            v-if="$route.params.id"
          >
            <purchase-order-detail />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { notifications } from '@/util'
import fetchData from '@/util/fetch'
import PurchaseOrderDetail from '@/components/PurchaseOrders/PurchaseOrderDetail.vue'
import GenericFilters from '@/components/shared/GenericFilters.vue'
import GenericTable from '@/components/shared/GenericTable.vue'
import FormField from '@/components/shared/FormField.vue'
import { XIcon, CheckIcon } from '@vue-hero-icons/outline'
import { useRouter, useRoute } from 'vue-router/composables'

const queryClient = useQueryClient()
const router = useRouter()
const route = useRoute()

const filter = reactive({
  partner: '',
  year: '',
  week: '',
  project: '',
})

const resetFilters = () => {
  filter.partner = ''
  filter.year = ''
  filter.week = ''
  filter.project = ''
}

const getPartners = () => fetchData('/tickets/api/v1/partners')
const partnersQuery = useQuery({
  queryKey: ['partners'],
  queryFn: getPartners,
})
const partners = computed(() => partnersQuery.data?.value?.data.map((p) => p.legacy_name) || [])

const getProjects = () => fetchData('/config/api/v1/projects/?view=full')
const projectsQuery = useQuery({
  queryKey: ['projects'],
  queryFn: getProjects,
})

const projects = computed(
  () => projectsQuery.data.value?.data.map((prj) => ({ value: prj.id, label: prj.name })) || []
)

const availableYears = computed(() => {
  const years = projectsQuery.data.value?.data.map((p) => p.year) || []
  return [...new Set(years)].sort()
})

const weeks = computed(() => {
  const weeks = Array.from({ length: 52 }, (_, i) => i + 1)
  return weeks.map((w) => ({ value: w, label: w }))
})

const perPage = 50

const getList = async ({ pageParam = 1 }) => {
  const urlParams = new URLSearchParams({
    offset: (pageParam - 1) * perPage,
    limit: perPage,
  })

  const { partner, year, week, project } = filter

  if (partner) {
    urlParams.append('partner', partner)
  }
  if (year) {
    urlParams.append('year', year)
  }
  if (week) {
    urlParams.append('week', week)
  }
  if (project) {
    urlParams.append('project_id', project)
  }

  try {
    const result = await fetch(`/tickets/api/v1/purchase-orders?${urlParams}`)
    if (!result.ok) {
      notifications.addNotification({
        message: `Failed to get list of POs: ${result.detail || result.statusText}`,
        type: 'danger',
      })
      return
    }
    return await result.json()
  } catch (error) {
    notifications.addNotification({
      message: `Failed to get list of POs, ${e.message}`,
      type: 'danger',
    })
    throw new Error('Failed to get list of POs with error: ' + error.message)
  }
}

const {
  data: ordersQuery,
  isLoading,
  fetchNextPage,
  hasNextPage,
} = useInfiniteQuery({
  queryKey: computed(() => ['orders', filter]),
  keepPreviousData: true,
  queryFn: getList,
  refetchOnWindowFocus: false,
  keepPreviousData: true,
  getNextPageParam: (_, allPages) =>
    allPages[allPages.length - 1].data?.length === perPage ? allPages.length + 1 : undefined,
})

const orders = computed(() => ordersQuery?.value?.pages.flatMap((p) => p.data))

const columns = [
  { label: 'Partner name', field: 'partner_name' },
  { label: 'Project', field: 'project_id' },
  { label: 'Year', field: 'year' },
  { label: 'Week', field: 'week' },
  { label: 'Exact Stock Count', field: 'exact_stock_count_id' },
  { label: 'Exact Purchase Order', field: 'exact_purchase_order_id' },
]

const showOrderDetail = (row) => {
  if (row.purchase_order?.id) {
    const id = computed(() => route.params?.id)
    row.purchase_order?.id !== id.value &&
      router.push({ name: 'order-detail', params: { id: row.purchase_order.id } })
  } else {
    createOrder(row)
  }
}

const { mutate: createOrder } = useMutation({
  mutationFn: (row) => {
    const payload = {
      partner_name: row.partner_name,
      project_id: row.project_id,
      year: row.year,
      week: row.week,
    }
    return fetchData(`/tickets/api/v1/purchase-orders`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },
  onError: (e) => {
    console.error('Error on creating PO', e)
    const info = e.message || e.detail || e
    notifications.addNotification({
      message: `Error occured, ${info}`,
      type: 'danger',
    })
  },
  onSuccess: (data) => {
    console.log('PO created', data)
    router.push({ name: 'order-detail', params: { id: data.data.id } })
    queryClient.invalidateQueries({ queryKey: ['orders'] })
  },
})
</script>
