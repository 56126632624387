import {
  TASK_TYPE_DESIGN,
  TASK_TYPE_SALES,
  TASK_TYPE_INSTALLATION,
  TASK_TYPE_CLIENT,
  TASK_TYPE_FINANCE,
  TASK_TYPE_INSPECTION,
  TASK_TYPE_MAINTENANCE,
  STATUS_OK,
} from '@/constants'
import request from '@/requests'

import type {
  Address,
  ActionData,
  TaskTypeKey,
  DetailData,
  TaskHistoryData,
  BaseTaskType,
  Client,
} from '@/interfaces'

export default class MasterItem {
  public address: string
  public street: string
  public houseNumber: string
  public postalCode: string
  public groups: Array<string>
  public project: string
  public woco: string

  public design: BaseTaskType
  public sales: BaseTaskType
  public installation: BaseTaskType
  public finance: BaseTaskType
  public inspection: BaseTaskType
  public maintenance: BaseTaskType
  public client: Client
  public actionData: ActionData

  public isLoading = false

  public details: DetailData | undefined

  constructor(address: Address, actionData?: ActionData) {
    this.address = address.address
    this.street = address.street
    this.houseNumber = address.house_number
    this.postalCode = address.postal_code
    this.groups = address.group_list || []
    this.project = address.project
    this.woco = address.woco
    this.design = this.createTaskTypeDataStructure()
    this.sales = this.createTaskTypeDataStructure()
    this.installation = this.createTaskTypeDataStructure()
    this.finance = this.createTaskTypeDataStructure()
    this.inspection = this.createTaskTypeDataStructure()
    this.maintenance = this.createTaskTypeDataStructure()
    this.client = this.createClientDataStructure()
    this.actionData = actionData || {}
  }

  public setIsLoading = (value: boolean) => {
    this.isLoading = value
  }

  public setTypeData = (type: string, data: object) => {
    switch (type) {
      case TASK_TYPE_DESIGN:
      case TASK_TYPE_INSTALLATION:
      case TASK_TYPE_SALES:
      case TASK_TYPE_FINANCE:
      case TASK_TYPE_INSPECTION:
      case TASK_TYPE_MAINTENANCE:
        this[type as TaskTypeKey] = data as BaseTaskType
        return
      case TASK_TYPE_CLIENT:
        this.client = data as Client
        return
      default:
        console.warn(`There's no type of [${type}] defined for MasterItem`)
    }
  }

  public setActionData = (actionData: ActionData) => {
    this.actionData = {
      ...this.actionData,
      ...actionData,
    }
  }

  public resetActionData = () => {
    this.actionData = {}
  }

  public getTaskTypeFromURL = (url: string) => {
    const match = url.match(/task_type\/(?<taskType>\w+)\//)
    if (!match) {
      console.warn(`Failed to extract task type from [${url}]`)
      return null
    }

    return match.groups!.taskType
  }

  public getStatus = (taskType: TaskTypeKey) => {
    const taskData = this[taskType]
    return taskData && taskData.status && taskData.status.status !== 'no_status'
      ? taskData.status.status
      : null
  }

  public getStatusAt = (taskType: TaskTypeKey) => {
    const taskData = this[taskType]
    return taskData && taskData.status && taskData.status.at ? taskData.status.at : null
  }

  public getStatusBy = (taskType: TaskTypeKey) => {
    const taskData = this[taskType]
    return taskData && taskData.status && taskData.status.by ? taskData.status.by : null
  }

  public getTaskData = (taskType: TaskTypeKey) => {
    const taskData = this[taskType]
    return taskData && taskData.data ? taskData.data : {}
    // return taskData.data || {};
  }

  public getTaskExtraData = (taskType: TaskTypeKey) => {
    const taskData = this[taskType]
    return taskData && taskData.extra_data ? taskData.extra_data : {}
  }

  public getActions = (taskType: TaskTypeKey) => {
    const taskData = this[taskType]
    return taskData && taskData.actions ? taskData.actions : []
  }

  public hasActions = (taskType: TaskTypeKey) => {
    const taskData = this[taskType]
    return taskData && taskData.actions && taskData.actions.length > 0
  }

  public setDetails = (detailsData: DetailData | undefined) => {
    this.details = detailsData
  }

  public getDetailsFields = () => {
    if (!this.details) return null

    const fields = Object.entries(this.details).reduce((acc, [groupName, groupData]) => {
      if (groupName === 'products') return acc
      return {
        ...acc,
        [groupName]: {
          ...Object.entries(groupData).reduce(
            (accumulatedFields, [fieldName, fieldData]) => ({
              ...accumulatedFields,
              [fieldName]:
                fieldName === 'name'
                  ? fieldData
                  : {
                      selected: true,
                      name: fieldData.name,
                    },
            }),
            {}
          ),
        },
      }
    }, {})

    return fields
  }

  public getTaskHistory = async (taskType: TaskTypeKey) => {
    const response = await request.tasks.getTaskHistory(taskType, this.address)
    if (response.status !== STATUS_OK) {
      return null
    }

    return response.data as TaskHistoryData
  }

  protected createTaskTypeDataStructure = () =>
    ({
      data: {},
      status: {
        status: '',
        at: '',
        by: '',
      },
      actions: [],
      extra_data: {},
    }) as BaseTaskType

  protected createClientDataStructure = () =>
    ({
      household: {
        id: '',
        id_woco: '',
        street: '',
        house_number: '',
        postal_code: '',
        address: '',
        city: '',
        country: '',
      },
      people: [],
      phones: [],
      email: '',
    }) as Client
}
