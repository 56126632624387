import Vue from 'vue'

interface OuterClickableElement extends HTMLElement {
  __clickHandler__?: (event: Event) => void
}

Vue.directive('outer-click', {
  bind(el: OuterClickableElement, binding) {
    // eslint-disable-next-line no-param-reassign
    el.__clickHandler__ = (event: Event) => {
      if (!(el === event.target) && !el.contains(event.target as Node)) {
        binding.value(event)
      }
    }
    document.addEventListener('click', el.__clickHandler__)
  },
  unbind(el: OuterClickableElement) {
    document.removeEventListener('click', el.__clickHandler__!)
  },
})

interface DataSelectableElement extends HTMLElement {
  __inputHandler__?: (event: Event) => void
}

Vue.directive('datalist-selection', {
  bind(el: DataSelectableElement, binding) {
    // eslint-disable-next-line no-param-reassign
    el.__inputHandler__ = (event: Event) => {
      const e = event as InputEvent
      if (e.inputType === 'insertReplacementText' || e.inputType === undefined) {
        const target = event.target as HTMLInputElement
        binding.value(target.value)
      }
    }
    el.addEventListener('input', el.__inputHandler__)
  },
  unbind(el: DataSelectableElement) {
    el.removeEventListener('input', el.__inputHandler__!)
  },
})
