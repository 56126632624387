<template>
  <div v-if="paneInfo.loading">Loading...</div>
  <div v-else>
    <finance-table :manager="manager" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import BaseTasksPane from './BaseTasksPane.vue'
import FinanceTable from './table/FinanceTable.vue'

@Component({
  name: 'FinancePane',
  components: {
    FinanceTable,
  },
})
export default class FinancePane extends BaseTasksPane {}
</script>
