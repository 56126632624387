<template>
  <div class="detail-content">
    <div class="is-flex is-flex-wrap-wrap">
      <div class="box finance-box">
        <!-- ACTUALS -->
        <details-box-heading label="Actuals" />

        <!-- PRODUCTS -->
        <template v-if="products.length > 0">
          <details-box-heading label="Products" />
          <div class="box-content mb-2">
            <details-finance-table :items="products" />
          </div>
        </template>

        <!-- SERVICES -->
        <template v-if="services.length > 0">
          <details-box-heading label="Services" />
          <div class="box-content mb-2">
            <details-finance-table :items="services" />
          </div>
        </template>

        <!-- ADDITIONAL WORK -->
        <template v-if="additionalWork.length > 0">
          <details-box-heading label="Additional work" />
          <div class="box-content mb-2">
            <details-finance-table :items="additionalWork" />
          </div>
        </template>

        <!-- TOTALS -->
        <template v-if="totals">
          <details-box-heading label="Totals" />
          <div class="box-content mb-2">
            <details-finance-table :items="[totals]" :column-size-enabled="false" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import DetailsPaneBase from './DetailsPaneBase.vue'
import DetailsFinanceTable from './DetailsFinanceTable.vue'

@Component({
  name: 'DetailsPaneFinance',
  components: {
    DetailsFinanceTable,
  },
})
export default class DetailsPaneFinance extends DetailsPaneBase {
  get actuals() {
    return this.components.products && this.components.products.finance_product_actuals
      ? this.components.products.finance_product_actuals
      : {}
  }

  get products() {
    return this.actuals.products || []
  }

  get services() {
    return this.actuals.services || []
  }

  get additionalWork() {
    return this.actuals.additional_work || []
  }

  get totals() {
    return this.actuals.totals
  }
}
</script>
