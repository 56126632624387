<template>
  <div class="is-flex is-justify-content-space-between my-1 is-relative">
    <div class="wrapper">
      <div class="dropdown is-left" :class="classes" v-outer-click="() => (isActive = false)">
        <div class="dropdown-trigger">
          <button
            class="button is-fullwidth is-outlined flex is-justify-content-space-between"
            :disabled="disabled"
            :class="classes"
            :style="width && `width: ${width}px;`"
            @click="() => (isActive = !isActive)"
          >
            <span>
              {{ getDisplayPropertyValue(selected) || getDisplayPropertyValue(items[0]) }}
            </span>
            <span class="icon is-small has-text-info">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu">
          <div class="dropdown-content" :style="width && `min-width: ${width}px;`">
            <input class="input" type="text" v-model="content" />
            <div v-if="filteredOptions.length" class="items">
              <a
                v-for="option in filteredOptions"
                :key="getDisplayPropertyValue(option)"
                :data-id="dataId"
                @click="() => onSelectChange(option)"
                class="dropdown-item"
                >{{ getDisplayPropertyValue(option) }}</a
              >
            </div>
            <div v-else style="padding: 10px; text-align: center">No results found</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { sortStringWithNumbers } from '@/logic/common'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

interface Dictionary {
  [key: string]: Dictionary
}

@Component({})
export default class SearchableDropdown extends Vue {
  @Prop() display!: string
  @Prop({ default: () => [] }) items!: Array<string>
  @Prop({ default: '' }) id!: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: '' }) preselect!: string
  @Prop({}) dataId!: string
  @Prop({ default: '120' }) width!: string

  get filteredOptions() {
    return this.items.filter((item) =>
      this.getDisplayPropertyValue(item).toLowerCase().includes(this.content.toLowerCase())
    )
  }

  get classes() {
    return {
      'is-active': this.isActive,
    }
  }

  public content = ''
  public isActive = false

  private selected = this.preselect || ''

  private onSelectChange(item: string) {
    this.content = ''
    this.selected = item
    this.isActive = false
    this.$emit('change', item)
  }

  @Watch('preselect')
  onPreselectChange() {
    this.selected = this.preselect
  }

  // protected itemValue(item: string | Dictionary) {
  //   if (typeof item === 'string') return item;
  //   return this.getDisplayPropertyValue(item);
  // }

  public getDisplayPropertyValue = (item: string | Dictionary) => {
    if (!this.display || typeof item === 'string') return item as string

    return this.display
      .split('.')
      .reduce((v: Dictionary, k) => v[k], item as Dictionary) as unknown as string
  }
}
</script>

<style lang="scss" scoped>
.is-success {
  select {
    border-color: rgb(62, 189, 109) !important;

    &:after {
      border-color: rgb(62, 189, 109) !important;
    }
  }
}

.is-danger {
  select {
    border-color: rgb(196, 69, 69) !important;

    &:after {
      border-color: rgb(196, 69, 69) !important;
    }
  }
}

.items {
  overflow-y: scroll;
  max-height: 15rem;
}

.dropdown-content {
  min-width: 1rem;
}

.dropdown-item {
  font-size: 0.6rem !important;
}

.button {
  font-size: 1em;
  height: calc(23px + 0.2vh);

  &:disabled {
    opacity: 0.9;
  }

  span {
    text-overflow: clip !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
}

.input {
  font-size: 1em;
  margin-bottom: 1em;
}
</style>
