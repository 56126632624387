/* eslint-disable no-param-reassign */
import { events } from '@/util'
import { EVENT_RESIZE_END } from '@/constants'

interface Pane {
  component: string
  props?: { [key: string]: unknown }
  id?: number
}

interface PaneElement {
  ref: HTMLDivElement
  flex: number
}

interface PaneControllerConfig {
  pane: Pane
  margin: number // px
  minPaneWidth?: number // px
  maxPanes?: number
  minPanes?: number
  preOpen?: () => Array<object>
}

export default class DynamicPaneController {
  public panes: Array<Pane> = []
  public isReady = false

  protected elements: Map<Pane, PaneElement> = new Map()

  protected observer: MutationObserver
  protected containerElement: HTMLDivElement | null = null

  protected pane: Pane
  protected minPanes: number
  protected maxPanes: number
  protected margin: number
  protected preOpen: Array<object>

  protected resizeTarget: Pane | null = null

  protected containerSize: number | null = null
  protected cursorPosition = { x: 0, y: 0 }
  protected nextId = 0

  constructor(config: PaneControllerConfig) {
    this.pane = config.pane
    this.maxPanes = config.maxPanes || 0
    this.minPanes = config.minPanes !== undefined ? config.minPanes : 1
    this.margin = config.margin
    this.preOpen = config.preOpen ? config.preOpen() : []

    this.observer = new MutationObserver(this.mutationObserverCallback)
  }

  get lastPane() {
    return this.panes[this.panes.length - 1]
  }

  public init = (containerElement: HTMLDivElement) => {
    this.containerElement = containerElement
    this.containerSize = this.containerElement.getBoundingClientRect().width

    this.isReady = true

    if (this.preOpen.length) {
      const additionalProps = this.preOpen.shift() as object
      this.addPane(additionalProps)
    }
  }

  public addPane = (additionalProps?: object) => {
    if (this.maxPanes && this.panes.length === this.maxPanes) return

    // this.onWindowResize();
    this.connectObserver()

    const paneProps = additionalProps
      ? { ...this.pane.props, ...additionalProps }
      : { ...this.pane.props }

    this.nextId += 1

    const pane = {
      ...this.pane,
      id: this.nextId,
      props: paneProps,
    }

    pane.props.close = () => this.closePane(pane)

    this.panes = [...this.panes, pane]
  }

  public closePane = (pane: Pane) => {
    this.connectObserver()

    this.panes = this.panes.filter((p) => p !== pane)
    this.elements.delete(pane)
  }

  public onMouseDown = (event: MouseEvent, pane: Pane) => {
    event.preventDefault()

    this.resizeTarget = pane

    this.setResizeListeners()
    // this.runRequestAnimationFrameLoop();
  }

  public beforeDestroy = () => {
    // events.off(EVENT_RESIZE_END, this.onWindowResize);
  }

  protected onMouseMove = (event: MouseEvent) => {
    event.preventDefault()
    this.cursorPosition.x = event.x
    this.cursorPosition.y = event.y
  }

  protected onMouseUp = () => {
    this.removeResizeListeners()
    this.calculateFlex()
    this.resizeTarget = null
  }

  protected calculateFlex = () => {
    const resizeTargetIndex = this.panes.indexOf(this.resizeTarget!)
    const leftPaneElement = this.elements.get(this.resizeTarget!)!
    const rightPaneElement = this.elements.get(this.panes[resizeTargetIndex + 1])!

    const rect = leftPaneElement.ref.getBoundingClientRect()
    const cx = this.cursorPosition.x
    const direction = rect.right < cx ? 1 : -1
    const distance = direction * Math.abs(rect.right - cx)

    this.elements.forEach((element) => {
      if (element === leftPaneElement) {
        element.ref.style.flex = `${rect.width + distance}`
      } else {
        const r = element.ref.getBoundingClientRect()
        element.ref.style.flex =
          element === rightPaneElement ? `${r.width - distance}` : `${r.width}`
      }
    })

    this.cursorPosition.x = 0
  }

  protected setResizeListeners = () => {
    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  }

  protected removeResizeListeners = () => {
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  protected connectObserver = () => {
    this.observer.observe(this.containerElement as HTMLDivElement, { childList: true })
  }

  protected disconnectObserver = () => {
    this.observer.disconnect()
  }

  protected onPaneNodeAdded = (node: HTMLDivElement) => {
    const element = { ref: node, flex: 1 }
    this.elements.set(this.lastPane, element)

    if (this.preOpen.length > 0) {
      const additionalProps = this.preOpen.shift() as object
      setTimeout(() => this.addPane(additionalProps), 500)
    }
  }

  protected onPaneNodeRemoved = (node: HTMLDivElement) => {
    console.log('pane node removed:', node)
  }

  protected mutationObserverCallback = (mutationList: MutationRecord[]) => {
    mutationList.forEach((record) => {
      const { addedNodes, removedNodes } = record

      if (addedNodes.length > 0) {
        this.onPaneNodeAdded(addedNodes[0] as HTMLDivElement)
      }

      this.disconnectObserver()
    })
  }
}
