<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import BaseButtonBulk from './BaseButtonBulk.vue'

@Component({
  name: 'CancelButtonBulk',
})
export default class CancelButtonBulk extends BaseButtonBulk {
  protected color = 'is-danger'
}
</script>
