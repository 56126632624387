<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h4>Groep verwijderen</h4>
            <p>
              Weet je zeker dat je de volgende groep wilt verwijderen
              <b>{{ manager.groupMarkedForDelete.name }}</b
              >?
            </p>
          </div>
          <div class="buttons is-right">
            <button
              @click="manager.confirmCancel"
              class="button is-dark"
              data-id="group-delete-cancel"
            >
              Annuleren
            </button>
            <button
              @click="manager.deleteGroup"
              :class="`button is-danger ${manager.isLoading && 'is-loading'}`"
              data-id="group-delete-confirm"
            >
              Verwijderen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { GroupCreationScreenManager } from '@/logic/GroupCreationScreen'

@Component({})
export default class GroupDeleteModal extends Vue {
  @Prop() readonly manager!: GroupCreationScreenManager
}
</script>
