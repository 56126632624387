<template>
  <div v-if="paneInfo.loading">Loading...</div>
  <div v-else>
    <inspection-table :manager="manager" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import InspectionTable from './table/InspectionTable.vue'
import BaseTasksPane from './BaseTasksPane.vue'

@Component({
  name: 'InspectionPane',
  components: {
    InspectionTable,
  },
})
export default class InspectionPane extends BaseTasksPane {}
</script>
