interface TimerConfig {
  autoStart?: boolean
  tickFrequency?: number
  iterations?: number
  onTick?: Function
  onTimerEnd?: Function
}

export default class Timer {
  public expired = false
  public expiredTime = 0

  protected autoStart: boolean
  protected frequency: number
  protected iterations: number

  protected executedCycles = 0
  protected stopLoop = false
  protected lastUpdateTick: number | null = null

  protected onTick: Function | null
  protected onTimerEnd: Function | null

  constructor(config: TimerConfig = {}) {
    this.autoStart = !!config.autoStart
    this.frequency = config.tickFrequency || 10
    this.iterations = config.iterations !== undefined ? config.iterations : 1

    this.onTick = config.onTick || null
    this.onTimerEnd = config.onTimerEnd || null

    if (config.autoStart) {
      this.start()
    }
  }

  public start = () => {
    this.loop()
  }

  public stop = () => {
    this.stopLoop = true
    this.reset()
  }

  public restart = () => {
    this.stop()
    this.start()
  }

  public reset = () => {
    this.lastUpdateTick = null
    this.expiredTime = 0
    this.expired = false
    this.stopLoop = false
    this.executedCycles = 0
  }

  protected loop = () => {
    const now = Date.now()
    const deltaTime = this.lastUpdateTick ? now - this.lastUpdateTick : 0

    this.expiredTime += deltaTime

    if (this.expiredTime >= this.frequency) {
      const cycles = Math.floor(this.expiredTime / this.frequency)
      this.executedCycles += cycles

      if (this.onTick) {
        // call onTick for every cycle that passed (could be multiple at once)
        for (let idx = 0; idx < cycles; idx += 1) {
          this.onTick(this.expiredTime)
        }
      }
      if (this.iterations && this.executedCycles >= this.iterations) {
        this.expired = true
        if (this.onTimerEnd) {
          this.onTimerEnd()
        }
        return
      }
      this.expiredTime %= this.frequency
    }

    this.lastUpdateTick = now
    window.requestAnimationFrame(this.loop)
  }
}
