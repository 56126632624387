<template>
  <div class="container">
    <scroll-container maxHeight="75vh" border="1px solid #ccc">
      <table class="table is-fullwidth is-striped is-hoverable is-bordered">
        <thead>
          <tr>
            <th>Naam</th>
            <th>Woco</th>
            <th>Gebruiker</th>
            <th>Apps</th>
            <th>Projecten</th>
            <th>Zichtbaar</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="group in list.filteredItems"
            :key="group.id"
            :class="!canEditGroup(group) && 'has-background-info-light'"
            data-id="group-table"
          >
            <td>{{ group.name }}</td>
            <td>{{ group.woco }}</td>
            <td>{{ group.created_by }}</td>
            <td>{{ group.apps.map((a) => a.name).join(', ') }}</td>
            <td>{{ group.project }}</td>
            <td>{{ group.is_private ? 'Privé' : 'Openbaar' }}</td>
            <td>
              <div
                v-if="canEditGroup(group)"
                class="icon-text icon-button"
                style="flex-wrap: nowrap"
                @click="() => manager.handleEdit(group)"
              >
                <span class="icon" data-id="group-edit">
                  <i class="fas fa-user-edit"></i>
                </span>
                <span :data-id="'group-edit?group=' + group.name">Bewerk</span>
              </div>
            </td>
            <td>
              <div
                v-if="canEditGroup(group)"
                class="icon-text has-text-danger icon-button is-disabled"
                style="flex-wrap: nowrap"
                @click="() => manager.markGroupForDelete(group)"
              >
                <span class="icon has-text-danger" data-id="group-delete">
                  <i class="fas fa-trash-alt"></i>
                </span>
                <span :data-id="'group-delete?group=' + group.name">Verwijder</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { GroupCreationScreenManager } from '@/logic/GroupCreationScreen'
import { actions } from '@/store'
import { hasPermission, actionPermissions } from '@/logic/common/Permissions'
import { ExistingGroup } from '@/interfaces'

@Component({ name: 'GroupTable' })
export default class GroupTable extends Vue {
  @Prop()
  readonly manager!: GroupCreationScreenManager

  get list() {
    return this.manager.groupList
  }

  get user() {
    return actions.auth.readUser()
  }

  protected canEditGroup(group: ExistingGroup) {
    if (!group.is_private) return true
    return hasPermission(actionPermissions.editPrivateGroup, true) || this.user === group.created_by
  }
}
</script>

<style lang="scss" scoped>
table {
  position: relative;

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #000;
    color: #ccc !important;
  }
}

.container {
  max-width: none !important;
}

.icon-button {
  cursor: pointer;

  &:hover {
    filter: brightness(85%);
    text-decoration: underline;
  }
}
</style>
