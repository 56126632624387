<template>
  <div v-if="paneInfo.loading">Loading...</div>
  <div v-else>
    <installation-table :manager="manager" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import InstallationTable from '@/components/TasksScreen/table/InstallationTable.vue'
import BaseTasksPane from './BaseTasksPane.vue'

@Component({
  name: 'InstallationPane',
  components: {
    InstallationTable,
  },
})
export default class InstallationPane extends BaseTasksPane {}
</script>
