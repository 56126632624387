<template>
  <div class="is-relative" ref="container">
    <div v-if="manager.isLoadingDetail">Loading...</div>
    <template v-else>
      <h2
        class="label is-clickable"
        style="margin-bottom: 1rem; margin-left: 0.35rem"
        @click="copyAddress"
      >
        {{ manager.selectedMasterItem.address }}
      </h2>

      <div class="details-pane">
        <!-- CLOSE / RESIZE CONTROLS -->
        <button-delete @click="onCloseDetailsPane" />
        <i
          class="fas fa-ellipsis-v details-slider"
          @mousedown="(event) => slideController.onMouseDown(event, $refs.container)"
        ></i>

        <!-- TASK STATUS BOX -->
        <div v-if="status" class="box status">
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <h3 class="label group-heading">Status</h3>
            <status-label :label="status" />
          </div>
        </div>

        <!-- CALL CENTRE -->
        <details-call-centre-text-box :manager="manager" />

        <!-- TASK SPECIFIC DATA SECTIONS -->
        <div
          v-if="!hasSections()"
          class="mt-5 p-5"
          style="text-align: center; height: 20vh; font-size: 13px"
        >
          <i>No data.</i>
        </div>

        <!-- DESIGN -->
        <details-pane-design v-if="type === taskTypes.TASK_TYPE_DESIGN" :manager="manager" />

        <!-- INSTALLATION -->
        <details-pane-installation
          v-if="type === taskTypes.TASK_TYPE_INSTALLATION"
          :manager="manager"
        />

        <!-- INSPECTION -->
        <details-pane-inspection
          v-if="type === taskTypes.TASK_TYPE_INSPECTION"
          :manager="manager"
        />

        <!-- MAINTENANCE -->
        <details-pane-maintenance
          v-if="type === taskTypes.TASK_TYPE_MAINTENANCE"
          :manager="manager"
        />

        <!-- FINANCE -->
        <details-pane-finance v-if="type === taskTypes.TASK_TYPE_FINANCE" :manager="manager" />

        <!-- SALES -->
        <details-pane-sales v-if="type === taskTypes.TASK_TYPE_SALES" :manager="manager" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { copyToClipboard, notifications } from '@/util'

import { StatusLabel } from '@/components/TasksScreen/actions'
import type TasksScreenManager from '@/logic/TasksScreen/TasksScreenManager'
import { DetailSlideController } from '@/logic/TasksScreen'

import {
  TASK_TYPE_DESIGN,
  TASK_TYPE_INSPECTION,
  TASK_TYPE_SALES,
  TASK_TYPE_INSTALLATION,
  TASK_TYPE_FINANCE,
  TASK_TYPE_CLIENT,
  TASK_TYPE_MAINTENANCE,
} from '@/constants'

import {
  DetailsPaneDesign,
  DetailsPaneInstallation,
  DetailsPaneInspection,
  DetailsPaneSales,
  DetailsPaneFinance,
  DetailsPaneMaintenance,
  DetailsCallCentreTextBox,
} from './details'

import ActionComponent from './ActionComponent.vue'

@Component({
  name: 'DetailsPane',
  components: {
    StatusLabel,
    ActionComponent,
    DetailsPaneDesign,
    DetailsPaneInstallation,
    DetailsPaneInspection,
    DetailsPaneSales,
    DetailsPaneFinance,
    DetailsPaneMaintenance,
    DetailsCallCentreTextBox,
  },
})
export default class DetailsPane extends Vue {
  @Prop() manager!: TasksScreenManager

  protected slideController = new DetailSlideController()

  get status() {
    return this.manager.selectedMasterItem.components.status
      ? this.manager.selectedMasterItem.components.status.status
      : null
  }

  get type() {
    return this.manager.selectedMasterItem.detailTaskType
  }

  get taskTypes() {
    return {
      TASK_TYPE_DESIGN,
      TASK_TYPE_INSTALLATION,
      TASK_TYPE_INSPECTION,
      TASK_TYPE_SALES,
      TASK_TYPE_FINANCE,
      TASK_TYPE_CLIENT,
      TASK_TYPE_MAINTENANCE,
    }
  }

  protected hasSections() {
    return Object.values(this.manager.selectedMasterItem.components).some(
      (component: any) => Object.values(component).length > 1
    )
  }

  protected onCloseDetailsPane() {
    this.manager.selectedMasterItem = null
    this.manager.updateURLQueryParams()
  }

  protected copyAddress() {
    const { address } = this.manager.selectedMasterItem
    copyToClipboard(address, `${address} copied to clipboard`)
  }
}
</script>

<style lang="scss" scoped>
$box-margin: 0 0.35rem 0.7rem;

::v-deep .details-pane {
  flex: 0 0 350px;
  font-size: 0.73rem;
  height: calc(100vh - 11rem);
  overflow-y: scroll;

  > .delete {
    // close button
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(-100%);
  }

  .details-slider {
    position: absolute;
    top: 50%;
    left: -1rem;
    font-size: 1rem;
    transform: translateY(-50%);
    cursor: ew-resize;
  }

  .box.status {
    padding: 0.5rem 1.25rem;

    .tag {
      margin: 0;
    }
  }

  .buttons {
    margin: 0 0 20px 0;
  }

  .nowrap {
    white-space: nowrap;
  }

  .group-heading {
    white-space: nowrap;
  }

  .box {
    margin: $box-margin;
  }

  .is-flex-wrap-wrap > .box,
  .box.status {
    flex: 1;
    flex-shrink: 0;
    margin: $box-margin;

    &:last-child {
      // min-width: calc(50% - 0.7rem);
    }
  }

  .box.status {
    .group-heading {
      margin-bottom: 0;
    }
  }

  .image-preview {
    min-height: 30vh;
  }

  .image-container {
    min-width: 24vh;
  }

  h3 {
    font-size: 1.2em;
  }

  hr {
    margin-top: 0px;
    margin-bottom: 18px;
  }

  &::-webkit-scrollbar {
    width: 0.35rem;
    height: 0.35rem;
    color: #868686;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    cursor: grab;
  }
}
</style>
