var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-w-full tw-mb-2"},[(_vm.type == 'radio')?_c('div',[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('input',{staticClass:"tw-mx-2",attrs:{"type":"radio","name":_vm.name,"id":_setup.id,"disabled":_vm.disabled},domProps:{"value":_vm.radioValue,"checked":_vm.modelValue == _vm.radioValue},on:{"input":function($event){return _vm.$emit('input', _vm.radioValue)},"change":function($event){return _vm.$emit('change', _vm.radioValue)}}}),_c('label',{staticClass:"tw-inline-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold tw-cursor-pointer",attrs:{"for":_setup.id}},[_vm._v(_vm._s(_vm.label))])])]):_vm._e(),(_vm.type == 'checkbox')?_c('div',{staticClass:"tw-flex tw-items-start tw-mt-4"},[_c('input',{staticClass:"tw-bg-gray-200 tw-rounded tw-border tw-focus:border-gray-600 tw-mr-1",attrs:{"type":"checkbox","id":_setup.id,"disabled":_vm.disabled},domProps:{"checked":_vm.value == true},on:{"input":function($event){return _vm.$emit('input', $event.target.checked)}}}),_c('label',{staticClass:"tw-inline-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold tw-cursor-pointer",class:{ 'tw-sr-only': _vm.hideLabel },attrs:{"for":_setup.id}},[_vm._v(_vm._s(_vm.label)+" ")])]):_vm._e(),(_vm.type !== 'checkbox' && _vm.type !== 'radio')?_c('label',{staticClass:"tw-inline-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold tw-mb-2 tw-cursor-pointer",class:{ 'tw-sr-only': _vm.hideLabel },attrs:{"for":_setup.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(
      !_vm.type ||
      _vm.type === 'number' ||
      _vm.type === 'date' ||
      _vm.type === 'time' ||
      _vm.type === 'text' ||
      _vm.type === 'email' ||
      _vm.type === 'file'
    )?_c('input',{staticClass:"tw-block tw-w-full tw-bg-gray-200 tw-rounded tw-text-md tw-text-gray-500 tw-p-2 tw-focus:bg-white tw-border tw-focus:border-gray-600 tw-appearance-none tw-focus:outline-none",attrs:{"type":_vm.type || 'text',"disabled":_vm.disabled,"id":_setup.id,"accept":_vm.accept,"placeholder":_vm.placeholder},domProps:{"value":_vm.value || _vm.modelValue},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change', $event)}}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{staticClass:"tw-w-full tw-bg-gray-200 tw-rounded tw-text-md tw-text-gray-500 tw-p-2 tw-focus:bg-white tw-border tw-focus:border-gray-600 tw-appearance-none tw-focus:outline-none",attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder,"rows":_vm.rows || 1,"id":_setup.id,"maxlength":_vm.maxlength},domProps:{"value":_vm.value || _vm.modelValue},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}):(_vm.type === 'select')?_c('select',{staticClass:"tw-block tw-w-full tw-bg-gray-200 tw-rounded tw-text-md tw-text-gray-500 tw-text-md tw-rounded tw-p-2",class:[_vm.error && 'ring-1 ring-red-500'],attrs:{"disabled":!_vm.options || _vm.disabled,"id":_setup.id,"multiple":_vm.multiple,"size":_vm.size},on:{"input":function($event){_vm.multiple
        ? _vm.$emit(
            'input',
            Array.from($event.target.selectedOptions).map((o) => o.value)
          )
        : _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change')}}},[(_vm.nullable || _vm.nullOption)?_c('option',{domProps:{"value":''}},[_vm._v(" "+_vm._s(_vm.nullOption)+" ")]):_vm._e(),_vm._l((_setup.listOptions),function(option){return _c('option',{key:String(option.value),domProps:{"value":option.value,"selected":_vm.multiple ? _vm.value.includes(option.value) : _vm.value === option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2):(_vm.type == 'multi')?_c(_setup.MultiSelect,{attrs:{"value":_vm.value,"options":_vm.options,"multiple":_vm.multiple,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"mode":_vm.mode,"limit":Number(_vm.limit),"searchable":""},on:{"input":function($event){return _vm.$emit('input', $event)},"search-change":function($event){return _vm.$emit('search-change', $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }