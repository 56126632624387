<template>
  <div class="image-input">
    <hr class="my-1" />
    <div
      @click="onClick"
      class="detail-image"
      style="cursor: pointer; display: flex; flex-direction: column"
      data-id="detail-image"
    >
      <span class="image-name" style="margin: 0 auto; font-size: 1.2em">{{ name }}</span>
      <!-- IMAGE -->
      <figure v-if="hasImageFileExtension(value) || fileSource" class="square-image">
        <img :src="fileSource || value" class="preview-image" />
      </figure>
      <!-- PDF ICON -->
      <i
        v-else-if="getFileExtensionFromURL(value) === 'pdf'"
        class="fa-file-icon fas fa-file-pdf"
      ></i>
      <!-- EXCEL ICON -->
      <i v-else-if="hasTableFileExtension(value)" class="fa-file-icon fas fa-file-excel"></i>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import { getFileExtensionFromURL, hasImageFileExtension, hasTableFileExtension } from '@/util'

import DetailsDataComponentBase from './DetailsDataComponentBase.vue'

@Component({
  name: 'DetailsImagePreview',
})
export default class DetailsImagePreview extends DetailsDataComponentBase {
  protected fileSource: string | null = null

  get name() {
    return this.component.name || this.field
  }

  get value() {
    const { value } = this.component
    return typeof value === 'string' && value.includes('dl=0')
      ? value.replace('dl=0', 'dl=1')
      : value
  }

  protected onClick() {
    this.$emit('click', [this.field, this.component])
  }

  protected getFileExtensionFromURL(source: string) {
    return getFileExtensionFromURL(source)
  }

  protected hasImageFileExtension(source: string) {
    return hasImageFileExtension(source)
  }

  protected hasTableFileExtension(source: string) {
    return hasTableFileExtension(source)
  }

  protected setSourceFromFile(file: File) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.fileSource = reader.result as string
    }
  }

  created() {
    if (this.value !== null && typeof this.value === 'object') {
      this.setSourceFromFile(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .detail-image {
  text-align: center;

  .fa-file-icon {
    font-size: 25vh !important;
  }
}

.image-name {
  max-width: 25vh;
  text-overflow: ellipsis;
  overflow: hidden;
}

.square-image {
  position: relative;
  width: 25vh;
  padding-bottom: 25vh;
  overflow: hidden;
  margin: auto;

  > .preview-image {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 101%;
    min-height: 101%;
    width: auto;
    height: auto;
    max-width: 200%;
    transform: translate(-50%, -50%);
  }
}
</style>
