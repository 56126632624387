<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import request from '@/requests'

import { STATUS_OK } from '@/constants'
import type { MasterItem } from '@/logic/TasksScreen'
import TableHeadActionsBase from './TableHeadActionsBase.vue'
import { RefreshTickets, RejectTickets } from '../actions'

interface SpecialAction {
  key: string
  component: typeof Vue | string
  props: { [key: string]: unknown }
}

@Component({
  name: 'TableHeadActionsInstallation',
  components: { RefreshTickets, RejectTickets },
})
export default class TableHeadActionsInstallation extends TableHeadActionsBase {
  protected async updateSpecialActions() {
    const specialActions = [] as Array<SpecialAction>

    if (!this.selectedItems.length) {
      this.specialActions = specialActions
      return
    }

    const refreshTicketsAction = this.getRefreshTicketsAction()
    if (refreshTicketsAction) specialActions.push(refreshTicketsAction)

    const rejectTicketsAction = this.getRejectTicketsAction()
    if (rejectTicketsAction) specialActions.push(rejectTicketsAction)

    this.specialActions = specialActions
  }

  protected getRefreshTicketsAction() {
    const itemsToRefresh = this.selectedItems.filter((item) => {
      const taskData = item.getTaskData(this.type)
      if (
        !taskData ||
        !taskData.tickets
        // || (taskData.ticket_id as string).includes(',') // TODO: allow multiple tickets
      )
        return false

      const currentStatus = item.getStatus(this.type)
      const validStatusesForRefresh = ['assigned', 'ongoing', 'finished', 'rejected']
      return validStatusesForRefresh.includes(currentStatus!)
    })

    if (itemsToRefresh.length > 0) {
      return {
        key: 'refresh-tickets',
        component: RefreshTickets,
        props: {
          manager: this.manager,
          items: itemsToRefresh,
          type: this.type,
          updateActions: this.updateSpecialActions,
        },
      }
    }

    return null
  }

  protected getRejectTicketsAction() {
    let rejectTypeOptions: Array<string>
    const itemsToReject: Array<{ item: MasterItem; actionURL: string }> = []
    this.selectedItems.forEach((item) => {
      const itemActions = item.getActions(this.type)
      if (itemActions.length === 0) return

      const rejectAction = itemActions.find((actionEntry) => actionEntry.action === 'reject')
      if (!rejectAction) return

      itemsToReject.push({
        item,
        actionURL: rejectAction.url as string,
      })

      if (!rejectTypeOptions) {
        rejectTypeOptions = rejectAction.values as Array<string>
      }
    })

    if (itemsToReject.length !== this.selectedItems.length) return null

    return {
      key: 'reject-tickets',
      component: RejectTickets,
      props: {
        manager: this.manager,
        type: this.type,
        items: itemsToReject,
        rejectTypes: rejectTypeOptions!,
        updateActions: this.updateSpecialActions,
      },
    }
  }
}
</script>
