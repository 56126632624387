<template>
  <header class="has-background-ecorus-green p-4">
    <div class="is-flex is-align-items-center">
      <div class="logo">
        <router-link :to="{ name: screens.home }">
          <figure style="height: 35px">
            <img src="@/assets/EcorusLogo-wit.svg" alt="Logo" style="height: 100%" />
          </figure>
        </router-link>
      </div>
      <app-header-navbar />
      <app-header-login v-if="!isAuthenticated" :auth="auth" />
      <app-header-logout v-else :auth="auth" />
    </div>
  </header>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Authentication } from '@/logic/common'
import { mapState } from 'vuex'

import { SCREEN_HOME } from '@/constants'

import AppHeaderLogin from './AppHeaderLogin.vue'
import AppHeaderLogout from './AppHeaderLogout.vue'
import AppHeaderNavbar from './AppHeaderNavbar.vue'

@Component({
  name: 'AppHeader',
  components: { AppHeaderLogin, AppHeaderLogout, AppHeaderNavbar },
  computed: {
    ...mapState('auth', ['user', 'token', 'permissions']),
  },
})
export default class AppHeader extends Vue {
  private auth = new Authentication(this.$router)

  get isAuthenticated() {
    return this.auth.isAuthenticated()
  }

  get screens() {
    return {
      home: SCREEN_HOME,
    }
  }
}
</script>
