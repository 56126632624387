<template>
  <h3 class="label group-heading">{{ label }}</h3>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'DetailsBoxHeading',
})
export default class DetailsBoxHeading extends Vue {
  @Prop({ default: 'Header' }) label!: string
}
</script>
