<template>
  <div class="is-flex is-align-items-center">
    <dropdown-slotted label="week" min-width="0">
      <template v-slot:trigger>
        <div class="calendar-trigger">
          <span>{{ selectedWeek && selectedWeek.text }}</span>
          <i class="icon far fa-calendar-alt has-text-black ml-1"></i>
        </div>
      </template>
      <week-calendar :selected-week="selectedWeek" @change="onSelectWeek" />
    </dropdown-slotted>
    <button-delete
      v-if="selectedWeek && !hideButton"
      class="mx-1"
      @click="() => onSelectWeek(null)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import type { SelectedWeek } from '@/interfaces'

@Component({
  name: 'WeekPicker',
})
export default class WeekPicker extends Vue {
  @Prop({ default: false }) hideButton!: boolean
  @Prop({ default: null }) selected!: SelectedWeek | null

  protected selectedWeek = this.selected

  protected onSelectWeek(selection: SelectedWeek) {
    this.selectedWeek = selection
    this.$emit('change', selection)
  }
}
</script>

<style lang="scss" scoped>
.calendar-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 7rem;
  font-size: 0.6rem;
  white-space: nowrap;
  padding: 0.1rem 0.3rem;
  border: 1px solid #ccc;

  .icon {
    line-height: 1rem;
    justify-self: flex-end;
    height: auto;
    width: auto;
    font-size: 0.8rem;
  }
}
</style>
