<template>
  <td>
    <div class="scrollable-cell" @click="onClick">
      <slot></slot>
    </div>
  </td>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'TableCell' })
export default class TableCell extends Vue {
  @Prop({ default: 'center' }) flexAlign!: string

  get hasClickListener() {
    return !!this.$listeners.click
  }

  protected onClick(event: MouseEvent) {
    if (this.$listeners.click) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss">
.scrollable-cell {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 1px 2px;
  min-height: 38px;
}

.scrollable-cell::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
  color: #868686; /* Fallback */
}

.scrollable-cell::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollable-cell::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
</style>
