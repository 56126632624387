<template>
  <div
    class="image-field is-flex is-flex-direction-column is-align-items-center mr-1 mb-1"
    @click="() => selectImage(listIndex)"
  >
    <input
      class="input image-field mr-1"
      :id="`image-field-${listIndex}`"
      type="text"
      :value="value"
      ref="fieldInput"
      placeholder="image"
      :title="value"
      :disabled="disabled"
      autocomplete="off"
      @input.prevent="onFieldNameChange"
      @blur="onBlur"
      @keyup.enter="onEnter"
    />
    <figure class="image-thumbnail" :class="[!value && 'disabled']">
      <img
        v-if="fileType === 'image'"
        class="is-clickable"
        :src="fileSource"
        @click="onClickImage"
      />
      <template v-else-if="fileType === 'application'">
        <i
          v-if="fileExt === 'pdf'"
          :key="`${value}-${fileExt}`"
          class="fa-file-icon fas fa-file-pdf"
          :class="typeof data.url === 'string' && 'is-clickable'"
          @click="onClickImage"
        ></i>
        <!-- EXCEL ICON -->
        <i
          v-else
          :key="`${value}-${fileExt}`"
          class="fa-file-icon fas fa-file-excel"
          :class="typeof data.url === 'string' && 'is-clickable'"
          @click="onClickImage"
        ></i>
      </template>
      <label v-else class="file-label">
        <input class="file-input" type="file" :disabled="!value" @input="onFileChange" />
        <span class="file-cta" :class="[isFilePasteTarget && 'target', !this.value && 'disabled']">
          <span class="file-icon" style="margin: 0">
            <i class="fas fa-upload"></i>
          </span>
        </span>
      </label>
    </figure>
    <div class="controls">
      <i
        v-if="fileSource && !disabled"
        class="fas fa-times-circle has-text-grey mr-1 is-clickable"
        @click="clearImage"
        title="Clear image"
      ></i>
      <i
        class="fas fa-minus-circle has-text-grey-darker is-clickable"
        @click="remove"
        title="Remove field"
      ></i>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator'
import { events, hasImageFileExtension, getFileExtensionFromURL } from '@/util'

@Component({
  name: 'DetailsDynamicImageField',
})
export default class DetailsDynamicImageField extends Vue {
  @Prop({}) data!: any
  @Prop({ default: 0 }) listIndex!: number
  @Prop({ default: null }) selectedImageIndex!: number | null
  @Prop({ default: () => ({}) }) config!: any
  @Prop({}) remove!: Function
  @Prop({}) selectImage!: Function

  protected value = this.data.type
  protected fileSource: string | null = null
  protected fileExt = ''
  protected fileType = ''

  get fieldLabel() {
    return this.data.type || 'image'
  }

  get disabled() {
    return !!this.data.url && typeof this.data.url === 'string'
  }

  get isFilePasteTarget() {
    if (
      this.fileSource ||
      this.selectedImageIndex === null ||
      this.selectedImageIndex !== this.listIndex
    )
      return false

    return true
  }

  protected onFieldNameChange(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement
    const convertedValue = target.value
      .replace(/[^\w\d\s]/g, '') // strip non-allowed characters
      .replace(/(\s|_)+/g, '_') // replaces (multiple) spaces / underscores to single underscore
      .toLowerCase()
    this.value = convertedValue.replace(/(^_)/, '') // strip off underscore if first character
    this.$forceUpdate()
  }

  protected onClickImage() {
    if (!this.value || typeof this.data.url !== 'string') return
    events.emit('open-modal', this.value)
  }

  protected clearImage() {
    this.$emit('change', { ...this.data, url: null })
  }

  protected setFileInfo(file: File) {
    const fileNameSplit = file.name.split('.')
    ;[this.fileType] = file.type.split('/')
    this.fileExt = fileNameSplit[fileNameSplit.length - 1]
    this.$forceUpdate()
  }

  protected onFileChange(event: Event) {
    const target = event.target! as HTMLInputElement
    const image = target.files![0]
    const reader = new FileReader()

    reader.readAsDataURL(image)
    reader.onload = () => {
      this.$emit('change', { ...this.data, url: image })
      this.setFileInfo(image)
    }
  }

  protected onImagePaste(event: ClipboardEvent) {
    if (!this.isFilePasteTarget) return

    const { items } = event.clipboardData!
    if (items.length < 1) return

    const file = items[0]
    if (file.type.indexOf('image') === -1) return
    event.preventDefault()

    // check focus, don't add image if focus is on an input; user may've accidentally pasted an image instead of text
    const focussedElement = document.activeElement as HTMLElement
    if (
      focussedElement &&
      (['INPUT', 'TEXTAREA'].includes(focussedElement.tagName) || focussedElement.isContentEditable)
    )
      return

    const blob = file.getAsFile()!
    this.$emit('change', { ...this.data, url: blob })
    this.setFileInfo(blob)
  }

  protected onEnter(event: KeyboardEvent) {
    ;(event.target as HTMLInputElement).blur()
  }

  protected onBlur() {
    this.$emit('change', { ...this.data, type: this.value })
    this.selectImage(this.listIndex)
    this.value = this.data.type
  }

  protected setSourceFromFile(file: File) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.fileSource = reader.result as string
    }
  }

  mounted() {
    if (this.data.url) {
      if (this.data.url instanceof Object) {
        this.setSourceFromFile(this.data.url)
        this.setFileInfo(this.data.url)
      } else {
        this.fileSource = this.data.url
        this.fileExt = getFileExtensionFromURL(this.data.url) as string
        this.fileType = hasImageFileExtension(this.data.url) ? 'image' : 'application'
      }
    }

    document.addEventListener('paste', this.onImagePaste)
  }

  beforeDestroy() {
    document.removeEventListener('paste', this.onImagePaste)
  }
}
</script>

<style lang="scss">
.image-field {
  font-size: 0.7rem;

  .input {
    font-size: 1em;
    max-width: 4rem;
    padding: 0.25rem 0.5rem;
    height: 1rem;
    text-align: center;
  }

  .file-cta {
    &.target:not(.disabled),
    &.target:not(.disabled):hover {
      background-color: #333;
      color: #fff;
    }

    &.disabled,
    &.disabled:hover {
      background-color: #ccc;
      color: #333;
      cursor: not-allowed;
    }
  }

  .fas {
    font-size: 0.8rem;
  }

  .image-thumbnail {
    height: 30px;
    text-align: center;
    margin-top: 0.25rem;

    &.disabled {
      cursor: not-allowed !important;
    }

    img {
      height: 100%;
      width: auto;
    }

    .fa-file-icon {
      font-size: 1.7rem;
    }
  }
}
</style>
