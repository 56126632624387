<template>
  <table-row-task :item="masterItem" :manager="manager" :selected="selected">
    <!-- SELECT ALL -->
    <table-cell>
      <input
        type="checkbox"
        :data-id="'address-select?address=' + masterItem.address"
        :checked="selected"
        @click="(event) => list.onClickItem(masterItem, event)"
      />
    </table-cell>

    <!-- ADDRESSES -->
    <table-cell @click="copyAddress">
      {{ masterItem.address }}
    </table-cell>
  </table-row-task>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { copyToClipboard, notifications } from '@/util'
import TableRowBase from './TableRowBase.vue'

@Component({
  name: 'AddressTableRow',
  components: { TableRowBase },
})
export default class AddressTableRow extends TableRowBase {
  protected copyAddress() {
    copyToClipboard(this.masterItem.address, `${this.masterItem.address} copied to clipboard`)
  }
}
</script>
