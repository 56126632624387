<template>
  <div v-if="building" class="tw-m-2">
    <h1 class="tw-text-lg tw-font-bold tw-mb-3">{{ building.name }}</h1>

    <table class="tw-table-auto tw-border tw-border-collapse tw-border-slate-400">
      <thead>
        <tr>
          <template v-for="_ in floors[0]">
            <th class="tw-border tw-p-2 tw-w-16"></th>
            <th class="tw-border tw-p-2 tw-w-16">Status</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="floor in floors">
          <template v-for="loc in floor">
            <template v-if="!loc">
              <td class="tw-border tw-p-2 tw-w-16"></td>
              <td class="tw-border tw-p-2 tw-w-16"></td>
            </template>
            <template v-else>
              <td
                class="tw-border tw-p-2 tw-w-16"
                :class="{ 'tw-bg-red-200': selected === loc?.location_id }"
              >
                <div :title="`${loc.address.street} ${loc.address.house_number}`">
                  {{ loc.address.house_number }}
                </div>
              </td>
              <td
                v-if="edit"
                class="tw-border tw-p-2 tw-w-16"
                :class="{ 'tw-bg-red-500': selected === loc?.location_id }"
              >
                <button
                  v-if="selected !== loc.location_id"
                  class="tw-text-xs tw-bg-blue-100 tw-p-1 tw-rounded"
                  @click="selected = loc.location_id"
                >
                  Selecteer
                </button>
              </td>
              <td
                v-else
                class="tw-border tw-p-2 tw-w-16 tw-text-xs tw-align-middle"
                :class="statusToColorClass(loc.status)"
              >
                <div :title="formatISO(loc.status.sales.status_at)" v-if="loc.status">
                  {{ loc.status.sales.status }}
                </div>
              </td>
            </template>
          </template>
        </tr>
      </tbody>
    </table>

    <div class="tw-mt-3">
      <button
        class="tw-bg-blue-400 hover:tw-bg-blue-300 tw-rounded tw-p-1"
        @click="edit = true"
        v-if="!edit"
      >
        Bewerken
      </button>
      <button
        v-else
        class="tw-bg-green-400 hover:tw-bg-green-300 tw-rounded tw-p-1"
        @click="saveLayout"
      >
        Opslaan
      </button>
    </div>

    <div v-if="edit" class="tw-flex tw-my-2 tw-gap-2">
      <button @click="switchRows" class="tw-bg-gray-400 hover:tw-bg-gray-300 tw-rounded tw-p-1">
        Keer de verdiepingen om
      </button>
      <button @click="switchColumns" class="tw-bg-gray-400 hover:tw-bg-gray-300 tw-rounded tw-p-1">
        Keer de kolommen om
      </button>
    </div>

    <div class="tw-mt-3" v-if="edit">
      Klik op selecteer en gebruik de pijltjestoetsen op je toetsenbord om de huisnummers te
      verplaatsen
    </div>

    <div v-if="summary" class="tw-mt-3">
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Aantal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tw-w-32 tw-p-1" :class="statuses.approved">approved</td>
            <td>{{ summary.approved }}</td>
          </tr>
          <tr>
            <td class="tw-w-32 tw-p-1" :class="statuses.assigned">assigned</td>
            <td>{{ summary.assigned }}</td>
          </tr>
          <tr>
            <td class="tw-w-32 tw-p-1" :class="statuses.cancelled">cancelled</td>
            <td>{{ summary.cancelled }}</td>
          </tr>
          <tr>
            <td class="tw-w-32 tw-p-1">Totaal</td>
            <td>{{ summary.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { useRoute } from 'vue-router/composables'
import notifications from '@/util/Notifications'
import { computed, ref, watch } from 'vue'
import { onKeyStroke } from '@vueuse/core'
import { parseISO, format } from 'date-fns'

function formatISO(t) {
  if (!t) return ''
  return format(parseISO(t), 'dd-MM-yyyy HH:mm')
}

const queryClient = useQueryClient()
const route = useRoute()

const edit = ref(false)
const selected = ref(null)
const updatedLayout = ref(null)

const layout = computed(() => updatedLayout.value || building.value?.layout)

watch(
  () => edit.value,
  (value) => {
    if (!value) {
      selected.value = null
    }
  }
)

const { data: building } = useQuery({
  queryKey: ['building', route.params.id],
  queryFn: async () => {
    const result = await fetch(
      `/tickets/api/v1/buildings/${route.params.id}?include_locations=true`
    )
    if (!result.ok) {
      notifications.addNotification({
        message: `Could not fetch building ${result.statusText}`,
        type: 'danger',
      })
      throw Exception('Not fetched')
    }

    return (await result.json()).data
  },
})

const floors = computed(() => {
  if (!building.value) return []

  const layout = updatedLayout.value || building.value.layout
  const height = Math.max(...layout.map((column) => column.length))

  let floors = []
  for (let i = 0; i < height; i++) {
    if (!floors[i]) floors[i] = []

    for (let column in layout) {
      if (!layout[column][i]) {
        floors[i].push(null)
        continue
      }

      const loc = building.value.locations.find(
        (location) => location.location_id === layout[column][i]
      )
      floors[i].push(loc)
    }
  }

  return floors
})

const statuses = {
  approved: 'tw-bg-green-100 tw-text-green-600',
  cancelled: 'tw-bg-red-100 tw-text-red-600',
  assigned: 'tw-bg-blue-100 tw-text-blue-600',
}

function statusToColorClass(status) {
  if (!status) return 'tw-bg-gray-200'
  return statuses[status.sales.status]
}

onKeyStroke('ArrowLeft', () => move(selected.value, -1, 0))
onKeyStroke('ArrowRight', () => move(selected.value, 1, 0))
onKeyStroke('ArrowDown', () => move(selected.value, 0, 1))
onKeyStroke('ArrowUp', () => move(selected.value, 0, -1))

function move(locationId, deltaColumn, deltaRow) {
  if (!locationId) return

  const layout = updatedLayout.value || building.value.layout
  let copyOfLayout = [...layout.map((column) => [...column])]

  let currentColumnIndex = layout.findIndex((column) => column.includes(locationId))
  let currentRowIndex = layout[currentColumnIndex].findIndex((location) => location === locationId)

  let columnIndex = currentColumnIndex + deltaColumn
  let rowIndex = currentRowIndex + deltaRow

  if (columnIndex < 0) {
    copyOfLayout.unshift([])
    currentColumnIndex += 1
    columnIndex = 0
  }
  if (columnIndex > copyOfLayout.length - 1) {
    copyOfLayout.push([])
  }
  if (rowIndex < 0) {
    for (let i = 0; i < copyOfLayout.length; i++) {
      copyOfLayout[i].unshift(null)
    }
    currentRowIndex += 1
    rowIndex = 0
  }
  if (rowIndex > copyOfLayout[columnIndex].length - 1) {
    for (let i = 0; i < copyOfLayout.length; i++) {
      copyOfLayout[i].push(null)
    }
  }

  if (copyOfLayout[columnIndex][rowIndex] && !deltaColumn) {
    // movement in the same column: switch locations position
    const locationToMove = copyOfLayout[columnIndex][rowIndex]
    copyOfLayout[columnIndex][rowIndex] = locationId
    copyOfLayout[currentColumnIndex][currentRowIndex] = locationToMove
  } else if (copyOfLayout[columnIndex][rowIndex]) {
    // movement between columns: remove from current position
    copyOfLayout[currentColumnIndex][currentRowIndex] = null

    // movement between columns: put in an empty spot
    if (copyOfLayout[columnIndex].includes(null)) {
      const emptyIndex = copyOfLayout[columnIndex].findIndex((location) => !location)
      copyOfLayout[columnIndex][emptyIndex] = locationId
    }
    // or: add it to the bottom
    else {
      copyOfLayout[columnIndex].push(locationId)
    }
  } else {
    copyOfLayout[columnIndex][rowIndex] = locationId
    copyOfLayout[currentColumnIndex][currentRowIndex] = null
  }

  // cleanup nulls at the end of every column
  for (let column of copyOfLayout) {
    if (column[column.length - 1] === null) {
      column.pop()
    }
  }
  // remove empty column beginning
  if (copyOfLayout[0].length === 0) {
    copyOfLayout.shift()
  }
  // remove empty column end
  if (copyOfLayout[copyOfLayout.length - 1].length === 0) {
    copyOfLayout.pop()
  }
  // remove empty row beginning
  if (copyOfLayout.every((column) => !column[0])) {
    copyOfLayout.forEach((column) => column.shift())
  }
  // remove empty row end
  if (copyOfLayout.every((column) => !column[column.length - 1])) {
    copyOfLayout.forEach((column) => column.pop())
  }

  updatedLayout.value = copyOfLayout
  return copyOfLayout
}

const { mutate: saveLayout } = useMutation({
  mutationFn: async () => {
    const response = await fetch(`/tickets/api/v1/buildings/${route.params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ layout: updatedLayout.value }),
    })

    if (!response.ok) {
      notifications.addNotification({
        message: `Could not save layout: ${response.statusText}`,
        type: 'danger',
      })
      throw Exception('Not saved')
    }

    notifications.addNotification({
      message: 'Layout saved',
      type: 'success',
    })

    const data = await response.json()
    queryClient.setQueryData(['building', route.params.id], (current) => ({
      ...current,
      ...data.data,
    }))
    edit.value = false
    updatedLayout.value = null
  },
})

const summary = computed(() => {
  if (!building.value) return null

  const summary = {
    total: building.value.locations.length,
    approved: building.value.locations.filter((loc) => loc.status.sales.status === 'approved')
      .length,
    assigned: building.value.locations.filter((loc) => loc.status.sales.status === 'assigned')
      .length,
    cancelled: building.value.locations.filter((loc) => loc.status.sales.status === 'cancelled')
      .length,
  }

  return summary
})

function switchRows() {
  updatedLayout.value = layout.value.map((column) => column.toReversed())
}
function switchColumns() {
  updatedLayout.value = layout.value.toReversed()
}
</script>
