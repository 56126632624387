<template>
  <select class="select" @change="onChange">
    <option :selected="selectedPartner === ''" :value="''">Select partner</option>
    <option v-for="partner in partners" :key="partner" :selected="partner === selectedPartner">
      {{ partner }}
    </option>
  </select>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TasksScreenManager } from '@/logic/TasksScreen'
import type MasterItem from '@/logic/TasksScreen/MasterItem'

@Component({})
export default class InstallerSelect extends Vue {
  @Prop({}) action!: any
  @Prop({}) manager!: TasksScreenManager
  @Prop({}) masterItem!: MasterItem
  // @Prop({ default: '' }) selected!: string;

  protected selectedPartner: any = this.assignedPartner || ''

  get partners() {
    return [...this.action.data].sort((a: string, b: string) => a.localeCompare(b))
  }

  get assignedPartner() {
    return this.masterItem.actionData.assignedPartner
  }

  @Watch('assignedPartner')
  onSelectedInstallerChange() {
    this.selectedPartner = this.masterItem.actionData.assignedPartner
  }

  public onChange = (event: InputEvent) => {
    if (!this.masterItem) return
    const target = event.target as HTMLSelectElement
    this.masterItem.setActionData({
      ...this.masterItem.actionData,
      assignedPartner: target.value,
    })
  }
}
</script>

<style lang="scss" scoped>
.select {
  margin-right: 5px;
  font-size: 10px;
  height: 14px !important;
}
</style>
