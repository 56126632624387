<template>
  <div class="box output">
    <details-box-heading label="Output" class="mb-2" />

    <!-- PRODUCTS -->
    <details-box-heading label="Products" class="mb-1" />
    <div class="box-content mb-2">
      <template v-for="[productType, products] in Object.entries(ticketProductsOutput)">
        <div
          v-for="[productCode, productData] in Object.entries(products)"
          :key="`${productType}-${productCode}`"
          class="output-product-entry mb-2"
        >
          <!-- PRODUCT NAME / COUNT -->
          <div class="is-flex is-justify-content-space-between">
            <p>
              <b style="text-transform: capitalize"> {{ productType }}: </b>
              <span>{{ productCode }}</span>
            </p>
            <span class="tag" style="font-size: 0.6rem">{{ productData.amount }}</span>
          </div>

          <!-- PRODUCT SERIAL NUMBERS -->
          <template v-if="hasSerialNumbers(productData)">
            <div class="product-serial-numbers">
              <p><i>Serial numbers:</i></p>
              <p
                v-for="(serialNumber, idx) in productData.serial_numbers"
                :key="`product-${serialNumber}-${idx}`"
              >
                {{ serialNumber }}
              </p>
            </div>
          </template>
        </div>
      </template>
    </div>

    <!-- SERVICES -->
    <details-box-heading label="Services" class="mb-1" />
    <div class="box-content mb-2">
      <template v-for="[service, serviceData] in Object.entries(ticketServicesOutput)">
        <p v-if="!Array.isArray(serviceData)" :key="`service-${service}}`">
          <b>{{ capitalized(service) }}:&nbsp;</b>
          <span>{{ serviceData }}</span>
        </p>

        <template v-else>
          <p :key="`service-${service}`">
            <b>{{ capitalized(service) }}:</b>
          </p>
          <p v-for="serviceWork in serviceData" :key="`work-${serviceWork}`">- {{ serviceWork }}</p>
        </template>
      </template>
    </div>

    <!-- COMMENTS -->
    <details-box-heading label="Remarks" class="mb-1" />
    <div class="box-content mb-2">
      <p v-for="remark in ticketCommentsOutput" :key="`remark-${remark}`">
        {{ remark }}
      </p>
    </div>

    <!-- IMAGES -->
    <details-ticket-images :manager="manager" :images="output.images" />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import DetailsPaneBase from './DetailsPaneBase.vue'
import DetailsTicketImages from './DetailsTicketImages.vue'

@Component({
  name: 'DetailsInstallationOutputBox',
  components: {
    DetailsTicketImages,
  },
})
export default class DetailsInstallationOutputBox extends DetailsPaneBase {
  @Prop({ default: {} }) output!: { [prop: string]: unknown }

  get ticketProductsOutput() {
    return this.output.products || []
  }

  get ticketServicesOutput() {
    return this.output.services || {}
  }

  get ticketCommentsOutput() {
    return this.output.comments || []
  }

  protected capitalized(str: string) {
    return (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ')
  }

  protected hasSerialNumbers(productData: { serial_numbers?: Array<unknown> }) {
    return productData.serial_numbers && productData.serial_numbers.length > 0
  }
}
</script>
