<template>
  <div class="is-flex is-align-items-center">
    <button-outline
      label="Refresh tickets"
      color="is-success"
      data-id="refresh-tickets-selection"
      :loading="isLoading"
      :disabled="isLoading"
      @click="refreshTickets"
    />
    <span class="tag" style="font-size: 0.6rem">{{ items.length }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import request from '@/requests'
import type { MasterItem, TasksScreenManager } from '@/logic/TasksScreen'
import { STATUS_OK } from '@/constants'
import { actions } from '@/store'
import notifications from '@/util/Notifications'

@Component({
  name: 'RefreshTickets',
})
export default class RefreshTickets extends Vue {
  @Prop({}) manager!: TasksScreenManager
  @Prop({}) items!: Array<MasterItem>
  @Prop({}) type!: 'installation' | 'inspection'
  @Prop({}) updateActions!: Function

  protected isLoading = false

  protected async refreshTickets() {
    this.isLoading = true

    const requests = this.items.map((item) =>
      request.tasks.submitTicketRefresh(item.address, this.type)
    )

    const refreshTicketResponses = await Promise.all(requests)

    const itemsSucceeded: Array<MasterItem> = []
    const itemsFailed: Array<MasterItem> = []

    refreshTicketResponses.forEach((response, idx) => {
      const item = this.items[idx]
      const list = response.status === STATUS_OK ? itemsSucceeded : itemsFailed
      list.push(item)
    })

    const beforeUpdateStatuses = itemsSucceeded.map((item) => item.getStatus(this.type))

    // Update the master items that had their tickets successfully refreshed
    await Promise.all(itemsSucceeded.map((item) => this.manager.updateTaskDataForItem(item)))

    const changedItems = itemsSucceeded.filter((item, idx) => {
      const newStatus = item.getStatus(this.type)
      const prevStatus = beforeUpdateStatuses[idx]
      return newStatus !== prevStatus
    })

    // Update details pane if it matches a successfully updated master item
    if (this.manager.selectedMasterItem) {
      const { address, detailTaskType } = this.manager.selectedMasterItem
      const matchingItem = itemsSucceeded.find((item) => item.address === address)
      if (matchingItem && detailTaskType === this.type) {
        this.manager.setSelectedMasterItem(matchingItem, this.type, true)
      }
    }

    this.isLoading = false

    actions.common.commitNotifications([])
    notifications.addNotification({
      message:
        changedItems.length > 0
          ? `${changedItems.length} items have been updated`
          : 'Already up to date',
      type: 'success',
    })
    if (itemsFailed.length > 0) {
      notifications.addNotification({
        message: `${itemsFailed.length} items produced an error`,
        type: 'danger',
      })
    }

    this.updateActions()
  }
}
</script>
