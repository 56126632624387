import store from '@/store'
import { actions } from '@/store'
import { STATUS_UNAUTHORIZED } from '@/store/common'

export default async function fetchData(url, options = {}) {
  const headers = {
    Authorization: `Bearer ${store.getters['auth/getToken']}`,
    'Content-Type': 'application/json',
    ...(options.headers || {}),
  }
  const result = await fetch(url, { ...options, headers })

  if (result.ok) {
    return result.json()
  }
  if (result.status === 401) {
    actions.auth.commitToken('')
    actions.auth.commitPermissions(null)
    actions.common.commitStatus(STATUS_UNAUTHORIZED)

    throw new Error('401 Unauthorized response from API')
  }
  if (result.headers.get('content-type') === 'application/json') {
    throw await result.json()
  }

  console.error('Fetch error', result)
  throw new Error('Invalid response from API')
}

export async function fetchResponse(url, options = {}) {
  const headers = {
    Authorization: `Bearer ${store.getters['auth/getToken']}`,
    'Content-Type': 'application/json',
    ...(options.headers || {}),
  }
  const response = await fetch(url, { ...options, headers })

  if (response.status === 401) {
    actions.auth.commitToken('')
    actions.auth.commitPermissions(null)
    actions.common.commitStatus(STATUS_UNAUTHORIZED)

    throw new Error('401 Unauthorized response from API')
  }

  return response
}
