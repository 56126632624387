import request from '@/requests'
import { BaseScreenManager } from '@/logic/common'
import { actions } from '@/store'
import { Address } from '@/interfaces'
import { downloadExcel } from '@/util'
import VueRouter from 'vue-router'
import Action, { ExistingAction } from './CallCentreAction'
import Dashboard from './CallCentreDashboard'

interface Client {
  client: object
  address: Address
  woco: string
  project: string
  groups: Array<string>
  tasks: object
  fullAddress: string
}

export default class CallCentreScreenManager extends BaseScreenManager {
  public isAddressesLoading = false
  public isAddressLoading = false
  public isActionsLoading = false

  public isInvalidAddress = false

  public isActive = false
  public query = ''

  public selectedAddress: null | Client = null
  public fullAddress = ''
  public selectedStatus = 'Client'

  public actions: Array<Action> = []
  public addresses: Array<string> = []

  public selectedAction: Action | null = null
  public actionWasSelected = false

  public action = new Action()
  public dashboard = new Dashboard()

  private timer: null | number = null
  private minCharactersForQuery = 2

  constructor(router: VueRouter) {
    super(router)
    const address = actions.common.readSelectedCallCentreAddress()
    if (address) this.setSelectedAddress(address)
  }

  get possibleStatuses() {
    if (!this.selectedAddress!.tasks) return ['Client']
    const statuses = [
      'Client',
      'Eleena',
      ...Object.keys(this.selectedAddress!.tasks).map(
        (status) => status.charAt(0).toUpperCase() + status.slice(1)
      ), // capatilizing statuses
      // .filter((status) => status !== 'Inspection'),
    ]

    if (!statuses.includes('Maintenance')) statuses.push('Maintenance')

    return statuses
  }

  public formatDate = (date: Date, isUTC = false) => {
    let day = String(date.getDate())
    let month = String(date.getMonth() + 1)
    const year = date.getFullYear()

    let hour = String(date.getHours())
    let minute = String(date.getMinutes())

    if (isUTC) {
      hour = String(date.getUTCHours())
    }

    if (Number(hour) < 10) hour = `0${hour}`
    if (Number(minute) < 10) minute = `0${minute}`
    if (Number(day) < 10) day = `0${day}`
    if (Number(month) < 10) month = `0${month}`

    return `${day}/${month}/${year} - ${hour}:${minute}`
  }

  public setSelectedAction = (action: Action) => {
    this.actionWasSelected = true
    this.selectedAction = action
  }

  public setQuery = (event: InputEvent) => {
    const { value } = event.target as HTMLInputElement
    this.query = value
    if (this.query.length < this.minCharactersForQuery) {
      this.addresses = []
      return
    }

    if (this.timer) clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      this.setAddresses()
    }, 300)
  }

  public setAddresses = async () => {
    this.isAddressesLoading = true
    this.isActive = true

    if (this.query.length < this.minCharactersForQuery) {
      this.addresses = []
    } else {
      const response = await request.clients.getClientsForQuery(this.query)
      if (response.data) {
        this.addresses = response.data.map((address: any) => address.household.address)
      } else {
        this.addresses = []
      }
    }

    this.isAddressesLoading = false
  }

  public setSelectedAddress = async (value: string) => {
    this.fullAddress = value
    this.addresses = []
    this.query = value
    this.isActive = false
    this.isAddressLoading = true
    this.isInvalidAddress = false
    this.selectedStatus = 'Client'

    actions.common.commitSelectedCallCentreAddress(value)

    const response = await request.data.getDataForClient(value)
    if (response.data && response.data.client) {
      response.data.fullAddress = this.fullAddress
      this.selectedAddress = response.data

      this.setActions(value)
      this.action.address = value
      this.action.woco = this.selectedAddress!.woco
    } else {
      this.isInvalidAddress = true
    }
    this.isAddressLoading = false
  }

  public setActions = async (address: string) => {
    this.isActionsLoading = true

    const response = await request.actions.getActionsForAddress(address)

    if (response.data && response.data.actions) {
      this.actions = response.data.actions.map((action: ExistingAction) => new Action(action))
    }
    this.isActionsLoading = false
  }

  public setSelectedStatus = (status: string) => {
    this.selectedStatus = status
  }

  public exportExcel = () => {
    const items = this.dashboard.actions.filteredItems.map((item) => [
      item.address,
      item.value,
      item.subject,
      item.context,
      item.type,
      this.formatDate(new Date(item.created_at)),
      item.created_by,
      item.is_resolved ? 'Yes' : 'No',
    ])
    items.unshift([
      'Address',
      'Value',
      'Subject',
      'Context',
      'Type',
      'Created at',
      'Created by',
      'Closed',
    ])
    downloadExcel(items, 'Complaints')
  }
}
