<template>
  <div class="m-5">
    <div v-if="status === 'loading'">Loading...</div>

    <partner-order-product-edit-modal
      :order="editOrder"
      @close="editOrder = null"
      @save="saveProducts"
      :loading="mutationLoading"
      :is-editable="isEditable"
    />

    <div v-if="orders">
      <b-table
        :current-page="page"
        :data="orders"
        :loading="loading || isPreviousData || mutationLoading"
        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="(p) => (page = p)"
      >
        <b-table-column field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column field="created_at" label="Aangemaakt" v-slot="props">
          {{ localizeDateTime(props.row.created_at) }}
        </b-table-column>
        <b-table-column field="created_at" label="Bezorgdatum" v-slot="props">
          {{ localizeDateTime(`${props.row.date}T${props.row.time_from}`) }}
        </b-table-column>
        <b-table-column field="partner_name" label="Producten" v-slot="props">
          {{ props.row.products.map((p) => p.amount).reduce((a, b) => a + b, 0) }}
          <b-button class="button" icon-left="eye" size="is-small" @click="openEditOrder(props.row)"
            >Bekijk</b-button
          >
        </b-table-column>
        <b-table-column field="partner_name" label="Partner" v-slot="props">
          {{ props.row.partner_name }}
        </b-table-column>
        <b-table-column label="Status" v-slot="props">
          <b-tag :class="orderToStatusClass(props.row)">{{ orderToLocalStatus(props.row) }}</b-tag>
        </b-table-column>
        <b-table-column label="Status wijzigen" v-slot="props">
          <b-dropdown aria-role="list" v-if="orderToStatus(props.row) === 'created'">
            <template #trigger="{ active }">
              <b-button
                label="Acties"
                type="is-primary"
                :icon-right="active ? 'caret-up' : 'caret-down'"
              />
            </template>

            <b-dropdown-item @click="approveOrder(props.row)" aria-role="listitem"
              >Goedkeuren</b-dropdown-item
            >
            <b-dropdown-item @click="cancelOrder(props.row)" aria-role="listitem"
              >Annuleren</b-dropdown-item
            >
          </b-dropdown>
        </b-table-column>
        <b-table-column label="Details" v-slot="props">
          <router-link :to="{ name: SCREEN_PARTNER_ORDER_DETAILS, params: { id: props.row.id } }">
            Details
          </router-link>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script lang="js">
import { computed, ref } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { notifications } from '@/util'
import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'
import PartnerOrderProductEditModal from '@/components/partner-orders/PartnerOrderProductEditModal.vue'
import fetchData from '@/util/fetch'
import { RouterLink } from 'vue-router'
import { SCREEN_PARTNER_ORDER_DETAILS } from '@/constants'

function orderToStatus(order) {
  if (!order) return null
  const statusses = ['cancelled', 'approved', 'created']
  for (const status of statusses) {
    if (order[`${status}_at`]) return status
  }
  return null
}

function orderToLocalStatus(order) {
  const status = orderToStatus(order)
  return {
    created: 'aangemaakt',
    approved: 'goedgekeurd',
    cancelled: 'geannuleeerd',
  }[status]
}

function orderToStatusClass(order) {
  const status = orderToStatus(order)
  return {
    created: 'is-primary',
    approved: 'is-success',
    cancelled: 'is-danger is-light',
  }[status]
}

function orderToStatusDate(order) {
  return order[`${orderToStatus(order)}_at`]
}

const perPage = 10

export default {
  components: {
    PartnerOrderProductEditModal,
  },
  setup() {
    const page = ref(1)
    const editOrder = ref(null)
    const queryClient = useQueryClient()

    const getPartnerOrders = () =>
      fetchData(
        `/api/v1/tickets/partner-orders?limit=${perPage}&offset=${(page.value - 1) * perPage}`
      )
    const partnerOrders = useQuery({
      queryKey: ['partner-orders', page],
      queryFn: getPartnerOrders,
      keepPreviousData: true,
    })

    const putPartnerOrder = ({ id, payload }) =>
      fetchData(`/api/v1/tickets/partner-orders/${id}`, {
        method: 'put',
        body: JSON.stringify(payload),
      })
    const savePartnerOrder = useMutation({
      mutationFn: putPartnerOrder,
      onSuccess: () => {
        editOrder.value = null
        queryClient.invalidateQueries({ queryKey: ['partner-orders'] })
      },
      onError: (error) => {
        let message = error.details
        if (Array.isArray(error.details)) {
          message = `${error.details[0].loc.join('.')} ${error.details[0].msg}`
        }
        if (!message) {
          message = 'Er ging iets mis tijdens het updaten'
        }

        notifications.addNotification({
          message,
          type: 'danger',
        })
      },
    })

    function saveProducts(order) {
      savePartnerOrder.mutate({
        id: editOrder.value.id,
        payload: { products: order.products },
      })
    }

    function openEditOrder(order) {
      // if cancelled, we don't want to overwrite the original order.products
      editOrder.value = order
    }

    function approveOrder(order) {
      savePartnerOrder.mutate({
        id: order.id,
        payload: { approved_at: new Date().toISOString() },
      })
    }

    function cancelOrder(order) {
      savePartnerOrder.mutate({
        id: order.id,
        payload: { cancelled_at: new Date().toISOString() },
      })
    }

    function localizeDateTime(d) {
      return format(parseISO(d), 'dd LLL yyyy H:mm', { locale: nl })
    }

    return {
      SCREEN_PARTNER_ORDER_DETAILS,
      localizeDateTime,
      isEditable: computed(() => orderToStatus(editOrder.value) === 'created'),
      orderToStatusClass,
      approveOrder,
      cancelOrder,
      openEditOrder,
      loading: computed(() => partnerOrders.value?.status === 'loading'),
      mutationLoading: computed(() => savePartnerOrder.status.value === 'loading'),
      saveProducts,
      editOrder,
      orderToLocalStatus,
      orderToStatus,
      orderToStatusDate,
      page,
      perPage,
      status: partnerOrders.status,
      isPreviousData: partnerOrders.isPreviousData,
      orders: computed(() => partnerOrders.data.value && partnerOrders.data.value.data),
      total: computed(() => partnerOrders.data.value && partnerOrders.data.value.total),
    }
  },
}
</script>
