<template>
  <div v-if="paneInfo.loading">Loading...</div>
  <div v-else>
    <maintenance-table :manager="manager" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import BaseTasksPane from './BaseTasksPane.vue'
import MaintenanceTable from './table/MaintenanceTable.vue'

@Component({
  name: 'MaintenancePane',
  components: {
    MaintenanceTable,
  },
})
export default class MaintenancePane extends BaseTasksPane {}
</script>
