import request from '@/requests'
import { BaseList, BaseFilter, filterOnDate } from '@/logic/common'
import Action, { ExistingAction } from './CallCentreAction'

export interface ActionFilter {
  status: string
  woco: string
  subject: string
  type: string
  created_by: string
  dateSelection: { start: string; end: string }
}

export default class CallCentreDashboard {
  public isLoading = false
  public actions: BaseList<Action>

  public possibleStatuses = ['All', 'Resolved', 'Unresolved']

  constructor() {
    this.actions = this.initializeActions([])
    this.setActions()
  }

  get possibleWocos() {
    return ['All', ...new Set(this.actions.getItems().map((action) => action.woco))].filter(
      (woco) => woco
    )
  }

  get possibleSubjects() {
    return ['All', ...new Set(this.actions.getItems().map((action) => action.subject))].filter(
      (subject) => subject
    )
  }

  get possibleCreatedBy() {
    const users = this.actions.getItems().map((action) => action.created_by)
    const nonEmptyUsers = users.filter(Boolean)
    const uniqueUsers = ['All', ...new Set(nonEmptyUsers.map((email) => email.toLowerCase()))]
    return uniqueUsers.sort()
  }

  public setActions = async () => {
    this.isLoading = true

    const response = await request.actions.getActionsHasFollowUp()

    if (response.data && response.data.actions) {
      this.actions.setItems(
        response.data.actions.map((action: ExistingAction) => new Action(action)).reverse()
      )
    }

    this.isLoading = false
  }

  private initializeActions = (actions: Array<Action>) =>
    new BaseList(actions, {
      filter: new BaseFilter({
        state: {
          status: '',
          woco: '',
          subject: '',
          type: '',
          created_by: '',
          dateSelection: {
            start: '',
            end: '',
          },
        },
        filters: {
          status: this.filterActionStatus,
          woco: this.filterActionWoco,
          subject: this.filterActionSubject,
          type: this.filterActionType,
          date: this.filterActionCreatedAt,
          // Groups the emails of Call Center responder
          created_by: this.filterActionCreatedBy,
        },
      }),
    })

  protected /* ;) */ filterActionCreatedAt = (action: Action, state: ActionFilter) =>
    filterOnDate(action.created_at, state.dateSelection)

  private filterActionStatus = (action: Action, state: ActionFilter) => {
    if (state.status === 'All') return true
    if (!state.status) return true
    if (state.status === 'Resolved') return action.is_resolved
    if (state.status === 'Unresolved') return !action.is_resolved
    return false
  }

  private filterActionWoco = (action: Action, state: ActionFilter) => {
    if (state.woco === 'All') return true
    if (!state.woco) return true
    return action.woco === state.woco
  }

  private filterActionSubject = (action: Action, state: ActionFilter) => {
    if (state.subject === 'All') return true
    if (!state.subject) return true
    return action.subject === state.subject
  }

  private filterActionType = (action: Action, state: ActionFilter) => {
    if (state.type === 'All') return true
    if (!state.type) return true
    return action.type === state.type
  }

  private filterActionCreatedBy = (action: Action, state: ActionFilter) => {
    if (state.created_by === 'All') return true
    if (!state.created_by) return true
    return action.created_by.toLocaleLowerCase() === state.created_by
  }
}
