<template>
  <div class="m-5" v-if="project">
    <div class="mb-5">
      <router-link :to="{ name: SCREEN_PROJECTS }">Terug naar projecten</router-link>
    </div>
    <header>
      <p class="modal-card-title">Projectnaam: {{ project.name }}</p>
    </header>

    <div style="max-width: 800px" class="mt-5">
      <section>
        <div class="columns">
          <div class="column is-one-fifth has-text-grey">Project ID</div>
          <div class="column">
            {{ project.id }}
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">Naam</div>
          <div class="column">
            {{ project.name }}
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            <label for="customer-survey"> Klantonderzoek</label>
          </div>
          <div class="column">
            <input type="checkbox" id="customer-survey" v-model="form.customerSurvey" />
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            <label for="monitoring"> Monitoring</label>
          </div>
          <div class="column">
            <select id="monitoring" v-model="form.monitoring">
              <option
                v-for="option in monitoringOptions"
                :value="option.value"
                :key="option.value"
                :selected="option.value === form.monitoring"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            <label for="enable-c4a"> C4a meters</label>
          </div>
          <div class="column">
            <input type="checkbox" id="enable-c4a" v-model="form.c4aMeters" />
          </div>
        </div>
        <div class="columns is-vcentered">
          <div class="column has-text-grey is-one-fifth">
            <label for="project-installers">Installateurs</label>
          </div>
          <div class="column">
            <Multiselect
              v-model="form.installers"
              :options="installerOptions"
              mode="tags"
              :searchable="true"
              tag-position="top"
              open-direction="top"
              :options-limit="2"
              class="custom-multiselect"
            />
          </div>
        </div>
        <div class="columns is-vcentered">
          <div class="column has-text-grey is-one-fifth">
            <label for="project-installers">Proces</label>
          </div>
          <div class="column">
            <Multiselect
              v-model="form.process"
              :options="processOptions"
              :searchable="true"
              class="custom-multiselect"
            />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <b-button
              label="
Opslaan"
              type="is-primary"
              @click="saveProject"
            />
          </div>
          <div class="column" style="color: red" v-if="errors">
            <div>Errors:</div>
            <template v-if="typeof errors === 'object'">
              <div v-for="(list, field) in errors" :key="field">
                <div v-for="error in list" :key="error">
                  <b>{{ field }}:</b> {{ error }}
                </div>
              </div>
            </template>
            <div v-else>
              {{ errors }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import fetchData from '@/util/fetch'
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { useRoute, useRouter } from 'vue-router/composables'
import { computed, reactive, watch, ref } from 'vue'
import { SCREEN_PROJECTS, monitoringOptions } from '@/constants'

const queryClient = useQueryClient()
const router = useRouter()
const form = reactive({
  installer: null,
  customerSurvey: null,
  c4aMeters: null,
  process: null,
  monitoring: null,
})
const errors = ref(null)
const getPartners = () => fetchData('/api/v1/tickets/partners')
const partners = useQuery({
  queryKey: ['partners'],
  queryFn: getPartners,
})
const installerOptions = computed(() => partners.data.value?.data.map((p) => p.legacy_name))

// Fetch projects and filter the desired project
const getProjects = () => fetchData('/config/api/v1/projects/')
const projects = useQuery({
  queryKey: ['projects'],
  queryFn: getProjects,
})

const getProcesses = () => fetchData('/config/api/v1/project-processes/')
const processes = useQuery({
  queryKey: ['project-processes'],
  queryFn: getProcesses,
})
const processOptions = computed(() =>
  processes.data.value?.data.map((p) => ({
    label: p.name,
    value: p.id,
  }))
)

const route = useRoute()
const project = computed(() =>
  projects.data.value?.data.find((p) => p.id === Number(route.params.id))
)
watch(
  project,
  () => {
    form.installers = project.value?.installers
    form.customerSurvey = project.value?.customer_survey
    form.c4aMeters = project.value?.c4a_meters
    form.process = project.value?.process?.id
    form.monitoring = project.value?.monitoring
  },
  { immediate: true }
)

const putProject = () => {
  const id = route.params.id
  const payload = {
    ...project.value,
    monitoring: form.monitoring,
    installers: form.installers,
    customer_survey: form.customerSurvey,
    c4a_meters: form.c4aMeters,
    process: form.process,
  }

  return fetchData(`/config/api/v1/projects/${id}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  })
}

const { mutate: saveProject } = useMutation({
  mutationFn: putProject,
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['projects'] })
    router.push({ name: SCREEN_PROJECTS })
  },
  onError: (error) => {
    errors.value = error.errors || 'Server error'
  },
})
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
