<template>
  <div class="box output">
    <details-box-heading label="Output" class="mb-2" />

    <!-- EXTRA FIELDS -->
    <details-box-heading label="Extra fields" class="mb-1" />
    <div class="box-content mb-2">
      <p
        v-for="[field, fieldValue] in Object.entries(ticketExtraFieldsOutput)"
        :key="`extra-field-${field}`"
      >
        <b>{{ field.replaceAll('_', ' ') }}: </b>{{ fieldValue }}
      </p>
    </div>

    <!-- SERVICES -->
    <details-box-heading label="Services" class="mb-1" />
    <div class="box-content mb-2">
      <template v-if="ticketAdditionalWorkOutput.length > 0">
        <p><b>Additional work:</b></p>
        <p v-for="serviceWork in ticketAdditionalWorkOutput" :key="`work-${serviceWork}`">
          - {{ serviceWork }}
        </p>
      </template>
    </div>

    <!-- COMMENTS -->
    <details-box-heading label="Remarks" class="mb-1" />
    <div class="box-content mb-2">
      <p v-for="remark in ticketCommentsOutput" :key="`remark-${remark}`">
        {{ remark }}
      </p>
    </div>

    <!-- IMAGES -->
    <details-ticket-images :manager="manager" :images="output.images" />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import DetailsPaneBase from './DetailsPaneBase.vue'
import DetailsTicketImages from './DetailsTicketImages.vue'

interface TicketServices {
  additional_work: unknown
}

@Component({
  name: 'DetailsInspectionOutputBox',
  components: {
    DetailsTicketImages,
  },
})
export default class DetailsInspectionOutputBox extends DetailsPaneBase {
  @Prop({ default: {} }) output!: { [prop: string]: unknown }

  get ticketExtraFieldsOutput() {
    return this.output.extra_fields || {}
  }

  get ticketServicesOutput() {
    return this.output.services || {}
  }

  get ticketAdditionalWorkOutput() {
    const services = this.output.services as TicketServices
    return services ? services.additional_work || [] : []
  }

  get ticketCommentsOutput() {
    return this.output.comments || []
  }

  protected capitalized(str: string) {
    return (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ')
  }
}
</script>
