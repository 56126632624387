import { render, staticRenderFns } from "./CallCentreEleenaData.vue?vue&type=template&id=14783c52&scoped=true"
import script from "./CallCentreEleenaData.vue?vue&type=script&lang=ts"
export * from "./CallCentreEleenaData.vue?vue&type=script&lang=ts"
import style0 from "./CallCentreEleenaData.vue?vue&type=style&index=0&id=14783c52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14783c52",
  null
  
)

export default component.exports