<template>
  <div class="box tickets">
    <details-box-heading label="Tickets" />
    <!-- TICKET SELECTION / REFRESH -->
    <div class="is-flex is-align-items-center">
      <template v-if="selectedTicket">
        <span style="margin-right: 0.25rem">ID:</span>

        <span v-if="!hasMultipleTickets" class="tag" style="margin-right: 0.25rem">
          {{ selectedTicket.id }}
        </span>
        <select class="tw-border" v-else @change="onTicketSelectionChange">
          <option
            v-for="id in tickets.map((ticket) => ticket.id)"
            :key="`ticket-${id}`"
            :selected="selectedTicket && selectedTicket.id === id"
          >
            {{ id }}
          </option>
        </select>
        <i
          class="fas fa-copy is-clickable copy-to-clipboard"
          title="Copy ticket ID"
          @click="
            () =>
              copyToClipboard(
                selectedTicket.id,
                `Ticket ID ${selectedTicket.id} copied to clipboard`
              )
          "
        ></i>

        <details-ticket-button
          v-if="hasRefreshTicketButton"
          class="ml-1"
          field="refresh_ticket_button"
          :component="this.components.ticket_info.refresh_ticket_button"
          @click="refreshDetails"
        />
        <span style="margin-left: 1rem; font-size: 0.65rem; font-style: italic">
          Last refresh: {{ taskLastRefresh }}
        </span>
      </template>
      <template v-if="['maintenance'].includes(type)">
        <div class="button-input">
          <button
            class="button thin-button is-small is-info m-1"
            @click="() => $emit('new-ticket', null)"
          >
            New Ticket
          </button>
        </div>
        <div
          v-if="
            hasTicket &&
            !selectedTicket.error &&
            ['created', 'planned'].includes(selectedTicket.ticket.current_status)
          "
          class="button-input"
        >
          <button
            class="button thin-button is-small is-info m-1"
            @click="() => $emit('change-installer', null)"
          >
            Change installer
          </button>
        </div>
      </template>
    </div>

    <template v-if="hasTicket && !selectedTicket.error">
      <details-static-data-field
        class="my-1"
        field="Created"
        :value="formatDate(ticketCreatedAt)"
      />
      <details-static-data-field
        class="my-1"
        field="Installer"
        :value="selectedTicket.ticket.partner"
      />

      <details-static-data-field class="my-1" field="Status">
        <status-label
          :label="selectedTicket.ticket.current_status.replaceAll('_', ' ')"
          style="margin: 0"
        />
      </details-static-data-field>
      <details-static-data-field
        class="my-1"
        field="Remarks"
        v-if="currentStatusRemark"
        :value="currentStatusRemark"
        :wrap="true"
      />
    </template>

    <!-- TICKET ERROR -->
    <p v-else-if="hasTicket" class="mt-2">
      <i class="fas fa-exclamation-triangle has-text-danger"></i> {{ selectedTicket.error }}
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import { copyToClipboard, formatDateTime, isDateTimeField } from '@/util'
import DetailsPaneBase from './DetailsPaneBase.vue'

interface Ticket {
  id: string
  actions: { [prop: string]: unknown }
  ticket: { [prop: string]: unknown }
  error?: unknown
}

@Component({
  name: 'DetailsTicketBox',
  methods: {
    copyToClipboard,
  },
})
export default class DetailsTicketBox extends DetailsPaneBase {
  @Prop({ default: [] }) tickets!: Array<Ticket>
  @Prop({}) selected!: number

  get hasTicket() {
    return this.tickets.length > 0
  }

  get selectedTicket() {
    if (this.selected === null) return null

    return this.tickets[this.selected]
  }

  get currentStatusRemark() {
    if (!this.selectedTicket || this.selectedTicket.error) {
      return null
    }
    const ticketStatusList = this.selectedTicket.ticket.status as Array<{
      [prop: string]: unknown
    }>
    if (!Array.isArray(ticketStatusList)) return null

    return ticketStatusList[ticketStatusList.length - 1].comment
  }

  get taskLastRefresh() {
    const lastRefresh = this.selectedMasterItem.getTaskData(this.type).last_refresh
    return lastRefresh || ''
  }

  get hasMultipleTickets() {
    return this.tickets.length > 1
  }

  get hasRefreshTicketButton() {
    if (!this.components.ticket_info) return false

    return !!this.components.ticket_info.refresh_ticket_button
  }

  get ticketCreatedAt() {
    if (!this.selectedTicket || this.selectedTicket.error) return null

    const ticketStatusList = this.selectedTicket.ticket.status as Array<{
      [prop: string]: unknown
    }>
    if (!Array.isArray(ticketStatusList)) return 'N/A'

    const createdStatus = ticketStatusList.find((status) =>
      Object.keys(status).some((key) => key.match(/created/))
    )

    if (!createdStatus) return 'N/A'
    return createdStatus.created_at || 'N/A'
  }

  protected formatDate(input: string) {
    return isDateTimeField(input) ? formatDateTime(input) : input
  }

  protected onTicketSelectionChange(event: InputEvent) {
    const target = event.target as HTMLInputElement
    const index = this.tickets.findIndex((ticket: { id: string }) => target.value === ticket.id)

    if (index === -1) return

    this.$emit('change', index)
  }
}
</script>
