import request from '@/requests'
import { actions } from '@/store'
import { hasPermission } from '@/logic/common/Permissions'
import { STATUS_FORBIDDEN, STATUS_ALLOWED, STATUS_UNAUTHORIZED } from '@/constants'

import type { Permissions } from '@/interfaces'
import type VueRouter from 'vue-router'

export default class Authentication {
  protected router: VueRouter

  constructor(router: VueRouter) {
    this.router = router
  }

  public isAuthenticated = () => {
    const token = actions.auth.readToken()
    const user = actions.auth.readUser()
    const permissions = actions.auth.readPermissions()
    return !!(user && token && permissions)
  }

  public loginWithOTP = async (email: string, otp: string) => {
    const response = await request.auth.submitOTP(otp, email)
    if (response.status === 'OK') {
      this.onLoginSuccess(email, response.data.access_token)
    }
    return response
  }

  public requestOTP = async (email: string) => {
    const response = await request.auth.requestOTP(email)
    return response
  }

  public getPermissionsFromToken = async (token: string): Promise<Permissions | null> => {
    const permissionsResponse = await request.auth.getPermissions(token)
    const permissions = permissionsResponse?.data?.permissions
    return permissions || null
  }

  public logout = () => {
    actions.auth.commitToken('')
    actions.auth.commitPermissions(null)

    this.updateStatus()
  }

  public updateStatus = () => {
    const userPermissions = actions.auth.readPermissions()
    if (!userPermissions) {
      return actions.common.commitStatus(STATUS_UNAUTHORIZED)
    }

    const route = this.router.currentRoute
    const routePermissions = route.meta!.permissions

    if (!routePermissions || hasPermission(routePermissions)) {
      return actions.common.commitStatus(STATUS_ALLOWED)
    }

    return actions.common.commitStatus(STATUS_FORBIDDEN)
  }

  protected onLoginSuccess = async (username: string, token: string) => {
    const permissions = await this.getPermissionsFromToken(token)
    if (!permissions) {
      console.error('PERMISSIONS: Failed to parse token')
      return
    }
    actions.auth.commitUser(username)
    actions.auth.commitToken(token)
    actions.auth.commitPermissions(permissions)

    this.updateStatus()
  }
}
