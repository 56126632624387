<template>
  <section class="section">
    <div class="container">
      <h1 class="is-size-2 has-text-centered">401 - Unauthorized</h1>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Unauthorized',
})
export default class Unauthorized extends Vue {}
</script>
