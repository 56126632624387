<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import request from '@/requests'

import { CopyDetailsDesign } from '@/components/TasksScreen/actions'
import { STATUS_OK } from '@/constants'
import TableHeadActionsBase from './TableHeadActionsBase.vue'

interface SpecialAction {
  key: string
  component: typeof Vue | string
  props: { [key: string]: unknown }
}

@Component({
  name: 'TableHeadActionsDesign',
  components: { CopyDetailsDesign },
})
export default class TableHeadActionsDesign extends TableHeadActionsBase {
  protected async updateSpecialActions() {
    const specialActions = [] as Array<SpecialAction>

    if (!this.selectedMasterItem || !this.selectedItems.length) {
      this.specialActions = specialActions
      return
    }

    const copyDetailsAction = await this.getCopyDetailsAction()
    if (copyDetailsAction) {
      specialActions.push(copyDetailsAction)
    }

    this.specialActions = specialActions
  }

  protected async getCopyDetailsAction() {
    const allowedCopySource = ['approved', 'finished', 'ongoing']
    const allowedCopyDestination = ['ongoing', 'finished']

    const sourceStatus = this.selectedMasterItem.getStatus(this.type) || ''

    const hasValidCopySource = allowedCopySource.includes(sourceStatus)
    const hasValidCopyDestinations = this.selectedItems.every(
      (item) =>
        allowedCopyDestination.includes(item.getStatus(this.type) || '') &&
        item.address !== this.selectedMasterItem.address
    )

    if (!hasValidCopySource || !hasValidCopyDestinations) {
      return null
    }

    const source: string = this.manager.selectedMasterItem.address
    const copyDesignResponse = await request.tasks.getCopyDesign(source)
    if (copyDesignResponse.status !== STATUS_OK) {
      return null
    }

    return {
      key: 'copy-details-design',
      component: CopyDetailsDesign,
      props: {
        manager: this.manager,
        fieldsData: copyDesignResponse.data,
      },
    }
  }
}
</script>
