<template>
  <div class="box output">
    <details-box-heading label="Output" class="mb-2" />

    <!-- EXTRA FIELDS -->
    <details-box-heading label="Extra fields" class="mb-1" />
    <div class="box-content mb-2">
      <p
        v-for="[field, fieldValue] in Object.entries(ticketExtraFieldsOutput)"
        :key="`extra-field-${field}`"
      >
        <b>{{ capitalized(field) }}: </b>{{ fieldValue }}
      </p>
    </div>

    <!-- COMMENTS -->
    <details-box-heading label="Comments" class="mb-1" />
    <div class="box-content mb-2">
      <p v-for="comment in ticketCommentsOutput" :key="`comment-${comment}`">
        {{ comment }}
      </p>
    </div>

    <!-- PRODUCTS -->
    <details-box-heading label="Consumed products" class="mb-1" />
    <div class="box-content mb-2">
      <template v-for="[productType, products] in Object.entries(ticketProductsOutput)">
        <div
          v-for="[productCode, productData] in Object.entries(products)"
          :key="`${productType}-${productCode}`"
          class="output-product-entry mb-2"
        >
          <!-- PRODUCT NAME / COUNT -->
          <div class="is-flex is-justify-content-space-between">
            <p>
              <b style="text-transform: capitalize"> {{ productType }}: </b>
              <span>{{ productCode }}</span>
            </p>
            <span class="tag" style="font-size: 0.6rem">{{ productData.amount }}</span>
          </div>

          <!-- PRODUCT SERIAL NUMBERS -->
          <template v-if="hasSerialNumbers(productData)">
            <div class="product-serial-numbers">
              <p><i>Serial numbers:</i></p>
              <p
                v-for="(serialNumber, idx) in productData.serial_numbers"
                :key="`product-${serialNumber}-${idx}`"
              >
                {{ serialNumber }}
              </p>
            </div>
          </template>
        </div>
      </template>
    </div>

    <!-- IMAGES -->
    <details-ticket-images :manager="manager" :images="output.images" />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import DetailsPaneBase from './DetailsPaneBase.vue'
import DetailsTicketImages from './DetailsTicketImages.vue'

interface TicketServices {
  additional_work: unknown
}

@Component({
  name: 'DetailsMaintenanceOutputBox',
  components: {
    DetailsTicketImages,
  },
})
export default class DetailsMaintenanceOutputBox extends DetailsPaneBase {
  @Prop({ default: {} }) output!: { [prop: string]: unknown }

  // TODO: change ticket data structure to group these fields together
  get ticketExtraFieldsOutput() {
    const accumulator: { [prop: string]: unknown } = {}
    const ignoreFields = ['images', 'products', 'services']

    return Object.entries(this.output).reduce((acc, [k, v]) => {
      if (ignoreFields.includes(k) || !v) return acc
      return { ...acc, [k]: v }
    }, accumulator)
  }

  get ticketProductsOutput() {
    return this.output.products || []
  }

  get ticketAdditionalWorkOutput() {
    const services = this.output.services as TicketServices
    return services ? services.additional_work || [] : []
  }

  get ticketCommentsOutput() {
    return this.output.comments || []
  }

  protected capitalized(str: string) {
    return (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ')
  }

  protected hasSerialNumbers(productData: { serial_numbers?: Array<unknown> }) {
    return productData.serial_numbers && productData.serial_numbers.length > 0
  }
}
</script>
