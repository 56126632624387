<template>
  <div class="is-flex is-align-items-center">
    <!-- FILTER -->
    <master-data-filter :manager="manager" type="design" />
    <table-head-data-design-filter :manager="manager" />

    <!-- DATA TOTALS -->
    <template v-for="[field, value] in Object.entries(totals)">
      <!-- NESTED DATA -->
      <template v-if="typeof value === 'object'">
        <dropdown-slotted :key="`${field}-${value}`" min-width="0">
          <template v-slot:trigger>
            <data-box class="mr-1">
              <span class="data-box-value">{{ field }}: {{ value.total }}</span>
              <i class="fas fa-angle-down has-text-white ml-1"></i>
            </data-box>
          </template>

          <template>
            <data-box
              v-for="[type, val] in orderFieldsByCount(value.fields)"
              :key="`${type}-${val}`"
              class="has-text-black my-1"
            >
              <span class="data-box-value">{{ type }}: {{ val }}</span>
            </data-box>
          </template>
        </dropdown-slotted>
      </template>

      <!-- FLAT DATA -->
      <data-box v-else :key="`${field}-${value}`" class="mr-1">
        <span class="data-box-value">{{ field }}: {{ value }}</span>
      </data-box>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import { TASK_TYPE_DESIGN } from '@/constants'
import type { TasksScreenManager } from '@/logic/TasksScreen'

import TableHeadDataDesignFilter from './TableHeadDataDesignFilter.vue'
import MasterDataFilter from './MasterDataFilter.vue'

@Component({
  name: 'TableHeadDataDesign',
  components: {
    TableHeadDataDesignFilter,
    MasterDataFilter,
  },
})
export default class TableHeadDataDesign extends Vue {
  @Prop() manager!: TasksScreenManager

  get totals() {
    const dataFields = ['panels', 'inverters', 'smartmeters']
    const data = this.manager.generateTaskDataStatistics(TASK_TYPE_DESIGN, dataFields)
    return data
  }

  protected orderFieldsByCount(fields: { [field: string]: number }) {
    return Object.entries(fields).sort((a, b) => {
      const valueA = a[1]
      const valueB = b[1]
      return valueB - valueA
    })
  }
}
</script>
