<template>
  <div class="tw-m-2">
    <div>
      <router-link class="nav-link mx-2 has-text-weight-semibold" :to="{ name: 'new-building' }">
        Gebouw aanmaken
      </router-link>
    </div>
    <div>
      <div v-if="isLoading">Laden...</div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Naam</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="building in buildings">
            <td class="tw-p-1">{{ building.id }}</td>
            <td class="tw-p-1">
              <router-link :to="{ name: 'building-details', params: { id: building.id } }">{{
                building.name
              }}</router-link>
            </td>
          </tr>
        </tbody>
      </table>

      <button @click="fetchNextPage" v-if="hasNextPage">Meer...</button>
    </div>
  </div>
</template>

<script setup>
import { useInfiniteQuery } from '@tanstack/vue-query'
import notifications from '@/util/Notifications'
import { computed } from 'vue'
import router from '@/router'

const perPage = 30

const getBuildings = async ({ pageParam = 1 }) => {
  const urlParams = new URLSearchParams({
    offset: (pageParam - 1) * perPage,
    limit: perPage,
  })

  try {
    const result = await fetch(`/tickets/api/v1/buildings?${urlParams}`)
    if (!result.ok) {
      notifications.addNotification({
        message: `Failed to get list of buildings: ${result.statusText}`,
        type: 'danger',
      })
      return
    }

    return await result.json()
  } catch (error) {
    notifications.addNotification({
      message: `Failed to get list of buildings, ${e.message || e.detail}`,
      type: 'danger',
    })
    throw new Error('Failed to get list of buildings with error: ' + error.message)
  }
}

const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
  queryKey: computed(() => ['buildings']),
  keepPreviousData: true,
  queryFn: getBuildings,
  getNextPageParam: (_, allPages) =>
    allPages[allPages.length - 1].data?.length === perPage ? allPages.length + 1 : undefined,
})

const buildings = computed(() => data.value?.pages.flatMap((page) => page.data) || [])
</script>
