<template>
  <button
    class="button is-clickable"
    :data-id="dataId"
    :class="[colorClass, size, spacing, loading]"
    @click="$emit('click', $event)"
  >
    {{ label }}
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'ButtonSmall' })
export default class ButtonSmall extends Vue {
  @Prop({ default: 'Button' }) label!: string
  @Prop({ default: '' }) color!: string
  @Prop({ default: '' }) align!: string
  @Prop({ default: '' }) spacing!: string
  @Prop({ default: false }) isLoading!: boolean
  @Prop({}) dataId!: string
  size = 'is-small'

  loading = this.isLoading ? 'is-loading' : ''
  colorClass = `is-${this.color}`
}
</script>
