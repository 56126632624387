<template>
  <tr class="tw-m-2">
    <td class="tw-align-middle tw-p-2" v-if="filteredTypeOptions?.length === 1">
      <input type="checkbox" v-model="selectedAddresses" :value="address.id" class="tw-mx-auto" />
    </td>
    <td class="tw-align-middle">
      <router-link
        :to="{
          name: 'task-detail',
          params: { address: address.address, type: 'address' },
        }"
        class="tw-text-gray-800 hover:tw-underline"
      >
        {{ address.address }}
      </router-link>
    </td>
    <td v-for="typeOption in filteredTypeOptions" :key="typeOption" class="tw-align-middle">
      <div
        class="tooltip-wrapper"
        @mouseover="showTooltip = true"
        @mouseleave="showTooltip = false"
      >
        <router-link
          v-if="address[typeOption + '_status']"
          :to="{
            name: 'task-detail',
            params: { address: address.address, type: typeOption },
          }"
        >
          <div
            class="tw-rounded-lg tw-inline tw-p-1 tw-text-white tw-cursor-pointer"
            :class="getStatusClass(address[typeOption + '_status'])"
          >
            {{ address[typeOption + '_status'] }}
          </div>
        </router-link>
        <!-- <div v-if="showTooltip" class="tooltip">SOME LONG TEXT HERE</div> -->
      </div>
    </td>
    <td
      v-if="filteredTypeOptions?.length === 1 && address.actions"
      class="tw-align-middle tw-flex tw-p-2"
    >
      <div
        v-for="(option, index) in address.actions[address[filteredTypeOptions + '_status']]"
        :key="index"
        class="tw-flex tw-items-center"
      >
        <select
          v-if="option.name === 'Assign'"
          :key="address.adress_id"
          v-model="selectedInstaller"
          class="tw-border tw-border-2 tw-border-gray-300 tw-rounded-lg tw-p-1 tw-mr-3"
        >
          <option value="" disabled>Select installer</option>
          <option v-for="installer in availableInstallers(address.project_id)" :value="installer">
            {{ installer }}
          </option>
        </select>
        <p
          v-if="option.name === 'Cancel' || option.name === 'Assign'"
          class="tw-rounded-lg tw-inline tw-px-1 tw-border-2 tw-border-red-500 tw-cursor-pointer tw-mr-3 cancel tw-flex tw-items-center"
          :class="option.name === 'Cancel' && 'hover:tw-bg-red-500'"
          @click="
            option.name === 'Cancel'
              ? showConfirmationModal()
              : handleAction(option.action, address.address, filteredTypeOptions)
          "
        >
          {{ option.name }}
        </p>
        <confirmation-modal
          v-if="showModal"
          :action="option.action"
          @confirm="handleAction(option.action, address.address, filteredTypeOptions)"
          @cancel="hideConfirmationModal"
        />
      </div>
    </td>

    <notification-message :message="notification.message" :type="notification.type" />
  </tr>
</template>

<script setup>
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { ref } from 'vue'
import { RouterLink } from 'vue-router'
import ConfirmationModal from './ConfirmationModal.vue'
import { getStatusClass } from './utils.js'
import fetchData from '@/util/fetch'
import store from '@/store'
import NotificationMessage from './NotificationMessage.vue'

const props = defineProps({
  address: {
    type: Object,
    required: true,
  },
  filteredTypeOptions: {
    type: Array,
    required: true,
  },
})

const queryClient = useQueryClient()
const showModal = ref(false)
const selectedAddresses = ref([])
const selectedInstaller = ref('')
const notification = ref({ message: '', type: '' })
const showTooltip = ref(false)

const showConfirmationModal = () => {
  showModal.value = true
}

const hideConfirmationModal = () => {
  showModal.value = false
}

const getProjects = () => fetchData('/config/api/v1/projects/?view=full')
const projectsQuery = useQuery({
  queryKey: ['projects'],
  queryFn: getProjects,
})

const availableInstallers = (id) =>
  projectsQuery.data.value?.data.find((p) => p.id === id)?.installers

const handleAction = async (action, address, type) => {
  hideConfirmationModal()
  if (action === 'assign' && !selectedInstaller.value) {
    notification.value = { message: 'Please select an installer', type: 'error' }
    setTimeout(() => {
      notification.value = { message: '', type: '' }
    }, 3000)
    return
  }
  try {
    const url_action = `api/v1/address/${address}/task_type/${type}/action/${action}`
    const response = await fetch(url_action, {
      method: 'PUT',
      body: JSON.stringify(
        selectedInstaller.value ? { assignedInstaller: selectedInstaller.value } : {}
      ),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters['auth/getToken']}`,
      },
    })
    if (response.status === 200) {
      queryClient.invalidateQueries({ queryKey: ['addresses'] })
      selectedInstaller.value = ''
      notification.value = {
        message: `Action '${action}' for address '${address}' and type '${type} is seccussfully performed`,
        type: 'success',
      }
      setTimeout(() => {
        notification.value = { message: '', type: '' }
      }, 5000)
    } else {
      notification.value = { message: 'Failed to perform action', type: 'error' }
      setTimeout(() => {
        notification.value = { message: '', type: '' }
      }, 3000)
      console.error(
        `Failed to perform action '${action}' for address '${address}' and type '${type}'.`
      )
    }
  } catch (error) {
    console.error('An error occurred:', error)
  }
}
</script>
