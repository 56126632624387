<template>
  <div class="is-flex is-align-items-center">
    <master-data-filter :manager="manager" type="finance" />
    <!-- DATA TOTALS -->
    <data-box
      v-for="[field, value] in Object.entries(totals)"
      :key="`${field}-${value}`"
      class="mr-1"
    >
      <span class="data-box-value"> {{ formatField(field) }}: {{ value.toFixed(2) }} </span>
    </data-box>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { TASK_TYPE_FINANCE } from '@/constants'
import type { TasksScreenManager } from '@/logic/TasksScreen'
import MasterDataFilter from './MasterDataFilter.vue'

@Component({
  name: 'TableHeadDataFinance',
  components: {
    MasterDataFilter,
  },
})
export default class TableHeadDataFinance extends Vue {
  @Prop() manager!: TasksScreenManager

  get totals() {
    const dataFields = ['total_sell_price']
    const data = this.manager.generateTaskDataStatistics(TASK_TYPE_FINANCE, dataFields)

    if (Object.keys(data).length < 1) return data

    const totalPurchasePrice = this.manager.list.filteredItems.reduce((acc, item) => {
      const extraData = item.getTaskExtraData(TASK_TYPE_FINANCE)

      return typeof extraData.total_purchase_price === 'number'
        ? acc + extraData.total_purchase_price
        : acc
    }, 0)

    const totalMargin = (data.total_sell_price as number) - totalPurchasePrice
    const totalMarginPercentage = this.calculatePercentage(
      totalMargin,
      data.total_sell_price as number
    )

    return { ...data, total_margin_percentage: totalMarginPercentage }
  }

  protected translateField(field: string) {
    const translationMap: { [field: string]: string } = {
      total_margin_percentage: 'total_margin_%',
    }

    return translationMap[field] || field
  }

  protected formatField(field: string) {
    const f = this.translateField(field)
    return f.replaceAll('_', ' ')
  }

  protected calculatePercentage(numerator: number, denominator: number) {
    if (numerator === 0) return 0
    return (numerator / denominator) * 100
  }
}
</script>
