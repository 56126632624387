<template>
  <div v-if="manager.isLoading">Verwerken...</div>
  <div v-else>
    <client-table :manager="manager" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import BaseTasksPane from './BaseTasksPane.vue'
import ClientTable from './table/ClientTable.vue'

@Component({
  name: 'ClientPane',
  components: {
    ClientTable,
  },
})
export default class ClientPane extends BaseTasksPane {}
</script>
