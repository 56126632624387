import { CustomResponse, Notification } from '@/interfaces'
import { actions } from '@/store'
import { STATUS_OK, STATUS_ERROR, STATUS_WARNING } from '@/constants'

export class Notifications {
  public addNotification = (notification: Notification) => {
    const notifications = [...actions.common.readNotifications(), notification]
    actions.common.commitNotifications(notifications)
  }

  public removeNotification = (notification: Notification) => {
    const notifications = actions.common.readNotifications().filter((n) => n !== notification)
    actions.common.commitNotifications(notifications)
  }

  public setNotificationFromResponse = (response: CustomResponse) => {
    if (response.status === STATUS_OK) {
      actions.common.commitNotifications([
        {
          message: response.message,
          type: 'success',
        },
      ])
    } else if (response.status === STATUS_ERROR) {
      actions.common.commitNotifications([
        {
          message: response.message,
          type: 'danger',
        },
      ])
    } else {
      actions.common.commitNotifications([
        {
          message: 'Something went wrong',
          type: 'danger',
        },
      ])
    }
  }

  public addNotificationFromResponse = (response: CustomResponse) => {
    if (response.status === STATUS_OK) {
      this.addNotification({
        message: response.message,
        type: 'success',
      })
    } else if (response.status === STATUS_WARNING) {
      this.addNotification({
        message: response.message,
        type: 'warning',
      })
    } else if (response.status === STATUS_ERROR) {
      this.addNotification({
        message: response.message,
        type: 'danger',
      })
    } else {
      this.addNotification({
        message: 'Something went wrong',
        type: 'danger',
      })
    }
  }
}

export default new Notifications()
