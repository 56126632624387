<template>
  <span v-if="label" class="tag" :class="color" v-html="html" :title="title"></span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { formatDateTime, isDateTimeField } from '@/util'

@Component({})
export default class DataLabel extends Vue {
  @Prop({}) label!: string
  @Prop({}) value!: string
  @Prop({}) title!: string
  @Prop({ default: 'is-info is-light' }) color!: string

  protected finalValue = isDateTimeField(this.value) ? formatDateTime(this.value) : this.value

  private readableLabel(label: string) {
    const [first, ...others] = this.label.split('_')
    return [first.charAt(0).toUpperCase() + first.slice(1), ...others].join(' ')
  }

  get html() {
    return `<b>${this.readableLabel(this.label)}:</b>&nbsp;${this.finalValue}`
  }
}
</script>

<style scoped lang="scss">
.tag {
  font-size: 10px !important;
  padding: 6px;
  // margin-left: 6px;
  margin: 0;
  height: 14px;
}
</style>
