<template>
  <b-modal :active="active" has-modal-card>
    <div class="modal-card" style="width: auto; min-width: 600px">
      <header class="modal-card-head">
        <p class="modal-card-title">Bewerk Ticket Financieel</p>
        <button type="button" class="delete" @click="$emit('cancel')" />
      </header>
      <section class="modal-card-body w-min-200">
        <b-field :label="humanize(field)" v-for="field in fields" :key="field">
          <b-input
            v-if="!options[field]"
            :type="inputType(field)"
            v-model="form[field]"
            step="0.01"
            :icon="iconName(field)"
          />
          <b-select v-else expanded v-model="form[field]">
            <option v-for="option in options[field]" :value="option" :key="option">
              {{ option }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Annuleren" @click="$emit('cancel')" />
        <b-button label="Opslaan" type="is-primary" @click="save" :loading="loading" />
      </footer>
    </div>
  </b-modal>
</template>

<script lang="js">
import { notifications } from '@/util'

export default {
  props: ['active', 'ticket'],
  emits: ['finish', 'cancel'],
  data() {
    return {
      loading: false,
      form: {
        ...this.finance,
      },
      // these options are hard-coded for now, even though they are also stored in some config setting
      options: {
        billing_to: [
          '',
          'WoCo',
          'Installer',
          'AP Systems',
          'Hoymiles',
          'Zeversolar',
          'Goodwe',
          'Other suppliers',
          'Supplier solarpanel',
          'Eleena',
        ],
        malfunction_category: [
          '',
          'Micros',
          'Inverter',
          'Eleenameter',
          'Cabling',
          'Leakage',
          'Service request',
          'Inspection',
        ],
      },
    }
  },
  methods: {
    humanize(str) {
      return (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ')
    },
    inputType(name) {
      if (name.includes('date')) return 'date'
      if (name.includes('price')) return 'number'
      return 'text'
    },
    iconName(name) {
      if (name.includes('price')) return 'euro-sign'
      return null
    },
    async save() {
      const { id } = this.ticket
      const { partner, type } = this.ticket.ticket
      const url = `/tickets/${partner}/erp-ticket/${type}/${id}`

      this.loading = true
      const update = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify({
          maintenance_input: {
            ...this.ticket.ticket.input,
            // this transformation is needed because the update is otherwise not accepted
            images: Object.fromEntries(this.ticket.ticket.input.images.map(({ t, u }) => [t, u])),
            finance: this.form,
          },
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.getters['auth/getToken']}`,
        },
      })
      this.loading = false

      if (update.ok) {
        const data = await update.json()
        notifications.addNotification({
          message: data.message,
          type: 'success',
        })

        this.$emit('finish')
      } else {
        notifications.addNotification({
          message: `Error while updating ticket, status ${update.status}`,
          type: 'danger',
        })
      }
    },
  },
  computed: {
    finance() {
      return {
        billing_to: '',
        price_work: '',
        invoice_number: '',
        price_material: '',
        client_reference: '',
        malfunction_category: '',
        purchase_invoice_date: '',
        ...this.ticket?.ticket?.input?.finance,
      }
    },
    fields() {
      return Object.keys(this.finance)
    },
  },
  mounted() {
    this.form = this.finance
  },
}
</script>

<style></style>
