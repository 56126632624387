<script lang="ts">
import { Component } from 'vue-property-decorator'
import BaseButton from './BaseButton.vue'

@Component({})
export default class ApproveButton extends BaseButton {
  protected color = 'is-success'

  // get disabled() {
  //   return this.masterItem.isLoading || !this.action.enabled || !this.isValid;
  // }

  // get isValid() {
  //   if (!this.manager.selectedMasterItem) return false;
  //   if (this.masterItem.address !== this.manager.selectedMasterItem.address) return false;

  //   let isValid = true;

  //   Object.entries(this.masterItem.details!).forEach(([key, values]) => {
  //     if (key === 'status') return;

  //     if (key === 'products') {
  //       Object.values(values).forEach((product: any) => {
  //         if (typeof product === 'string') return;
  //         product.items.forEach((item: any) => {
  //           Object.values(item).forEach((value: any) => {
  //             if (value.required && !value.value) isValid = false;
  //           });
  //         });
  //       });
  //       return;
  //     }

  //     Object.values(values).forEach((value: any) => {
  //       if (typeof value === 'string') return;
  //       if (value.required && !value.value) isValid = false;
  //     });
  //   });

  //   return isValid;
  // }
}
</script>
