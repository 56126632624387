<template>
  <b-modal :active="!!order" has-modal-card>
    <div class="modal-card" style="width: auto; min-width: 600px" v-if="order">
      <header class="modal-card-head">
        <p class="modal-card-title">Producten order #{{ order.id }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body w-min-200">
        <h2 class="subtitle">Productlijst</h2>

        <div v-for="(product, index) in products" :key="product.code" class="mb-2 box">
          <div class="columns">
            <div class="column is-one-quarter">
              <b-field label="Aantal">
                <b-input v-model.number="product.amount" type="number" :disabled="!isEditable" />
              </b-field>
            </div>
            <div class="column">
              <div>{{ product.name }}</div>
              <div>{{ product.code }}</div>
            </div>
            <div class="column is-one-quarter">
              <b-button
                :disabled="!isEditable"
                icon-left="trash"
                @click="removeProduct(index)"
                size="is-small"
                >Verwijder</b-button
              >
            </div>
          </div>
        </div>

        <div class="mt-4" v-show="isEditable">
          <h2 class="subtitle">Product toevoegen</h2>

          <b-field label="Aantal">
            <b-input v-model.number="newProduct.amount" type="number" />
          </b-field>
          <b-field label="Product">
            <b-autocomplete
              v-model="productQuery"
              :data="filteredProducts"
              field="description"
              @select="selectProduct"
              append-to-body
              clearable
            >
              <template slot-scope="props">
                <div>
                  <div>{{ props.option.description }}</div>
                  <div>{{ props.option.code }}</div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <div v-if="duplicatedProduct" class="has-text-danger mb-1">
            Dit product staat al in de lijst. Pas het aantal aan.
          </div>
          <b-button @click="addProduct" :disabled="duplicatedProduct">Toevoegen</b-button>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Annuleren" @click="$emit('close')" />
        <b-button label="Opslaan" type="is-primary" @click="save" :loading="loading" />
      </footer>
    </div>
  </b-modal>
</template>

<script lang="js">
import { ref, computed, watchEffect } from 'vue'
import fetchData from '@/util/fetch'
import { useQuery } from '@tanstack/vue-query'

export default {
  props: ['order', 'loading', 'isEditable'],
  emits: ['close'],
  setup(props, { emit }) {
    const products = ref([])

    watchEffect(() => {
      if (props.order) {
        products.value = [...props.order.products]
      }
    })

    const newProduct = ref({ amount: 1, product: null })
    const getProducts = useQuery({
      queryKey: ['products'],
      queryFn: () => fetchData('/api/v1/tickets/products'),
    })
    const productQuery = ref('')

    function addProduct() {
      products.value.push({
        amount: newProduct.value.amount,
        code: newProduct.value.product.code,
        name: newProduct.value.product.description,
      })

      newProduct.value = { amount: 1, product: null }
      productQuery.value = ''
    }

    function save() {
      emit('save', { products })
    }

    const allowed = ['panel', 'inverter', 'smartmeter', 'omvormer varia', 'omvormer']
    const filteredProducts = computed(() => {
      if (getProducts.data.value) {
        return getProducts.data.value.data.filter(
          (p) =>
            allowed.includes(p.product_type) &&
            p.description.toLowerCase().includes(productQuery.value?.toLowerCase())
        )
      }
      return null
    })

    function selectProduct(product) {
      newProduct.value.product = product
    }

    function removeProduct(index) {
      products.value.splice(index, 1)
    }

    const duplicatedProduct = computed(
      () =>
        newProduct.value?.product?.code &&
        products.value.some((p) => p.code === newProduct.value.product.code)
    )

    return {
      duplicatedProduct,
      removeProduct,
      selectProduct,
      products,
      filteredProducts,
      productQuery,
      newProduct,
      save,
      addProduct,
    }
  },
}
</script>
