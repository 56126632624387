<template>
  <div class="dashboard">
    <div class="ml-4 p-2" v-if="manager.dashboard.isLoading">Loading dashboard...</div>
    <div
      class="ml-4 p-2 has-text-success"
      style="font-size: 1.1em"
      v-else-if="manager.dashboard.actions.getItems().length === 0"
    >
      There are currently no unresolved complaints
    </div>
    <div v-else class="actions ml-3">
      <div class="actions-filters is-flex is-align-items-center">
        <div class="actions-filter mr-2">
          <span>Woco </span>
          <dropdown
            class="dropdown"
            display="label"
            :items="wocoDropdown"
            @change="(item) => item.handler()"
          />
        </div>
        <div class="actions-filter mr-2">
          <span>Subject </span>
          <dropdown
            class="dropdown"
            display="label"
            :items="subjectDropdown"
            @change="(item) => item.handler()"
          />
        </div>
        <div class="actions-filter mr-2">
          <span>Type </span>
          <dropdown
            class="dropdown"
            display="label"
            :items="typeDropdown"
            @change="(item) => item.handler()"
          />
        </div>
        <div class="actions-filter mr-2">
          <span>Status </span>
          <dropdown
            class="dropdown"
            display="label"
            :items="statusDropdown"
            @change="(item) => item.handler()"
          />
        </div>
        <div class="actions-filter mr-2">
          <span>Created By</span>
          <dropdown
            class="dropdown"
            display="label"
            :items="createdByDropdown"
            @change="(item) => item.handler()"
          />
        </div>

        <!-- STATUS DATE RANGE -->
        <div class="actions-filter date">
          <p>Date</p>
          <dropdown-slotted min-width="0" margin-top="10px" align="right" width="11.5rem">
            <template v-slot:trigger>
              <i class="far fa-calendar-alt" style="height: 1em; font-size: 2.5em"></i>
            </template>
            <template v-slot:default="{ close }">
              <div class="has-text-black">
                <div class="is-flex is-align-items-center">
                  <small-tag
                    label="apply"
                    margin="2px 4px"
                    @click="() => onApplyDateSelection(close)"
                  />
                  <small-tag
                    label="clear"
                    margin="2px 0"
                    color="is-info"
                    @click="() => onClearDateSelection(close)"
                  />
                </div>
                <date-range-picker
                  :start="dateSelection.start"
                  :end="dateSelection.end"
                  @change="(range) => (dateSelection = range)"
                />
              </div>
            </template>
          </dropdown-slotted>
        </div>
      </div>
      <div class="is-flex is-justify-content-space-between mt-1">
        <p class="totals">
          <span>Total: {{ total }}</span> / <span>Open: {{ open }}</span> /
          <span>Closed: {{ closed }}</span>
        </p>
        <label class="checkbox is-flex">
          Compact View
          <input
            type="checkbox"
            class="checkbox ml-1"
            :checked="compactView"
            @change="() => (compactView = !compactView)"
          />
        </label>
      </div>
      <div class="actions-list">
        <div
          v-for="action in manager.dashboard.actions.filteredItems"
          :key="action.id"
          @click="() => handleClick(action)"
          class="card"
          :class="{
            'card-resolved': action.is_resolved,
            'card-unresolved': !action.is_resolved,
            'compact-card': compactView,
          }"
        >
          <div class="card-content">
            <div>
              <span
                ><b>{{ action.address }}</b></span
              >
              <span class="card-creator">{{ action.created_by.split('@')[0] }}</span>
              <p>
                <span :title="action.value">{{ action.value }}</span>
              </p>
              <div v-if="!compactView">
                <span style="font-size: 0.7rem">
                  {{ action.subject }},
                  {{ action.is_inbound ? 'Inbound' : ' Outbound' }}
                </span>
                <span>
                  <span style="font-size: 0.7rem">
                    <i>{{ action.created_by }}</i> </span
                  >,
                  <span style="font-size: 0.6rem">
                    {{ manager.formatDate(new Date(action.created_at)) }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CallCentreScreenManager } from '@/logic/CallCentreScreen'
import Action from '@/logic/CallCentreScreen/CallCentreAction'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class CallCentreClientData extends Vue {
  @Prop({}) manager!: CallCentreScreenManager

  protected compactView = true
  protected dateSelection = {
    start: '',
    end: '',
  }

  get filter() {
    return this.manager.dashboard.actions.filter!
  }

  get statusDropdown() {
    const statuses = this.manager.dashboard.possibleStatuses
    const dropdown = statuses.map((value) => ({
      label: value,
      handler: () => this.filter?.setState({ status: value }),
    }))
    return dropdown
  }

  get wocoDropdown() {
    const wocos = this.manager.dashboard.possibleWocos
    const dropdown = wocos.map((value) => ({
      label: value,
      handler: () => this.filter.setState({ woco: value }),
    }))
    return dropdown
  }

  get subjectDropdown() {
    const subjects = this.manager.dashboard.possibleSubjects
    const dropdown = subjects.map((value) => ({
      label: value,
      handler: () => this.filter.setState({ subject: value }),
    }))
    return dropdown
  }

  get typeDropdown() {
    return ['All', 'Comment', 'Complaint'].map((value) => ({
      label: value,
      handler: () => this.filter.setState({ type: value }),
    }))
  }

  get createdByDropdown() {
    const user_emails = this.manager.dashboard.possibleCreatedBy
    const dropdown = user_emails.map((value) => ({
      label: value,
      handler: () => this.filter.setState({ created_by: value }),
    }))
    return dropdown
  }

  get open() {
    return this.manager.dashboard.actions.filteredItems.filter((item: Action) => !item.is_resolved)
      .length
  }

  get closed() {
    return this.manager.dashboard.actions.filteredItems.filter((item: Action) => item.is_resolved)
      .length
  }

  get total() {
    return this.manager.dashboard.actions.filteredItems.length
  }

  protected handleClick(action: Action) {
    this.manager.setSelectedAddress(action.address)
    this.manager.setSelectedAction(action)
  }

  protected onClearDateSelection(close: Function) {
    this.dateSelection = {
      start: '',
      end: '',
    }
    this.filter.setState({ dateSelection: this.dateSelection })
    close()
  }

  protected onApplyDateSelection(close: Function) {
    this.filter.setState({ dateSelection: this.dateSelection })
    close()
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  height: 100%;
  width: 27vw;
  font-size: 0.9em;

  .actions-list {
    overflow-y: scroll;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0.35rem;
      height: 0.35rem;
      color: #868686;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      cursor: grab;
    }

    .card-unresolved {
      background-color: rgb(250, 128, 114);
      color: rgb(21, 9, 7);

      &:hover {
        cursor: pointer;
        background-color: rgb(234, 56, 24);
        color: rgb(72, 54, 54);
      }

      .card-footer {
        border-top: 1px solid rgb(184, 103, 85);

        .card-footer-item {
          color: rgb(143, 66, 49);
        }
      }
    }

    .card-resolved {
      background-color: rgb(156, 237, 140);

      &:hover {
        cursor: pointer;
        background-color: rgb(91, 225, 64);
        color: rgb(54, 72, 55);
      }

      .card-footer {
        border-top: 1px solid rgb(173, 240, 159);

        .card-footer-item {
          color: rgb(39, 67, 40);
        }
      }
    }
  }

  .actions-filters {
    flex-direction: row;
    flex-flow: row wrap;

    .actions-filter {
      width: 5.5em;
      flex: 1;

      .dropdown {
        width: auto;
      }
    }
  }

  .totals {
    font-weight: 600;
  }

  .card {
    margin: 0.5rem 0.3rem 0.5rem 0 !important;

    .card-content {
      flex-direction: row;
      padding: 0.8em !important;
      font-size: 0.9em;
      p {
        font-size: 1em;

        :not(:last-child) {
          margin-bottom: 0.2em;
        }
      }
    }

    .card-creator {
      float: right;
    }

    .card-address {
      float: left;
    }
  }

  .compact-card {
    .card-content {
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
