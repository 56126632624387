import requests from '@/util/RequestHandler'
import type { GroupCreate, Interaction } from '@/interfaces'
import { actions } from './store'

export default {
  auth: {
    requestOTP: async (email: string) => {
      const url = '/auth/api/v1/otps'
      const data = { email: email }

      const headers = {
        'Content-Type': 'application/json',
      }

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data), // Stringify the object
      })

      return response.json()
    },

    submitOTP: async (otp: string, email: string) => {
      const url = '/auth/api/v1/jwts'
      const data = JSON.stringify({ otp, email })
      const headers = {
        'Content-Type': 'application/json',
      }

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: data,
      })

      return response.json()
    },

    getPermissions: async (accessToken: string): Promise<any> => {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }
      const url = '/auth/api/v1/me'

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers,
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.json()
        return data
      } catch (error) {
        console.error('Error fetching permissions:', error)
        throw error
      }
    },
  },

  tasks: {
    // GET
    getAllTasksForGroup: (group: string, taskType: string) =>
      requests.get(`/api/v1/tasks/task_type/${taskType}/group/${group}`),
    getAllTasksForType: (taskType: string) => requests.get(`/api/v1/tasks/task_type/${taskType}`),
    getTaskDetail: (taskType: string, address: string) =>
      requests.get(`/api/v1/tasks/task_type/${taskType}/address/${address}/detail`),
    getTaskData: (taskType: string, address: string) =>
      requests.get(`api/v1/tasks/task_type/${taskType}/address/${address}`),
    getTaskHistory: (taskType: string, address: string) =>
      requests.get(`api/v1/tasks/task_type/${taskType}/address/${address}/history`),
    getCopyDesign: (address: string) => requests.get(`api/v1/address/${address}/copy-design`),
    getAllMaintenanceAddresses: () => requests.get('api/v1/tasks/task_type/maintenance/addresses'),

    // CREATE
    createTask: (address: string, taskType: string) =>
      requests.post(`api/v1/address/${address}/create-task/${taskType}`, {}),

    // SUBMIT
    submitAction: (url: string, actionData: { [prop: string]: unknown }) =>
      requests.put(url, actionData),
    submitCopyDesign: (
      address: string,
      targetAddresses: Array<string>,
      fields: { [groupName: string]: Array<string> }
    ) => requests.post(`api/v1/address/${address}/copy-design`, { to: targetAddresses, fields }),
    submitAddressesForTaskInitialization: (
      woco: string,
      project: string,
      addresses: Array<string>
    ) =>
      requests.post(`api/v1/tasks/woco/${woco}/project/${project}/addresses/initialize`, {
        addresses,
      }),
    submitTicketRefresh: (address: string, taskType: 'installation' | 'inspection') =>
      requests.get(`/api/v1/address/${address}/task_type/${taskType}/ticket/check`),
    submitNewMaintenanceTicket: (address: string, formData: FormData) =>
      requests.put(
        `/api/v1/address/${address}/task_type/maintenance/ticket/add_ticket`,
        {},
        formData
      ),
    submitUpdateMaintenanceTicket: (
      address: string,
      formData: FormData,
      ticketID: string,
      installer: string
    ) => {
      const queryParams = `ticket_id=${ticketID}&installer=${installer}`
      return requests.put(
        `api/v1/address/${address}/task_type/maintenance/ticket/update?${queryParams}`,
        {},
        formData
      )
    },
  },

  groups: {
    // GET
    getAllGroups: () => requests.get('/api/v1/group'),
    getAllGroupsVisibleForUser: (username: string) =>
      requests.get(`/api/v1/group/user/${username}`),
    getGroupByName: (groupName: string) => requests.get(`/api/v1/group/${groupName}`),
    getGroupVisibleForUser: (groupName: string, username: string) =>
      requests.get(`/api/v1/group/${groupName}/user/${username}`),
    getAllWocos: () => requests.get('/api/v1/config/wocos/'),
    getAllProjectsForWoco: (woco: string) => requests.get(`/api/v1/config/wocos/${woco}/projects/`),
    getAllGroupsForProject: (woco: string, project: string, username: string) =>
      requests.get(`/api/v1/project/woco/${woco}/name/${project}/groups`),
    getUnavailableAddressesForProject: (woco: string, project: string) =>
      requests.get(`/api/v1/project/woco/${woco}/name/${project}/addresses/unavailable`),
    getAssignedAddressesForWoco: (woco: string) => requests.get(`/api/v1/address/woco/${woco}`),
    getClientServiceAddressesForWoco: (woco: string) =>
      requests.get(`/api/v1/address/service/clients/woco/${woco}`),
    getAvailableApps: () => requests.get('/api/v1/app'),
    getAddressDetails: (address: string) => requests.get(`/api/v1/address/${address}`),

    // CREATE
    createGroup: (groupData: GroupCreate) => requests.post('/api/v1/group', groupData),

    // UPDATE
    updateGroupInfo: (groupName: string, groupInfo: GroupCreate) =>
      requests.put(`/api/v1/group/${groupName}`, groupInfo),
    updateGroupAddresses: (groupName: string, addresses: Array<object>) =>
      requests.put(`/api/v1/group/${groupName}/addresses`, addresses),

    // DELETE
    deleteGroup: (groupName: string) => requests.delete(`/api/v1/group/${groupName}`),
  },

  details: {
    // CREATE
    addTaskDetailForClient: (url: string, data: object, formData?: FormData) =>
      requests.post(url, data, formData),

    // GET
    getTaskDetailForClient: (url: string) => requests.get(url),

    // UPDATE
    updateTaskDetailForClient: (url: string, data: object, formData?: FormData) =>
      requests.put(url, data, formData),
    updateTaskDataField: (address: string, taskType: string, data: object, formData?: FormData) =>
      requests.put(`/api/v1/address/${address}/task_type/${taskType}/data`, data, formData),

    // SEND, RESEND
    sendTaskDetailForClient: (url: string, data: object, formData?: FormData) =>
      requests.post(url, data, formData),

    // DELETE
    deleteTaskDetailForClient: (url: string, data: object) => requests.delete(url, data),
  },

  clients: {
    // GET
    getClientsForGroup: (groupName: string) => requests.get(`/api/v1/clients/group/${groupName}`),

    getClientsForQuery: (query: string) => requests.get(`/api/v1/clients/query/${query}`),

    getClientDetailsForEdit: (address: string) =>
      requests.get(`/api/v1/clients/${address}/details`),

    // UPDATE
    updateClientDetails: (address: string, data: object) =>
      requests.post(`/api/v1/clients/${address}/details`, data),
  },

  interactions: {
    // GET
    getInteractions: () => requests.get('/api/v1/interaction'),

    // CREATE
    createInteraction: (interaction: Interaction) => {
      const body = {
        type: interaction.type,
        value: interaction.value || '',
        context: interaction.context,
        created_by: actions.auth.readUser(),
      }

      return requests.post('/api/v1/interaction', body)
    },
  },

  dashboard: {
    getDashboardData: () => requests.get('/api/v1/dashboard'),
    getDashboardDataInRange: (start: string, end: string) =>
      requests.get(`/api/v1/dashboard/start/${start}/end/${end}`),
    submitForecast: (project: string, taskType: string, value: number, date: string) =>
      requests.post(`/api/v1/dashboard/forecast/project/${project}/task_type/${taskType}`, {
        value,
        date,
      }),
  },

  data: {
    getDataForClient: (address: string) => requests.get(`/api/v1/data/address/${address}`),
  },

  actions: {
    getActionsForAddress: (address: string) => requests.get(`api/v1/actions/address/${address}`),
    getActionsHasFollowUp: () => requests.get('api/v1/actions/has_follow_up'),
    createAction: (body: any) => requests.post('api/v1/actions', body),
    updateAction: (body: any, id: number) => requests.put(`api/v1/actions/${id}`, body),
    getSubjects: () => requests.get('api/v1/actions/subjects'),
  },

  eleena: {
    sendCredentials: (address: string) =>
      requests.post(`api/v1/eleena/send-credentials/${address}`, {}),
    getEmailData: (address: string) =>
      requests.get(`api/v1/eleena/get-email-stats-for-address/${address}`),
  },

  excel: {
    createExcel: (items: Array<Array<string>>, filename: string) =>
      requests.post('api/v1/excel', { items, filename }, undefined, true),
  },
}
