<template>
  <div class="tw-bg-white tw-drop-shadow-xl tw-rounded-lg tw-p-5 tw-mb-5">
    <div class="tw-flex tw-items-center tw-mb-4">
      <form-field
        hideLabel="true"
        type="date"
        label="Selected date:"
        v-model="localDate"
        class="tw-border tw-rounded tw-p-2 tw-mx-10 tw-w-[200px]"
      />

      <button @click="$emit('changeDate', addDays(selectedDate, -1))" class="tw-mx-2">
        <i class="fas fa-arrow-left tw-text-xl tw-text-blue-500"></i>
      </button>
      <button
        @click="$emit('changeDate', startOfToday())"
        class="tw-bg-blue-500 tw-text-white tw-py-1 tw-px-4 tw-rounded tw-mx-2"
      >
        TODAY
      </button>
      <button @click="$emit('changeDate', addDays(selectedDate, 1))" class="tw-mx-2">
        <i class="fas fa-arrow-right tw-text-xl tw-text-blue-500"></i>
      </button>
    </div>

    <div class="tw-relative tw-max-h-50">
      <ChartLine :data="chartData" :options="chartOptions" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits, watch } from 'vue'
import { useQuery, useQueries } from '@tanstack/vue-query'
import fetchData from '@/util/fetch'
import { format, parseISO, addDays, startOfToday } from 'date-fns'
import { Line as ChartLine } from 'vue-chartjs'
import FormField from '@/components/shared/FormField.vue'
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend)

const props = defineProps({
  serialNumber: {
    type: String,
    required: true,
  },
  selectedDate: {
    type: Date,
    required: true,
  },
  metersForComparison: {
    type: Array,
    required: false,
    default: () => [],
  },
})

const emit = defineEmits(['changeDate'])

const localDate = computed({
  get() {
    return format(props.selectedDate, 'yyyy-MM-dd')
  },
  set(value) {
    emit('changeDate', new Date(value))
  },
})

const fetchMeterData = async (serialNumber) => {
  const dateFrom = new Date(props.selectedDate).toISOString()
  const dateUntil = new Date(addDays(props.selectedDate, 1)).toISOString()
  const urlParams = new URLSearchParams({ date_from: dateFrom, date_until: dateUntil })
  const result = await fetchData(
    `/eleena/api/v1/meters/${serialNumber}/quarter-hour-deltas?${urlParams}`
  )
  return result?.status === 'OK' ? result.data : []
}

const formattedDate = computed(() => format(props.selectedDate, 'yyyy-MM-dd'))

const {
  data: mainMeterData,
  isLoading,
  isFetching,
} = useQuery({
  queryKey: computed(() => ['meterGraph', props.serialNumber, formattedDate.value]),
  queryFn: () => fetchMeterData(props.serialNumber),
  refetchOnWindowFocus: false,
  keepPreviousData: true,
})

const queries = computed(() =>
  props.metersForComparison.map((meter) => ({
    queryKey: ['meter', meter.serialNumber, formattedDate.value],
    queryFn: () => fetchMeterData(meter.serialNumber),
  }))
)

const meterQueries = useQueries({ queries: queries })

const datesArray = computed(
  () => mainMeterData.value?.map((item) => format(parseISO(item.date), 'HH:mm')) || []
)

const mainDataValues = computed(() => mainMeterData.value?.map((item) => item.delta_power) || [])
const activePowerDataValues = computed(
  () => mainMeterData.value?.map((item) => item.active_power) || []
)

const comparisonDataValues = ref([])

const predefinedColors = [
  'rgba(54, 162, 235, 0.8)',
  'rgba(255, 159, 64, 0.8)',
  'rgba(153, 102, 255, 0.8)',
  'rgba(255, 99, 132, 0.8)',
]

watch(
  meterQueries,
  (newMeterQueries) => {
    comparisonDataValues.value = newMeterQueries
      .map((query, index) => {
        if (query?.data && Array.isArray(query.data)) {
          const meter = props.metersForComparison[index] || {}

          return {
            label: `${meter.address}`,
            color: predefinedColors[index],
            pointRadius: 3,
            fill: true,
            data: query.data.map((item) => item.delta_power),
            tension: 0.4,
          }
        }
        return null
      })
      .filter(Boolean)
  },
  { deep: true, immediate: true }
)

const chartData = computed(() => {
  const getDatasetColor = (isLoadingOrFetching, color) => {
    return isLoadingOrFetching
      ? color.replace('0.8', '0.1') // Transparent if loading
      : color // Normal color if not loading
  }
  const mainProductionColor = 'rgba(75, 192, 192, 0.8)'
  const activePowerColor = 'rgba(255, 219, 88, 0.8)'

  const isLoadingOrFetching = isLoading.value || isFetching.value // Check loading state once

  return {
    labels: datesArray.value,
    datasets: [
      {
        label: 'Main Serial Production',
        backgroundColor: getDatasetColor(isLoadingOrFetching, mainProductionColor),
        borderColor: getDatasetColor(isLoadingOrFetching, mainProductionColor),
        pointBackgroundColor: getDatasetColor(isLoadingOrFetching, mainProductionColor),
        pointRadius: 3,
        fill: true,
        data: mainDataValues.value,
        tension: 0.4,
      },
      {
        label: 'Active Power',
        backgroundColor: getDatasetColor(isLoadingOrFetching, activePowerColor),
        borderColor: getDatasetColor(isLoadingOrFetching, activePowerColor),
        pointBackgroundColor: getDatasetColor(isLoadingOrFetching, activePowerColor),
        pointRadius: 3,
        fill: true,
        data: activePowerDataValues.value,
        tension: 0.4,
      },
      ...comparisonDataValues.value.map((dataset) => ({
        ...dataset,
        backgroundColor: getDatasetColor(isLoadingOrFetching, dataset.color),
        borderColor: getDatasetColor(isLoadingOrFetching, dataset.color),
        pointBackgroundColor: getDatasetColor(isLoadingOrFetching, dataset.color),
      })),
    ],
  }
})

const chartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: { display: true },
    title: { display: false },
  },
  scales: {
    y: { beginAtZero: true },
  },
}
</script>
