<template>
  <table-row-task :item="masterItem" :manager="manager" :selected="selected">
    <!-- ID -->
    <table-cell>
      <span>{{ idWoco }}</span>
    </table-cell>

    <!-- PERSON -->
    <table-cell>
      <span>{{ people }}</span>
    </table-cell>

    <!-- PHONE NUMBER -->
    <table-cell>
      <span>{{ phones }}</span>
    </table-cell>

    <!-- EMAIL -->
    <table-cell>
      <span>{{ email }}</span>
    </table-cell>

    <table-cell>
      <span>{{ city }}</span>
    </table-cell>
  </table-row-task>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'

import TableRowBase from './TableRowBase.vue'

@Component({
  name: 'ClientTableRow',
  components: {},
})
export default class ClientTableRow extends TableRowBase {
  get idWoco() {
    return this.masterItem.client.household.id_woco
  }

  get people() {
    return this.masterItem.client.people.map((person) => person.name).join('; ')
  }

  get phones() {
    return this.masterItem.client.phones.map((phone) => phone.number_text).join('; ')
  }

  get email() {
    const { email } = this.masterItem.client
    return typeof email === 'object' ? email.address : ''
  }

  get city() {
    return this.masterItem.client.household.city
  }
}
</script>

<style lang="scss" scoped>
tr:hover {
  cursor: pointer;
}

.status-info {
  font-size: 8px;
}

.popover {
  position: fixed;
  background-color: #000;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
