<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import BaseButtonBulk from './BaseButtonBulk.vue'

@Component({
  name: 'RejectButtonBulk',
})
export default class RejectButtonBulk extends BaseButtonBulk {
  // protected label = 'reject';
  protected color = 'is-danger'
}
</script>
