<template>
  <input type="checkbox" @click="list.toggleSelectAll()" data-id="select-all" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import type { MultiSelectList } from '@/logic/common'
import type { MasterItem } from '@/logic/TasksScreen'

@Component({
  name: 'TableHeadSelectAll',
})
export default class TableHeadSelectAll extends Vue {
  @Prop() list!: MultiSelectList<MasterItem>
}
</script>
