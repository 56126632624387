<template>
  <div class="design-data">
    <span class="pb-2"><b>Status:</b> {{ client.tasks.design.status }}</span>
    <div v-for="product in client.tasks.design.products" :key="product.code" class="products">
      <span><b>Type:</b> {{ product.type }}</span>
      <span><b>Code:</b> {{ product.code }}</span>
      <span><b>Amount:</b> {{ product.amount }}</span>
      <span><b>Description:</b> {{ product.description }}</span>
    </div>

    <div class="extra-fields">
      <span v-for="[key, value] in Object.entries(client.tasks.design.extra_fields)" :key="key">
        <b>{{ key.replace('_', ' ') }}:</b>{{ value }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class CallCentreDesignData extends Vue {
  @Prop({}) client!: object
}
</script>

<style lang="scss" scoped>
.design-data {
  display: flex;
  flex-direction: column;

  .products {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.6rem;

    & > * {
      b {
        padding-right: 0.3rem;
      }
    }
  }

  .extra-fields {
    display: flex;
    flex-direction: column;

    & > * {
      b {
        padding-right: 0.3rem;
        text-transform: capitalize;
      }
    }
  }

  & > * {
    b {
      padding-right: 0.3rem;
    }
  }
}
</style>
