<template>
  <div v-if="input" class="box ticket-input">
    <!-- HEADER / UPDATE TICKET BUTTON -->
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
      <details-box-heading label="Input" />
      <details-ticket-button
        v-if="selectedTicket.actions.update_ticket_button"
        field="update_ticket_button"
        :component="selectedTicket.actions.update_ticket_button"
        @click="refreshDetails"
      />
    </div>

    <!-- TECHNICAL INFO -->
    <details-box-heading label="Technical info" class="mb-1" />
    <div class="box-content mb-2">
      <template v-for="[field, fieldValue] in Object.entries(input.extra_fields)">
        <p
          v-if="typeof fieldValue === 'string'"
          :key="`client-${field}`"
          :class="{ 'is-updated': hasChanged(`extra_fields.${field}`) }"
        >
          <b>{{ capitalized(field) }}: </b><span>{{ fieldValue }}</span>
        </p>
      </template>
    </div>

    <!-- PRODUCTS -->
    <details-box-heading label="Products" class="mb-1" />
    <div class="box-content mb-2">
      <template v-for="[productType, products] in Object.entries(ticketProductsInput)">
        <template v-for="[productCode, productData] in Object.entries(products)">
          <div
            v-if="!!productData.amount"
            :key="`${productType}-${productCode}`"
            class="product-entry mb-2"
            :class="{ 'is-updated': hasChanged(`products.${productType}`) }"
          >
            <!-- PRODUCT NAME / COUNT -->
            <div class="is-flex is-justify-content-space-between">
              <p>
                <b style="text-transform: capitalize"> {{ productType }}: </b>
                <span>{{ productCode }}</span>
              </p>
              <span class="tag" style="font-size: 0.6rem">{{ productData.amount }}</span>
            </div>
          </div>
        </template>
      </template>
    </div>

    <!-- SERVICES -->
    <details-box-heading label="Services" class="mb-1" />
    <div class="box-content mb-2">
      <template v-for="[service, serviceData] in Object.entries(ticketServicesInput)">
        <template v-if="!!serviceData">
          <p v-if="!Array.isArray(serviceData)" :key="`service-${service}}`">
            <b>{{ capitalized(service) }}:&nbsp;</b>
            <span>{{ serviceData }}</span>
          </p>

          <template v-else-if="serviceData.length > 0">
            <p :key="`service-${service}`">
              <b>{{ capitalized(service) }}:</b>
            </p>
            <p v-for="serviceWork in serviceData" :key="`work-${serviceWork}`">
              - {{ serviceWork }}
            </p>
          </template>
        </template>
      </template>
    </div>

    <!-- REMARK -->
    <!-- REMARK -->
    <details-box-heading label="Remarks" class="mb-1" />
    <div v-if="actions.length > 0 && inputRemark.enabled" class="is-flex is-flex-direction-row">
      <dropdown
        class="mr-2"
        v-if="actions.length > 0"
        display="label"
        :items="actionTaskDropdown"
        @change="(item) => item.handler()"
      />

      <dropdown
        class="mr-2"
        :disabled="!selectedSubject"
        display="label"
        style="max-width: 10rem"
        :items="actionDropdown"
        @change="(item) => item.handler()"
      />

      <button class="button thin-button is-info" :disabled="!selectedAction" @click="copyComment">
        Copy
      </button>
    </div>

    <div class="box-content mb-2">
      <details-text-area-input
        :component="inputRemark"
        :show-label="false"
        :auto-update="false"
        :value-override="remark"
        :force-show="true"
        :emit-on-input="true"
        @change="onRemarkChange"
      />
      <button
        v-if="inputRemark.enabled"
        :key="remark"
        class="button thin-button is-info my-0"
        :class="processingRemark && 'is-loading'"
        @click="submitRemark"
        :disabled="!hasUpdated"
      >
        Send
      </button>
    </div>

    <!-- IMAGES -->
    <details-ticket-images :manager="manager" :images="input.images" />
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop } from 'vue-property-decorator'
import requests from '@/requests'
import { STATUS_ERROR, STATUS_OK, STATUS_WARNING } from '@/constants'
import { notifications } from '@/util'
import CallCentreAction from '@/logic/CallCentreScreen/CallCentreAction'
import DetailsPaneBase from './DetailsPaneBase.vue'
import DetailsTicketImages from './DetailsTicketImages.vue'

interface Dictionary {
  [prop: string]: Dictionary
}

interface Subject {
  value: string
  translatedValue: string
}

@Component({
  name: 'DetailsInspectionInputBox',
  components: {
    DetailsTicketImages,
  },
})
export default class DetailsInspectionInputBox extends DetailsPaneBase {
  @Prop({ default: {} }) input!: Dictionary
  @Prop({}) selectedTicket!: Dictionary

  protected remark = ''
  protected processingRemark = false
  protected actions = []
  protected selectedSubject: null | Subject = null
  protected selectedAction: null | CallCentreAction = null

  get ticketProductsInput() {
    return this.input.products || {}
  }

  get ticketServicesInput() {
    return this.input.services || {}
  }

  get ticketRemarksInput() {
    return this.input.remarks || []
  }

  get hasUpdated() {
    return (
      !!this.inputRemark.value !== !!this.remark ||
      (!!this.inputRemark.value && !!this.remark && this.inputRemark.value !== this.remark)
    )
  }

  get inputRemark() {
    if (!(this.selectedTicket && this.selectedTicket.actions)) return undefined
    return this.selectedTicket.actions.input_remark as any
  }

  get actionTaskDropdown() {
    const subjects = this.actions.map((action: CallCentreAction) => {
      let translatedValue = ''

      switch (action.subject) {
        case 'Installatie':
          translatedValue = 'Installation'
          break
        case 'Werving':
          translatedValue = 'Sales'
          break
        case 'Algemeen/proces':
          translatedValue = 'Design'
          break
        case 'Schouwing':
          translatedValue = 'Inspection'
          break
        default:
          translatedValue = 'Design'
      }

      return {
        value: action.subject,
        translatedValue,
      }
    })

    const uniqueSubjects = subjects.filter(
      (a, index) => subjects.findIndex((b) => a.value === b.value) === index
    )

    const dropdown = uniqueSubjects.map((value) => ({
      label: value.translatedValue,
      handler: () => {
        this.selectedSubject = value
        this.selectedAction = null
      },
    }))

    dropdown.unshift({
      label: 'Select task',
      handler: () => {
        this.selectedSubject = null
        this.selectedAction = null
      },
    })

    return dropdown
  }

  get actionDropdown() {
    const actions = this.actions.filter(
      (action: CallCentreAction) => action.subject === this.selectedSubject?.value
    )
    const dropdown = actions.map((action: CallCentreAction) => ({
      label: action.value.slice(0, 80),
      handler: () => {
        this.selectedAction = action
      },
    }))

    dropdown.unshift({
      label: 'Select action',
      handler: () => {
        this.selectedAction = null
      },
    })

    return dropdown
  }

  protected async submitRemark() {
    this.processingRemark = true

    const translateNotificationTypeFromResponse = {
      [STATUS_OK]: 'success',
      [STATUS_WARNING]: 'warning',
      [STATUS_ERROR]: 'danger',
    } as { [key: string]: string }

    const actionURL = this.inputRemark.action.update
    const url = actionURL.includes('api') ? actionURL : `/api/v1/${actionURL}`
    const response = await requests.details.updateTaskDetailForClient(url, { remark: this.remark })

    const notificationMessage = response.status === STATUS_OK ? 'Remarks updated' : response.message

    notifications.addNotification({
      message: notificationMessage,
      type: translateNotificationTypeFromResponse[response.status],
    })

    if (response.status === STATUS_OK) {
      this.inputRemark.value = this.remark
      this.$forceUpdate()
    }

    this.processingRemark = false
  }

  protected copyComment() {
    if (this.remark) {
      this.onRemarkChange(`${this.remark}\n- ${this.selectedAction?.value}`)
    } else {
      this.onRemarkChange(`- ${this.selectedAction?.value}`)
    }
  }

  protected async setActions() {
    const response = await requests.actions.getActionsForAddress(
      this.manager.selectedMasterItem.address
    )

    if (response.status_code === 200) {
      this.actions = response.data.actions.filter(
        (action: CallCentreAction) => action.context === 'Internal'
      )
    }
  }

  protected onRemarkChange(value: string) {
    this.remark = value
  }

  protected capitalized(str: string) {
    return (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ')
  }

  protected hasChanged(field: string) {
    const { validation } = this.selectedTicket.actions
    if (!(validation && validation.input)) return false

    return !!field
      .split('.')
      .reduce((v: Dictionary | boolean, k) => (!v ? false : (v as Dictionary)[k]), validation.input)
  }

  created() {
    this.setActions()
    if (this.inputRemark) {
      this.remark = this.inputRemark.value || ''
    }
  }
}
</script>

<style lang="scss">
.is-updated {
  background-color: rgb(255, 215, 141);
}
</style>
