<template>
  <button
    class="button is-outlined"
    data-id="task-action"
    :class="color"
    @click="onClick"
    :disabled="disabled"
  >
    {{ action.label }}
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import type { TasksScreenManager, MasterItem } from '@/logic/TasksScreen'

@Component({})
export default class BaseButton extends Vue {
  @Prop({}) action!: any
  @Prop({}) manager!: TasksScreenManager
  @Prop({}) masterItem!: MasterItem

  get disabled() {
    return this.masterItem.isLoading || !this.action.enabled
  }

  protected color = 'is-primary'

  protected onClick() {
    if (
      !['approve', 'reject', 'cancel'].includes(this.action.label.toLowerCase()) ||
      window.confirm(`Are you sure you want to ${this.action.label.toLowerCase()} this task?`)
    ) {
      this.manager.submitMasterItemAction(this.masterItem, this.action)
    }
  }
}
</script>

<style scoped lang="scss">
.button {
  font-size: 10px !important;
  padding: 6px;
  margin-right: 6px;
  height: 14px;
  width: 50px;
}
</style>
