<template>
  <div class="tw-flex tw-flex-row tw-gap-5 tw-ml-5">
    <generic-filters :filter="filter" @resetFilters="resetFilters">
      <button
        class="tw-bg-blue-400 tw-rounded tw-p-2 tw-text-white tw-my-3"
        @click="downloadAlarmsExcel"
      >
        Exporteer Eleena Alarms
      </button>
      <form-field
        label="Zoek adres"
        type="text"
        v-model="queryInput"
        class="tw-w-[250px] tw-mr-5"
      />
      <form-field
        label="Type alarm"
        nullOption="Alle alarms"
        :options="alarmTypes"
        type="select"
        v-model="filter.type"
        class="tw-w-[250px] tw-mr-5"
      />
      <form-field
        label="Daktype"
        :options="roofOptions"
        type="select"
        v-model="filter.roofType"
        class="tw-w-[250px] tw-mr-5"
      />
      <form-field
        label="Status alarm"
        :options="Object.values(statusAlarms)"
        type="select"
        v-model="filter.status"
        class="tw-w-[250px] tw-mr-5"
      />
      <form-field
        label="Woco"
        nullOption="Alle wocos"
        :options="wocos"
        mode="tags"
        type="multi"
        v-model="filter.wocos"
        class="tw-w-[250px] tw-mr-5"
      />
      <form-field
        label="Verberg alarmen waarvoor een actieve case bestaat"
        type="checkbox"
        :value="true"
        v-model="filter.hideIfActiveCase"
        class="tw-w-[250px] tw-mr-5"
      />
    </generic-filters>

    <div class="tw-m-3">
      <generic-table
        :columns="columns"
        :data="alarms"
        :hasNextPage="hasNextPage"
        :isLoading="isLoading"
        :itemsAmount="numberOfAlarms"
        @fetchNextPage="fetchNextPage"
      >
        <template v-slot:cell-woco_project="{ row }">
          <div class="tw-relative tw-inline-block tw-max-w-[200px]">
            <!-- Visible by default -->
            <span class="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-block">
              {{ trimmedProjectName(row.location.project_id) }}
            </span>

            <!-- Hidden and becomes visible on hover -->
            <div
              class="tw-absolute tw-inset-0 tw-bg-transparent tw-rounded-md tw-p-2 tw-whitespace-nowrap tw-opacity-0 tw-transition-opacity hover:tw-opacity-100"
            >
              <span class="tw-bg-black tw-text-white tw-text-center tw-rounded-md tw-p-2">
                {{ projectName(row.location.project_id) }}
              </span>
            </div>
          </div>
        </template>

        <template v-slot:cell-started_at="{ row }">
          <div class="tw-w-[100px]">
            {{ formatDate(row.started_at) }}
          </div>
        </template>

        <template v-slot:cell-address="{ row }">
          {{ row.location.address.street }} {{ row.location.address.house_number }}
        </template>

        <template v-slot:cell-inverter_type_1="{ row }">
          <div>
            {{ getInverterName(row.inverter_type_1) }}
          </div>
        </template>
        <template v-slot:cell-actions="{ row }">
          <div class="tw-w-[60px] tw-flex tw-justify-between tw-items-center tw-mt-1">
            <div class="tw-relative" @click="toggleDropdown(row)">
              <button
                v-if="!row.closed_at"
                class="tw-inline-block tw-h-[20px] tw-align-middle tw-flex tw-items-center"
              >
                <div v-if="new Date(row.muted_till) > new Date()">
                  <SolidVolumeOffIcon size="20" />
                </div>
                <div v-else>
                  <OutlineVolumeOffIcon size="20" />
                </div>
              </button>

              <!-- Dropdown menu hidden by default, visible on hover -->
              <div
                class="tw-absolute tw-inset-0 tw-top-0 tw-right-full tw-mr-1 tw-w-[100px] tw-bg-transparent tw-rounded-md tw-p-2 tw-opacity-100 tw-transition-opacity tw-z-10"
                v-if="openDropdownId === row.id"
              >
                <div class="tw-bg-gray-200 tw-text-black tw-rounded-md tw-whitespace-nowrap">
                  <button
                    @click="muteAlarm({ id: row.id, days: 365 * 5 })"
                    class="tw-block tw-p-1 hover:tw-bg-gray-300 hover:tw-rounded-md"
                  >
                    for 5 year
                  </button>
                </div>
              </div>
            </div>

            <button class="tw-align-middle tw-flex tw-items-center">
              <a
                :href="getEleenaLink(row)"
                class="tw-text-green-600 hover:tw-underline tw-text-2xl"
                target="_blank"
              >
                <ChartSquareBarIcon size="20" />
              </a>
            </button>

            <button
              @click="createCase = row"
              class="tw-align-middle tw-flex tw-items-center"
              v-if="hasPermission(['Tickets:view_cases'])"
            >
              <SolidBriefcaseIcon v-if="row.case_id" size="20" />
              <OutlineBriefcaseIcon v-else size="20" />
            </button>
          </div>
        </template>
      </generic-table>

      <CreateCaseModal
        v-if="!!createCase"
        :location-id="createCase.location_id"
        :alarm-id="createCase.id"
        :description="createCase.type"
        @close="createCase = false"
        @created="caseCreated"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, reactive } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { useStorage } from '@vueuse/core'
import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { actions } from '@/store'
import fetchData from '@/util/fetch'
import { formatDate, notifications } from '@/util'
import { hasPermission } from '@/logic/common/Permissions'
import FormField from '@/components/shared/FormField.vue'
import GenericTable from '@/components/shared/GenericTable.vue'
import GenericFilters from '@/components/shared/GenericFilters.vue'
import {
  BriefcaseIcon as OutlineBriefcaseIcon,
  ChartSquareBarIcon,
  VolumeOffIcon as OutlineVolumeOffIcon,
} from '@vue-hero-icons/outline'
import {
  BriefcaseIcon as SolidBriefcaseIcon,
  VolumeOffIcon as SolidVolumeOffIcon,
} from '@vue-hero-icons/solid'
import { format, addDays } from 'date-fns'
import CreateCaseModal from '@/components/TicketCases/CreateCaseModal.vue'

const queryClient = useQueryClient()

const createCase = ref(null)

function caseCreated() {
  createCase.value = null
  queryClient.invalidateQueries(['alarms'])
}

const filter = reactive({
  type: useStorage('alarms-filter-type-v1', ''),
  status: useStorage('alarms-filter-status-v1', 'open'),
  wocos: useStorage('alarms-filter-wocos-v1', []),
  roofType: useStorage('alarms-filter-rooftype-v1', 'all'),
  query: '',
  hideIfActiveCase: useStorage('alarms-filter-hide-active-case-v1', false),
})

const resetFilters = () => {
  filter.type = ''
  filter.status = 'open'
  filter.wocos = []
  filter.roofType = 'all'
  filter.query = ''
  queryInput.value = ''
}

const queryInput = ref('')
const openDropdownId = ref(null)

function toggleDropdown(row) {
  if (new Date(row.muted_till) > new Date()) {
    // Already muted, unmute it
    muteAlarm({ id: row.id, days: -1 })
  } else {
    // Not muted, show the dropdown
    openDropdownId.value = openDropdownId.value === row.id ? null : row.id
  }
}

const alarmTypesQuery = useQuery({
  queryKey: ['alarmTypes'],
  queryFn: () => fetchData('/tickets/api/v1/alarm-types'),
})

const alarmTypes = computed(() => alarmTypesQuery.data?.value?.map((type) => type.name))

const statusAlarms = [
  { value: 'all', label: 'All alarms' },
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' },
  { value: 'muted', label: 'Muted' },
]

const roofOptions = [
  { value: 'all', label: 'All types' },
  { value: 'flat', label: 'Flat' },
  { value: 'angled', label: 'Angled' },
]

async function downloadAlarmsExcel() {
  const token = actions.auth.readToken()

  const urlParams = new URLSearchParams({})
  if (filter.wocos) {
    filter.wocos.forEach((woco) => {
      urlParams.append('woco_name', woco)
    })
  }
  if (filter.type !== '') {
    urlParams.append('type', filter.type)
  }
  if (filter.status !== 'all') {
    urlParams.append('status', filter.status)
  }
  if (filter.roofType !== 'all') {
    urlParams.append('roof_type', filter.roofType)
  }
  if (filter.query) {
    urlParams.append('query', filter.query)
  }
  if (filter.hideIfActiveCase) {
    urlParams.append('hide_if_active_case', filter.hideIfActiveCase)
  }

  const result = await fetch(`/tickets/api/v1/alarms?view=excel&${urlParams}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (result.ok) {
    await downloadAsFile(result)
  } else {
    const error = await result.json()
    console.error(error)
    notifications.addNotification({
      message: `Something went wrong: ${error.detail}`,
      type: 'danger',
    })
  }
}

async function downloadAsFile(result) {
  const match = /filename="([^"]+)"/.exec(result.headers.get('content-disposition'))
  const filename = match[1]
  console.log(filename)

  const element = document.createElement('a')
  const blob = new Blob([await result.blob()], {
    type: 'application/vnd.ms-excel',
  })
  element.href = window.URL.createObjectURL(blob)
  element.setAttribute('download', filename)
  element.click()
}

const perPage = 30
const token = actions.auth.readToken()

const getList = async ({ pageParam = 1 }) => {
  const { type, status, wocos, roofType, query } = filter

  const urlParams = new URLSearchParams({
    offset: (pageParam - 1) * perPage,
    limit: perPage,
  })
  if (wocos) {
    wocos.forEach((woco) => {
      urlParams.append('woco_name', woco)
    })
  }
  if (type !== '') {
    urlParams.append('type', type)
  }
  if (status !== 'all') {
    urlParams.append('status', status)
  }
  if (roofType !== 'all') {
    urlParams.append('roof_type', roofType)
  }
  if (query) {
    urlParams.append('query', query)
  }
  if (filter.hideIfActiveCase) {
    urlParams.append('hide_if_active_case', filter.hideIfActiveCase)
  }

  try {
    const result = await fetch(`/tickets/api/v1/alarms?${urlParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!result.ok) {
      notifications.addNotification({
        message: `Failed to get list of alarms: ${result.statusText}`,
        type: 'danger',
      })
      return
    }
    return await result?.json()
  } catch (error) {
    notifications.addNotification({
      message: `Failed to get list of alarms, ${e.message}`,
      type: 'danger',
    })
    throw new Error('Failed to get list of alarms with error: ' + error.message)
  }
}

const {
  data: alarmsQuery,
  isLoading,
  fetchNextPage,
  hasNextPage,
} = useInfiniteQuery({
  queryKey: computed(() => ['alarms', filter]),
  keepPreviousData: true,
  queryFn: getList,
  keepPreviousData: true,
  getNextPageParam: (_, allPages) =>
    allPages[allPages.length - 1].data?.length === perPage ? allPages.length + 1 : undefined,
})

const numberOfAlarms = computed(() => alarmsQuery?.value?.pages[0]?.total)
const alarms = computed(() => alarmsQuery?.value?.pages.flatMap((p) => p.data))

const wocosQuery = useQuery({
  queryKey: ['wocos'],
  queryFn: () => fetchData('/config/api/v1/wocos/?view=full'),
})
const wocos = computed(() =>
  wocosQuery.data?.value?.data.map((woco) => ({ label: woco.name, value: woco.shortname }))
)

const getProducts = useQuery({
  queryKey: ['products'],
  queryFn: () => fetchData('/api/v1/tickets/products'),
})

const getInverterName = (inverterCode) => {
  const inverter = getProducts.data?.value?.data?.find((product) => product.code === inverterCode)
  return inverter?.description || inverterCode
}

const columns = [
  { label: 'Start Date', field: 'started_at' },
  { label: 'Serial Number', field: 'serial_number' },
  { label: 'Address', field: 'address' },
  { label: 'Woco - Project', field: 'woco_project' },
  { label: 'Description', field: 'description' },
  { label: 'Type', field: 'type' },
  { label: 'Roof type', field: 'roof_type' },
  { label: 'Inverter type', field: 'inverter_type_1' },
  { label: 'Actions', field: 'actions', isAction: true },
]

const getEleenaLink = (alarm) => {
  const { postal_code: postalCode, house_number: houseNumber } = alarm.location.address
  return `https://reporting.eleena.eu/client-overview/?postcode=${postalCode.substring(0, 4)}&postcode_ext=${postalCode.substring(4, 6)}&housenmbr=${houseNumber}`
}

const { data: projects } = useQuery({
  queryKey: ['projects'],
  queryFn: async () => {
    const result = await fetchData('/config/api/v1/projects/?view=full')
    return result.data
  },
})

const projectName = (projectId) => {
  const project = projects.value?.find((project) => project.id === projectId)
  return project?.name || '...'
}

const trimmedProjectName = (projectId) => {
  if (!projects.value) {
    return ''
  }
  const project = projects.value.find((project) => project.id === projectId)
  if (!project) {
    return `(project ID ${projectId})`
  }
  const name = project.name
  if (name.length > 20) {
    return `${name.slice(0, 20)}...`
  }
  return name
}

const { mutate: muteAlarm } = useMutation({
  mutationFn: ({ id, days }) => {
    const futureDate = addDays(new Date(), days)

    const payload = {
      muted_till: format(futureDate, 'yyyy-MM-dd'),
    }
    return fetchData(`/tickets/api/v1/alarms/${id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
  },
  onError: (e) => {
    console.error('Error on muting alarm', e)
    notifications.addNotification({
      message: `Alarm status was not changed, ${e.message}`,
      type: 'danger',
    })
  },
  onSuccess: () => {
    notifications.addNotification({
      message: 'Alarm status changed!',
      type: 'success',
    })
    openDropdownId.value = null
    queryClient.invalidateQueries(['alarms'])
  },
})

const getCase = async (alarm_id) => {
  const result = await fetch(`/tickets/api/v1/cases?alarm_id=${alarm_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return await result.json()
}

watchDebounced(
  queryInput,
  () => {
    filter.query = queryInput.value
  },
  {
    debounce: 300,
  }
)
</script>
