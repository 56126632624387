<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import BaseButtonBulk from './BaseButtonBulk.vue'

@Component({
  name: 'BaseButtonBulk',
})
export default class ApproveButtonNulk extends BaseButtonBulk {
  protected color = 'is-assign'

  protected onClick() {
    const now = new Date()
    const deadline = new Date(now.getTime() + 604800000)

    this.manager.setActionDataForItems({ deadline }, this.actionList)
    this.submitActions(this.actionList)
  }
}
</script>
