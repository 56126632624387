<template>
  <div v-if="!isLoading" class="tw-bg-white tw-drop-shadow-xl tw-rounded-lg tw-p-5 tw-mb-5">
    <div class="tw-flex tw-mt-2">
      <span class="tw-font-bold tw-mr-2">ID: </span>
      <span>{{ orderDetail.id }}</span>
    </div>
    <div class="tw-flex tw-mt-2">
      <span class="tw-font-bold tw-mr-2">Partner:</span>
      <span>{{ orderDetail.partner.full_name }}</span>
    </div>
    <div class="tw-flex tw-mt-2">
      <span class="tw-font-bold tw-mr-2">Project:</span>
      <span>{{ project }}</span>
    </div>
    <div class="tw-flex tw-mt-2">
      <span class="tw-font-bold tw-mr-2">Year:</span>
      <span>{{ orderDetail.year }}</span>
    </div>
    <div class="tw-flex tw-mt-2">
      <span class="tw-font-bold tw-mr-2">Week:</span>
      <span>{{ orderDetail.week }}</span>
    </div>
    <div class="tw-flex tw-mt-2">
      <span class="tw-font-bold tw-mr-2">Tickets:</span>

      <a
        :href="`https://tickets.ecorushome.com/v2/tickets?po_id=${orderDetail.id}`"
        class="hover:tw-underline tw-text-l tw-cursor-pointer"
        target="_blank"
      >
        Link to tickets
      </a>
    </div>
    <div class="tw-flex tw-flex-col tw-mt-2">
      <span class="tw-font-bold tw-mr-2">Products:</span>
      <div
        v-if="orderDetail.products"
        class="tw-mt-2 tw-space-y-2 tw-bg-gray-50 tw-p-3 tw-rounded tw-border tw-border-gray-200"
      >
        <div v-for="(amount, code) in orderDetail.products" :key="code" class="tw-flex">
          <div class="tw-text-gray-800 tw-font-bold tw-mr-5">{{ amount }}</div>
          <div v-if="getDescription(code)" class="tw-text-gray-600 tw-text-sm">
            {{ getDescription(code).description }}
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-mt-2">
      <span class="tw-font-bold tw-mr-2">Services:</span>
      <div
        v-if="orderDetail.services"
        class="tw-mt-2 tw-space-y-2 tw-bg-gray-50 tw-p-3 tw-rounded tw-border tw-border-gray-200"
      >
        <div v-for="(amount, code) in orderDetail.services" :key="code" class="tw-flex">
          <div class="tw-text-gray-800 tw-font-bold tw-mr-5">{{ amount }}</div>
          <div v-if="getDescription(code)" class="tw-text-gray-600 tw-text-sm">
            {{ getDescription(code).description }}
          </div>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-4">
      <button
        v-if="!orderDetail.exact_purchase_order_id"
        class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded tw-py-1 tw-px-2 tw-font-semibold disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
        @click="createPurchaseOrder"
        :disabled="isCreatingPurschaseOrder"
      >
        <span v-if="isCreatingPurschaseOrder">In progress...</span>
        <span v-else>Create Purchase Order</span>
      </button>
      <button
        v-if="!orderDetail.exact_stock_count_id"
        class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded tw-py-1 tw-px-2 tw-font-semibold disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
        @click="createStockCount"
        :disabled="isCreatingStockCount"
      >
        <span v-if="isCreatingStockCount">In progress...</span>
        <span v-else>Create Stock Count</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query'
import { notifications } from '@/util'
import fetchData from '@/util/fetch'

const route = useRoute()

const id = computed(() => route.params?.id)

const {
  data: orderDetail,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ['orderDetail', String(id.value)]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/tickets/api/v1/purchase-orders/${id.value}`)
      if (result.status !== 'OK' || !result) {
        notifications.addNotification({
          message: `Failed to get the purchase order: ${result.statusText}`,
          type: 'danger',
        })
        return
      }
      return result?.data
    } catch (error) {
      notifications.addNotification({
        message: `Failed to get the  purchase order, ${e.message}`,
        type: 'danger',
      })
      throw new Error('Failed to fetch  purchase order data')
    }
  },
  refetchOnWindowFocus: false,
})

const projectsQuery = useQuery({
  queryKey: ['projects'],
  queryFn: fetchData('/config/api/v1/projects/?view=full'),
})

const project = computed(
  () => projectsQuery.data.value?.data.find((p) => p.id === orderDetail.value.project_id).name
)

const productsQuery = useQuery({
  queryKey: ['products'],
  queryFn: () => fetchData('/api/v1/tickets/products'),
})

const getDescription = (code) => {
  return productsQuery.data.value?.data.find((product) => product.code === code)
}

const queryClient = useQueryClient()

const { mutate: createPurchaseOrder, isLoading: isCreatingPurschaseOrder } = useMutation({
  mutationFn: async () => {
    const result = await fetchData(
      `/tickets/api/v1/purchase-orders/${id.value}/exact-purchase-order`,
      {
        method: 'POST',
      }
    )
    return result
  },
  onSuccess: () => {
    notifications.addNotification({
      message: 'Purchase order created successfully',
      type: 'success',
    })
    addLogModal.value = false

    queryClient.invalidateQueries(['orderDetail', String(id.value)])
    queryClient.invalidateQueries(['orders'])
  },
  onError: (error) => {
    console.error('Error adding log', error)
    let message = error.detail || 'Unknown error'
    if (error.detail[0]) {
      message = error.detail[0].msg
    }
    notifications.addNotification({
      message: `Failed to create purchase order: ${message}`,
      type: 'danger',
    })
  },
})

const { mutate: createStockCount, isLoading: isCreatingStockCount } = useMutation({
  mutationFn: async () => {
    const result = await fetchData(
      `/tickets/api/v1/purchase-orders/${id.value}/exact-stock-count`,
      {
        method: 'POST',
      }
    )
    return result
  },
  onSuccess: () => {
    notifications.addNotification({
      message: 'Stock count created successfully',
      type: 'success',
    })
    addLogModal.value = false

    queryClient.invalidateQueries(['orderDetail', String(id.value)])
    queryClient.invalidateQueries(['orders'])
  },
  onError: (error) => {
    console.error('Error adding log', error)
    let message = error.detail || 'Unknown error'
    if (error.detail[0]) {
      message = error.detail[0].msg
    }
    notifications.addNotification({
      message: `Failed to create stock count: ${message}`,
      type: 'danger',
    })
  },
})
</script>
