<template>
  <div class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-z-50">
    <div
      class="tw-modal-overlay tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
      @click="cancel"
    ></div>

    <div
      class="tw-modal-container tw-bg-white tw-w-96 tw-mx-auto tw-rounded tw-shadow-lg tw-z-50 tw-overflow-y-auto"
    >
      <div class="tw-modal-content tw-py-4 tw-text-left tw-px-6">
        <span
          class="tw-close tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer tw-text-3xl tw-leading-none tw-pr-6 tw-pt-1"
          @click="cancel"
          >&times;</span
        >

        <h2 class="tw-text-lg tw-font-bold tw-mb-4">Confirmation</h2>

        <p class="tw-mb-4">
          Are you sure you want to <span class="tw-font-bold">{{ action }} </span>selected item(s)?
        </p>

        <div class="tw-mt-6 tw-flex tw-justify-end">
          <button
            @click="confirm"
            class="tw-bg-green-600 tw-text-white tw-px-4 tw-py-2 tw-rounded tw-mr-2"
          >
            Yes
          </button>
          <button
            @click="cancel"
            class="tw-bg-gray-300 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    action: String,
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
