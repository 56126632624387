<template>
  <th
    class="dashboard-table-header px-2"
    :class="[current && 'current', showYTD ? 'week-header-wide' : 'week-header']"
  >
    <p class="has-text-centered">{{ formatWeek(week) }}</p>
    <div class="is-flex has-text-right">
      <p style="flex: 1" v-if="showYTD">YTD</p>
      <p style="flex: 1">For.</p>
      <p style="flex: 1">Act.</p>
      <p style="flex: 1.5" title="Difference compared to the previous week">Diff.</p>
    </div>
  </th>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getISOWeek, getISOWeekYear } from 'date-fns'

@Component({
  name: 'DashboardTableHeader',
})
export default class DashboardTableHeader extends Vue {
  @Prop({ default: '' }) week!: string
  @Prop({ default: false }) current!: boolean
  @Prop({ default: false }) showYTD!: boolean

  protected formatWeek(date: string) {
    const d = new Date(date)
    return `Week ${getISOWeek(d)}, ${getISOWeekYear(d)}`
  }
}
</script>
