import { getStoreAccessors } from 'vuex-typescript'
import type { Permissions } from '@/interfaces'

import { StoreState, AuthModuleState } from './state'
import type { store as Store } from './store'

const module = {
  namespaced: true,

  state: {
    user: '',
    token: '',
    permissions: null,
  },

  getters: {
    getUser(state: AuthModuleState) {
      return state.user
    },

    getToken(state: AuthModuleState) {
      return state.token
    },

    getPermissions(state: AuthModuleState) {
      return state.permissions
    },
  },

  mutations: {
    setUser(state: AuthModuleState, user: string) {
      state.user = user
    },

    setToken(state: AuthModuleState, token: string) {
      state.token = token
    },

    setPermissions(state: AuthModuleState, permissions: Permissions | null) {
      state.permissions = permissions
    },
  },
}

const { commit, read } = getStoreAccessors<AuthModuleState, StoreState>('auth')
const { mutations, getters } = module

const readUser = read(getters.getUser)
const readToken = read(getters.getToken)
const readPermissions = read(getters.getPermissions)

const commitUser = commit(mutations.setUser)
const commitToken = commit(mutations.setToken)
const commitPermissions = commit(mutations.setPermissions)

export default module

export const makeAuthActions = (store: typeof Store) => ({
  readUser: () => readUser(store),
  readToken: () => readToken(store),
  readPermissions: () => readPermissions(store),
  commitUser: (payload: string) => commitUser(store, payload),
  commitToken: (payload: string) => commitToken(store, payload),
  commitPermissions: (payload: Permissions | null) => commitPermissions(store, payload),
})
